// used on funding page, generate reports modal, update funding modal
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { enumFundingSourceType } from './fundingtype.enum';
import { ToastController } from '@ionic/angular';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FundingService {


  public idToken: string;
  newFund: any;
  constructor(public httpClient: HttpClient, private route: ActivatedRoute,
    private router: Router, public toastController: ToastController) { }
  createFunder(funder: any) {
    let route = `${environment.fundingSourceUrl}funders`;
    return this.httpClient.post(route, funder);
  }
  funders() {
    let route = `${environment.fundingSourceUrl}funders`;
    return this.httpClient.get(route);
  }
  // POST request to create a new funding
  createFunding(funding, modal) {
    console.log('createFunding() with url: ', environment.fundingSourceUrl);
    return this.httpClient.post(environment.fundingSourceUrl, funding).subscribe(
      result => {
        // Handle result
        console.log(result);
        this.newFund = result;
      },
      error => {
        this.showError(error);
      },
      async () => {
        const toast = await this.toastController.create({
          color: 'success',
          duration: 3000,
          message: 'Success!',
          showCloseButton: true,
          position: 'top',
          buttons: [
            {
              side: 'end',
              text: 'Go To Newly Added Funder',
              handler: () => {
                this.goToFund(Number(this.newFund.id));
              }
            }
          ]
        });
        await toast.present();
        setTimeout(function () {
        }, 1500);
      }
    );
  }
  // route user to newly added funding detail page
  async goToFund(id) {
    return this.router.navigateByUrl(
      '/awards/' +
      id
    );
  }
  // fetch CY funding data
  getFundingCy() {
    console.log('getFunding()'); // todo: comment out logging as needed for prod
    return this.loadFundingCy().pipe(map(this.processFundingCy, this));
  }
  // fetch CY funding data
  private loadFundingCy() {
    console.log('loadFundingCy() with url: ', environment.fundingSourceUrl + 'cy');
    return this.httpClient.get(environment.fundingSourceUrl + 'cy');
  }
  // fetch CY funding data
  processFundingCy(data: any) {
    console.log('processFundingCy() with', data, 'rows.');
    data.forEach((row: any) => {
      if (row.fundingSource) {
        row.fundingSource.enumFundingSourceType = enumFundingSourceType[row.fundingSource.fundingSourceType];
      }
    });
    return data;
  }
  // fetch FY funding data
  getFundingFy() {
    console.log('getFundingFy()'); // todo: comment out logging as needed for prod
    return this.loadFundingFy().pipe(map(this.processFundingFy, this));
  }
  // fetch FY funding data
  private loadFundingFy() {
    console.log('loadFundingFy() with url: ', environment.fundingSourceUrl + 'fy');
    return this.httpClient.get(environment.fundingSourceUrl + 'fy');
  }
  // fetch FY funding data
  processFundingFy(data: any) {
    console.log('processFundingFy() with', data, 'rows.');
    data.forEach((row: any) => {
      if (row.fundingSource) {
        row.fundingSource.enumFundingSourceType = enumFundingSourceType[row.fundingSource.fundingSourceType];
      }
    });
    return data;
  }

  async showSuccess() {
    const toast = await this.toastController.create({
      color: 'success',
      duration: 2500,
      message: 'Success!',
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
    setTimeout(function () {
    }, 1500);
  }

  async showError(error) {
    console.log(error);
    const toast = await this.toastController.create({
      color: 'danger',
      duration: 2500,
      message: 'Error!',
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
  }
}
