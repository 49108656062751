import { Observable } from "rxjs";
import {
  Router,
  NavigationEnd,
  ActivationStart,
  ActivationEnd,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { link } from "fs";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BreadCrumbService {
  breadCrumb$: Subject<string> = new Subject<string>();
  links: any[] = [];
  constructor(private route: Router) {
    this.route.events.subscribe((e) => {
      //console.log(e);
      if (e instanceof ActivationEnd) {
        if (e.snapshot.data) {
          if (e.snapshot.data.root) {
            let data = e.snapshot.data;
            let r = window.location.pathname;
            this.links = [];
            this.links.push({
              title: data.title,
              url: r,
              root: true,
            });
          } else {
            let url = (e.snapshot as any)._routerState.url;
            let index = this.links.findIndex((l) => l.url == url);
            if (index >= 0) this.links.splice(index + 1, this.links.length - 1);
          }
        }
      }
    });
    this.breadCrumb$.subscribe((title) => {
      const r = window.location.pathname;
      if (this.links.length > 0) {
        const last = this.links[this.links.length - 1];
        if (last.title !== title) {
          if (last.url === r && this.links.length > 1) {
            this.links[this.links.length - 1] = { title: title, url: r };
          } else {
            this.links.push({
              title: title,
              url: r,
            });
          }
        }
      } else {
        this.links.push({
          title: title,
          url: r,
        });
      }

    });
  }
}
