import { CalendarEvent } from './../../calendar-editor/CalendarEvent';
import {
  DateRange,
  EditDateRangeModel,
  CalendarLegend,
} from './../../calendar-editor/DateRange';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarPeriod } from '../../master-calendar-page/CalendarPeriod';
import { CalendarEditorComponent } from '../../calendar-editor/calendar-editor.component';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarService } from '../../core/services/calendar.service';
import { TabsService } from '../../components/tabs.service';
import { SchoolsService } from '../../schools/schools.service';
import * as m from 'moment';
import _ from 'lodash';
import { UserData } from '../../users/user-data';
@Component({
  selector: 'supplemental-calendar',
  templateUrl: './supplemental-calendar.component.html',
  styleUrls: ['./supplemental-calendar.component.scss'],
})
export class SupplementalCalendarComponent implements OnInit {
  @ViewChild(CalendarEditorComponent, { static: false })
  calendar: CalendarEditorComponent;
  startDate: Date; // = new Date(2019, 6, 1);
  endDate: Date; // = new Date(2020, 6, 1);
  dateRanges: DateRange[] = [];
  removeWeekends = true;
  events: CalendarEvent[] = [];
  schools: any = [];
  calendarSchools: any = [];
  moment = m;
  calendars = [];
  name = '';
  type = 'Supplemental';
  id = 0;
  sortedItems: any = [];
  assigned = true;
  calendarId: any = 0;
  loaded = false;
  schoolCalendar: any = null;
  get days() {
    return this.calculateDays();
  }
  get sortedSchools() {
    return this.sortedItems;
  }
  get modifiedSchools() {
    return this.sortedItems.filter((s) => s.modified == true);
  }
  get assignedSchools() {
    return this.schools.filter((s) => s.selected == true);
  }
  constructor(
    private modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private calendarService: CalendarService,
    public tc: TabsService,
    public userData: UserData,
    public calendarPeriod: CalendarPeriod
  ) {}

  ngOnInit() {
    console.log('ngOnInit start supplemental ->>>>>>>>>>');
    this.calendarId = this.activatedRoute.snapshot.params['calendarId'];
    if (this.calendarId != null) {
      this.calendarId = parseInt(this.calendarId);
      this.calendarService
        .calendarBySchoolCalendar(this.calendarId)
        .subscribe((schoolCalendar) => {
          this.schoolCalendar = schoolCalendar;
          const calendar = schoolCalendar.operationalCalendar;
          this.id = schoolCalendar.id;
          this.name = calendar.name;
          const calendarRaw =
            schoolCalendar.calenderRawData == null
              ? calendar.calenderRawData
              : schoolCalendar.calenderRawData;
          const local = JSON.parse(calendarRaw);
          this.removeWeekends = local.removeWeekends;
          this.events = local.events.map((e) => {
            e.date = this.calendarPeriod.toUtcDate(e.date);
            return e;
          });
          this.dateRanges = local.dateRanges;
          this.startDate = m(local.startDate).toDate();
          this.endDate = m(local.endDate).toDate();
          this.loaded = true;
          setTimeout(() => {
            this.calendar.refresh();
          }, 600);
        });
    }
  }
  assignCalendar(row: any) {
    row.modified = !row.modified;
  }
  notAssigned() {
    this.assigned = !this.assigned;
    if (this.assigned) {
      this.sortedItems = this.schools;
    } else {
      this.sortedItems = this.schools.filter(
        (s) => s.selected == this.assigned
      );
    }
  }
  cancel() {
    const calendarJson = this.calendar.toJson();
    const calendar = {
      schoolId: this.schoolCalendar.schoolId,
    };

    this.router.navigateByUrl(`/school-details/${calendar.schoolId}`);
  }
  selectAll() {
    this.schools.forEach((school) => {
      if (school.selected == false && school.modified == false) {
        school.modified = true;
      }
      school.selected = true;
    });
  }
  unselectAll() {
    this.schools.forEach((school) => {
      school.selected = false;
    });
  }

  filterRows() {}
  saveCalendar() {
    this.calendar.removeWeekends = this.removeWeekends;
    const calendarJson = this.calendar.toJson();
    const calendar = {
      id: this.id,
      operationalCalendarId: this.schoolCalendar.operationalCalendarId,
      schoolId: this.schoolCalendar.schoolId,
      calenderRawData: JSON.stringify(calendarJson),
    };
    this.calendarService.updateSchoolCalendar(calendar).subscribe(() => {
      this.router.navigateByUrl(`/school-details/${calendar.schoolId}`);
    });
  }
  refresh() {
    if (this.startDate && this.endDate) {
      setTimeout(() => {
        this.calendar.refresh();
      }, 200);
    }
  }

  daysDiff( dateRange: DateRange) {
    const diff = this.getBusinessDaysDiff(dateRange);
    return diff;
  }



  deletePeriod(index) {
    this.dateRanges.splice(index, 1);
    this.refresh();
  }
  calcBusinessDays(startDate, endDate) {
    let day = m(startDate);
    let businessDays = 0;

    while (day.isSameOrBefore(endDate, 'day')) {
      if (day.day() != 0 && day.day() != 6) { businessDays++; }
      day.add(1, 'd');
    }
    return businessDays;
  }
  calculateDays() {

    if (this.calendar == undefined) { return 0; }
    if (this.calendar.layers.length == 0) { return 0; }

    let days = 0;
    let exceptionalDays = 0;
    this.calendar.dateRanges
      .filter((item) => item.isExceptionalDay)
      .forEach((dateRange) => {
        const diff = this.getBusinessDaysDiff(dateRange);
        exceptionalDays = + diff;
      });

    this.calendar.dateRanges
    .filter((item) => !item.isExceptionalDay)
    .forEach((dateRange) => {
      const diff = this.getBusinessDaysDiff(dateRange);
      days = days + diff;
    });

    return days - exceptionalDays;
  }

  private getBusinessDaysDiff(dateRange: DateRange) {
    let diff = m(dateRange.end).diff(dateRange.start, 'day') + 1;
    const startDate = m(dateRange.start);
    const endDate = m(dateRange.end);

    if (this.removeWeekends) {
      while (startDate.isSameOrBefore(endDate)) {
        if (startDate.isoWeekday() > 5) {
          diff--;
        }
        startDate.add(1, 'day');
      }
    }
    return diff;
  }

  async editPeriod(index: number) {
    let editModal: EditDateRangeModel = {
      model: this.dateRanges[index],
      index: index,
    };

    const modal = await this.modalController.create({
      component: CalendarPeriod,
      cssClass: 'calendar-period',
      componentProps: {
        supplemental: false,
        showSchools: false,
        dataRanges: this.dateRanges,
        editModel: editModal,
        isExceptionalDay: true,
      },
    });
    modal.onDidDismiss().then((result) => {
      if (result.data == undefined || result.data == 'cancel') { return; }
      const range: DateRange = this.loadDateRange(result);
      this.dateRanges[index] = range;
      this.refresh();
    });
    await modal.present();
  }

  async addPeriod() {
    const modal = await this.modalController.create({
      component: CalendarPeriod,
      cssClass: 'calendar-period',
      componentProps: {
        supplemental: false,
        showSchools: false,
        dataRanges: this.dateRanges,
        isExceptionalDay: true,
      },
    });
    modal.onDidDismiss().then((result) => {
      if (result.data == undefined || result.data == 'cancel') { return; }
      const range: DateRange = this.loadDateRange(result);
      this.dateRanges.push(range);
      this.refresh();
    });
    await modal.present();
  }

  private loadDateRange(result) {
    const color = '#ff4d4d'; // Red

    const range: DateRange = {
      title: result.data.title,
      start: result.data.startDate,
      end: result.data.endDate,
      workingHours: result.data.workingHours,
      color: color,
      isExceptionalDay: result.data.isExceptionalDay,
    };
    return range;
  }

  getSupplementalLegends(): Array<CalendarLegend> {
    return [
      { color: '#AED6F1', description: '3h Working Hours' },
      { color: '#ABEBC6', description: '6h Working Hours' },
      { color: '#FAD7A0', description: '9h Working Hours' },
    ];
  }
  ngOnDestroy() {}
}
