// used on student details page
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Components } from '@ionic/core';
import { AgenciesService } from '../agencies/agencies.service';
import { PopoverController } from '@ionic/angular';
import { DeactivateAuthorizedPersonComponent } from '../deactivate-authorized-person/deactivate-authorized-person.component';
import { StudentsService } from '../students/students.service';

@Component({
  selector: 'update-authorized-person',
  templateUrl: './update-authorized-person.component.html',
  styleUrls: ['./update-authorized-person.component.scss'],
})
export class UpdateAuthorizedPersonComponent implements OnInit {
  public personForm: FormGroup;
  loading = false;
  @Input() update;
  @Input() person;
  @Input() student;
  @Input() modal: Components.IonModal;
  masks = {
    phoneNumber: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  };
  isReadOnly = false;

  constructor(public router: Router, public formBuilder: FormBuilder,
    private studentsService: StudentsService, public popoverController: PopoverController) {
    this.personForm = formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      relationship: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      mobilePhone: ['', [Validators.required, Validators.minLength(14)]],
      hasRestrainingOrder:[],
      onHold:[],
      isActive:[],
      isLegalGuardian:[,[Validators.required]],
    });
  }
  // create new auth person
  createPerson() {
    console.log(this.personForm);
    const person = this.personForm.value;
    person.isLegalGuardian = person.isLegalGuardian == "no" ? false : true;
    person.studentId = parseInt(this.student.id);
    person.isActive = true;
    person.hasRestrainingOrder = false;
    person.onHold = false;
    person.pin = '1122';
    //person.isLegalGuardian = true;
    person.state = 'CA';
    person.city = 'CA';
    person.streetAddress = 'test street';
    person.gender = 'Female';
    person.source = 'BTB';
    console.log(person);
    this.studentsService.createAuthorizedPerson(person).add(() => {
      this.modal.dismiss();
    });
  }

  optionsFn(item) {
    console.log(item);
  }
  // update existing auth person
  updatePerson() {
    console.log(this.person);
    if (this.personForm.dirty) {          
      this.person.firstName = this.personForm.value.firstName;
      this.person.lastName = this.personForm.value.lastName;
      this.person.mobilePhone = this.personForm.value.mobilePhone;
      this.person.relationship = this.personForm.value.relationship;
      if(this.personForm.value.isActive == "active"){
        this.person.isActive = true;
      }
      else{
        this.person.isActive = false;
      }
      if(this.personForm.value.isLegalGuardian == "yes"){
        this.person.isLegalGuardian = true;
      }
      else{
        this.person.isLegalGuardian = false;
      }
      if(this.personForm.get('onHold').value === true){
        this.person.onHold = true;
      }
      else{
        this.person.onHold = false;
      }
      //this.person.isActive = this.personForm.value.isActive;
      // if (!this.person.isActive && this.personForm.value.status === true) {
      //   this.person.isActive = true;
      // }
      this.studentsService.updateAuthorizedPerson(this.person).add(() => {
        this.modal.dismiss();
      });
    }{
      console.log("No changes were made.");
      this.modal.dismiss();
    }
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: DeactivateAuthorizedPersonComponent,
      event: ev,
      translucent: true,
      componentProps: { person: this.person },
    });
    //popover.onDidDismiss().then(() => {
    this.modal.dismiss();
    // });
    return await popover.present();
  }
  // populate current auth person's details to be edited
  populatePerson = async () => {
    this.personForm.patchValue(this.person);
    console.log(this.personForm.status);
    this.person.isActive == true ? this.personForm.value.isActive ="active" : this.personForm.value.isActive ="inactive";
    this.person.isActive == true ? this.personForm.get('isActive').setValue("active") : this.personForm.get('isActive').setValue("inactive");

    this.person.isLegalGuardian == true ? this.personForm.value.isLegalGuardian ="yes" : this.personForm.value.isLegalGuardian ="no";
    this.person.isLegalGuardian == true ? this.personForm.get('isLegalGuardian').setValue("yes") : this.personForm.get('isLegalGuardian').setValue("no");
    //this.personForm.value.isActive = this.person.isActive;

    this.person.hasRestrainingOrder == true ? this.personForm.value.hasRestrainingOrder = "true" : this.personForm.value.hasRestrainingOrder = "false";
    this.person.hasRestrainingOrder == true ? this.personForm.get('hasRestrainingOrder').setValue("true") : this.personForm.get('hasRestrainingOrder').setValue("false");

    this.person.onHold == true ? this.personForm.value.onHold = "true" : this.personForm.value.onHold = "false";
    this.person.onHold == true ? this.personForm.get('onHold').setValue("true") : this.personForm.get('onHold').setValue("false");
  }

  ngOnInit() {
    console.log(this);
    this.personForm.valueChanges.subscribe(change => {
      if (this.personForm.dirty) {
        this.modal.backdropDismiss = false;
      }
    });

    if(this.person.source == "LAUSD"){
      this.isReadOnly = true;
      this.personForm = this.formBuilder.group({
        firstName: ['', []],
        lastName: ['', []],
        relationship: ['',[]],
        mobilePhone: ['', []],
        hasRestrainingOrder:[],
        onHold:[],
        isActive:[],
        isLegalGuardian:[,[]],
      });
    }

    if (this.update) {
      this.populatePerson();
    }
  }
}
