// used for staff details page
import { Component, OnInit, Input } from '@angular/core';
import { Components } from '@ionic/core';
import { StudentsService } from '../students/students.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StaffService } from '../staff/staff.service';

@Component({
  selector: 'remove-staff-certification',
  templateUrl: './remove-staff-certification.component.html',
  styleUrls: ['./remove-staff-certification.component.scss'],
})
export class RemoveStaffCertificationComponent implements OnInit {

  public withdrawStudentForm: FormGroup;
  @Input() modal: Components.IonModal;
  @Input() staffCertification: any;
  constructor(public staffService: StaffService) { }

  ngOnInit() {
    console.log(this.staffCertification);
  }
  // removes selected certification from staff member's certification table
  removeStaff() {
    this.staffService.removeStaffCertification(this.staffCertification.id);
  }
}
