import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { ToastController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class StudentAttendanceService {
  public idToken: string;

  constructor(
    public httpClient: HttpClient,
    public toastController: ToastController
  ) { }

  studentAttendance(studentAttendence : any) {
    console.log('studentAttendance() with url: ', environment.studentsUrl + 'Attendance');
    return this.httpClient.post(environment.studentsUrl + 'Attendance', studentAttendence).subscribe(
      result => {
        // Handle result
        console.log(result);
      },
      error => {
        this.showError(error);
      },
      () => {
        this.showSuccess();
      }
    );
  }

  async showSuccess() {
    const toast = await this.toastController.create({
      color: "success",
      duration: 2500,
      message: "Success!",
      showCloseButton: true,
      position: "top",
    });
    await toast.present();
    setTimeout(function () {
      //window.location.reload();
    }, 1500);
  }

  async showError(error) {
    console.log(error);
    const toast = await this.toastController.create({
      color: "danger",
      duration: 2500,
      message: "Error!",
      showCloseButton: true,
      position: "top",
    });
    await toast.present();
  }
}
