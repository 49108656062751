// used for AssignStudentAgencyComponent modal
import { Component, OnInit, Input } from '@angular/core';
import { Components } from '@ionic/core';
import { ModalController } from '@ionic/angular';
import { AssignStudentAgencyComponent } from '../assign-student-agency/assign-student-agency.component';

@Component({
  selector: 'pop-students-add',
  templateUrl: './pop-students-add.component.html',
  styleUrls: ['./pop-students-add.component.scss'],
})
export class PopStudentsAddComponent implements OnInit {
  @Input() popover: Components.IonPopover;
  @Input() row: any;
  constructor(public modalController: ModalController) { }

  ngOnInit() {
    console.log(this.row);
  }
  // triggers AssignStudentAgencyComponent modal
  async addStudent() {
    event.stopPropagation();
    const modal = await this.modalController.create({
      component: AssignStudentAgencyComponent,
      componentProps: { students: [], schoolIdContext: this.row.row.id, schoolNameContext: this.row.row.schoolName },
    });

    return await modal.present();
  }
}
