import { Component, OnInit, Input } from '@angular/core';
import { Components } from '@ionic/core';
import { StudentsService } from '../students/students.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'withdraw-student',
  templateUrl: './withdraw-student.component.html',
  styleUrls: ['./withdraw-student.component.scss'],
})
export class WithdrawStudentComponent implements OnInit {
  public withdrawStudentForm: FormGroup;
  @Input() popover: Components.IonPopover;
  @Input() studentAct: any;
  @Input() modal: Components.IonModal;

  constructor(public studentsService: StudentsService) { }

  ngOnInit() {
    console.log(this.studentAct);
  }

  withdrawStudent() {
    this.studentsService.withdrawStudent(this.studentAct.id).add(() => {
      this.popover.dismiss();
    });
  }
}
