// used on staff details page
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Components } from '@ionic/core';
import { StaffService } from '../staff/staff.service';
import { PopoverController } from '@ionic/angular';
import { environment } from '../../environments/environment';
import _ from 'lodash'
import { CancelConfirmationComponent } from '../cancel-confirmation/cancel-confirmation.component';
@Component({
  selector: 'update-staff-details',
  templateUrl: './update-staff-details.component.html',
  styleUrls: ['./update-staff-details.component.scss'],
})
export class UpdateStaffDetailsComponent implements OnInit {
  public staffForm: FormGroup;
  loading = false;
  @Input() update;
  @Input() staff;
  @Input() modal: Components.IonModal;
  masks = {
    phoneNumber: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  };
  objectKeys = Object.keys;

  constructor(public router: Router, public formBuilder: FormBuilder,
    private staffService: StaffService, public popoverController: PopoverController) {
    this.staffForm = formBuilder.group({
      phone: ['', [Validators.required, Validators.minLength(14)]],
    });
  }

  ngOnInit() {
    console.log(this);
    this.populateStaff();
  }
  // populate staff phone number
  populateStaff = async () => {
    if (this.update) {
      this.staffForm.patchValue(this.staff);
    }
  }

  async cancelConfirmation(ev: any) {
    const form = this.staffForm.value;
    if (form.phone !== '') {
      const popover = await this.popoverController.create({
        component: CancelConfirmationComponent,
        event: ev,
        translucent: true,
        componentProps: { modal: this.modal },
      });
      return await popover.present();
    } else {
      this.modal.dismiss('cancel');
    }
  }
  // update staff details
  updateStaff() {
    console.log(this.staffForm.value);
    this.staff.phone = this.staffForm.value.phone;
    this.staffService.updateStaff(this.staff, this.modal).add(() => {
      this.modal.dismiss();
    });
    // this.modal.dismiss();
  }

  get profile() {
    return JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
  }
}

