// used for update authorized person modal
import { Component, OnInit, Input } from '@angular/core';
import { Components } from '@ionic/core';
import { StudentsService } from '../students/students.service';
@Component({
  selector: 'deactivate-authorized-person',
  templateUrl: './deactivate-authorized-person.component.html',
  styleUrls: ['./deactivate-authorized-person.component.scss'],
})
export class DeactivateAuthorizedPersonComponent implements OnInit {
  @Input() popover: Components.IonPopover;
  @Input() person: any;
  constructor(public studentsService: StudentsService) { }

  ngOnInit() {
    console.log(this.person);
  }
  // deactivate authorized person
  deactivateAuthorizedPerson() {
    this.studentsService.deactivateAuthorizedPerson(this.person).add(() => {
      this.popover.dismiss();
    });
  }
}
