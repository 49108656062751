import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})

export class RolePermissionsService {

  public idToken: string;

  constructor(public httpClient: HttpClient, public storage: Storage) { }

  getRolePermissions(username: string) {
    //console.log('getRolePermissions()'); // todo: comment out logging as needed for prod
    return this.loadRolePermissions(username).pipe(map(this.processRolePermissions, this));
  }

  private loadRolePermissions(username: string) {
    console.log('loadRolePermissions() with url: ', environment.rolePermissionsUrl);
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );

    return this.httpClient.get(environment.rolePermissionsUrl + username, { headers: header });
  }

  processRolePermissions(data: any) {
    console.log('processRolePermissions() with', data, 'rows.');
    return data;
  }

  getAllRoles() {
    console.log('getAllRoles()');
    return this.loadAllRoles().pipe(map(this.processRoles, this));;
  }
  // fetch roles data
  private loadAllRoles() {
    console.log('loadAllRoles() with url: ', environment.roleApiUrl);
    return this.httpClient.get(environment.roleApiUrl);
  }

  processRoles(data: any) {
    console.log('processRoles() with', data, 'rows.');
    return data;
  }


}
