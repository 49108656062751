import { Component, OnInit, Input } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { StudentsService } from '../students/students.service';
import { SubmitAttendanceConfirmationComponent } from '../submit-attendance-confirmation/submit-attendance-confirmation.component';

@Component({
  selector: 'pop-retro-actions',
  templateUrl: './pop-retro-actions.component.html',
  styleUrls: ['./pop-retro-actions.component.scss'],
})
export class PopRetroActionsComponent implements OnInit {

  @Input() attendanceReq: any;
  studentList = []
  
  get profile() {
    return JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
  }
  
  constructor(
    private studentsService: StudentsService,
    private modalController: ModalController,
    private popoverController: PopoverController
  ) { }

  ngOnInit() {
    console.log(this.attendanceReq[0])
    this.studentsService.getStudentsForRetro(this.attendanceReq[0].id).subscribe((students)=>{
      this.studentList = students
    })
  }

  async approveRetroAttendances(approveType)
  {
    let status;
    let studentList: any = [];

  

    if(approveType == 'reject'){
      status = "Rejected";
      studentList = [];
    }
    else if(approveType == 'approve_all'){
      status = "Approved";
      studentList = this.studentList.map(s => s.id);
    }
    else{
      // status = "Custom";
      // studentList = this.selectedStudentsForApproval;
    }

    const attendanceRetro = {
      attendanceRetroId: this.attendanceReq[0].id,
      approverNotes: '',
      status: status,
      approvedStudents: studentList
    };

    const isReject = approveType == 'reject';
    const type = isReject ? 'reject' : 'approve';

    const modal = await this.modalController.create({
      component: SubmitAttendanceConfirmationComponent,
      cssClass: 'my-custom-modal-css-small',
      componentProps: {
        title: `Are you sure?`,
        text: `You are about to ${type} a request for ${this.attendanceReq[0].attendanceTime} for ${this.attendanceReq[0].schoolName} by ${this.attendanceReq[0].agencyName} with ${this.studentList.length} student(s).`,
        reject: isReject
      },
    });
    modal.onDidDismiss().then((response) => {
      if(response.role == 'confirm'){
        this.studentsService.approveAttenanceRetro(attendanceRetro).subscribe((data: any) => {
          this.popoverController.dismiss('submitted');
        });
      } else {
        this.popoverController.dismiss();
      }
    });

    return await modal.present();

  }

  async cancelRetroRequest(){

    const modal = await this.modalController.create({
      component: SubmitAttendanceConfirmationComponent,
      cssClass: 'my-custom-modal-css-small',
      componentProps: {
        title: 'Cancel Retro Attendance Request?',
        text: 'This action cannot be undone'
      },
    });
    modal.onDidDismiss().then((response) => {
      if(response.role == 'confirm'){

        this.studentsService.cancelAttendanceRetro(this.attendanceReq[0].id).subscribe((data: any) => {
          this.popoverController.dismiss('cancelled');
        });


      } else {
        return
      }
    });

    return await modal.present();
  }

  hasRoles(roles: number[]): boolean {

    if (this.profile) {
      for (const aRole of this.profile.roles) {
        if (roles.includes(aRole)) {
          return true;
        }
      }
    }
    return false;
  }

}
