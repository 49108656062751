// used in UpdateCertificationTypeComponent
import { Component, OnInit, Input } from '@angular/core';
import { Components } from '@ionic/core';
import { ModalController } from '@ionic/angular';
import { UpdateCertificationTypeComponent } from '../update-certification-type/update-certification-type.component';
import { StaffService } from '../staff/staff.service';

@Component({
  selector: 'pop-certification-types',
  templateUrl: './pop-certification-types.component.html',
  styleUrls: ['./pop-certification-types.component.scss'],
})
export class PopCertificationTypesComponent implements OnInit {
  @Input() popover: Components.IonPopover;
  @Input() type: any = [];
  constructor(public modalController: ModalController, public staffService: StaffService) { }

  ngOnInit() {
  }
  // triggers update certification type modal
  async updateCertificationType() {
    event.stopPropagation();
    const modal = await this.modalController.create({
      component: UpdateCertificationTypeComponent,
      componentProps: {
        update: true,
        type: this.type
      }
    });
    modal.onDidDismiss().then((result) => {
      this.popover.dismiss(result.data);
    });
    return await modal.present();
  }
}
