// used on admin calendar
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Components } from '@ionic/core';
import { PopoverController } from '@ionic/angular';
import { CalendarService } from './../core/services/calendar.service';

@Component({
  selector: 'update-code',
  templateUrl: './update-code.component.html',
  styleUrls: ['./update-code.component.scss'],
})
export class UpdateCodeComponent implements OnInit {
  public codeForm: FormGroup;
  loading = false;
  @Input() update;
  @Input() updateCode;
  @Input() modal: Components.IonModal;
  newCode: any;
  constructor(public router: Router, public formBuilder: FormBuilder,
    public popoverController: PopoverController, public calendarService: CalendarService,
  ) {
    this.codeForm = formBuilder.group({
      code: ['', Validators.required],
      description: ['', Validators.required]
      // status: []
    });
  }
  // create new enrichment code
  createEnrichmentCode() {
    console.log(this.codeForm);
    this.newCode = this.codeForm.value;
    this.newCode.code = this.newCode.code;
    this.newCode.isActive = true;
    this.newCode.createDate = new Date();
    this.newCode.updateDate = new Date();
    console.log(this.newCode);
    this.calendarService.createCode(this.newCode).add(() => {
      this.modal.dismiss();
    });
  }
  // uodate existing code
  updateEnrichmentCode() {
    this.updateCode.code = this.codeForm.value.code;
    this.updateCode.description = this.codeForm.value.description;
    this.updateCode.isActive = this.codeForm.value.isActive;
    this.modal.dismiss(this.updateCode);
    console.log(this.updateCode);
  }
  // if updating, current enrichment code details will populate form
  populateCode() {
    console.log('Populate CodeData');
    this.codeForm.addControl('isActive', new FormControl('', Validators.required));
    this.codeForm.patchValue(this.updateCode);
  }

  ngOnInit() {
    console.log(this);
    this.codeForm.valueChanges.subscribe(change => {
      if (this.codeForm.dirty) {
        this.modal.backdropDismiss = false;
      }
    });
    if (this.update) {
      this.populateCode();
    }
  }
}
