import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemReconService {

  constructor(public httpClient: HttpClient, public storage: Storage, public toastController: ToastController) { }

  getLastNSystemReconDate(n: number) {
    console.log('getStudentsByAgencyByMasterCalendar(schoolId)'); // todo: comment out logging as needed for prod
    return this.loadLastNSystemReconDate(n);
  }
  private loadLastNSystemReconDate(n: number) {

    // const profile = JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
    // return this.httpClient.get(environment.activitiesUrl + 'scheduledactivities/agency/' + profile.agency + '/school/' + schoolId + '/mastercalendar/' + masterCalendarCode);
    return this.httpClient.get(environment.reportsUrl + 'topNattendanceReconDates/' + n);

  }

  updateSystemReconDate(reconDate) {
    // prevents user from submitting new activity without a name or enrichment code inputted
    const profile = JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
    console.log(profile);
    console.log('updateSystemReconDate() with url: ', environment.reportsUrl + 'UpdateAttendanceReconciliation/' + reconDate + '/' + profile.name);

    return this.httpClient.post( environment.reportsUrl + 'UpdateAttendanceReconciliation/' + reconDate + '/' + profile.name, {}).subscribe(
      result => {
        this.showSuccessToast('Updated System Reconciliation Date');
      },
      error => {
        this.showError(error, null);
      },
    );
  }

  async showSuccessToast(message) {
    if (message === null) {
      message = 'Success!';
    }
    const toast = await this.toastController.create({
      color: 'success',
      duration: 2500,
      message: message,
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
  }
  async showError(error, message) {
    if (message === null) {
      message = 'Error!';
    }
    console.log(error);
    const toast = await this.toastController.create({
      color: 'danger',
      duration: 2500,
      message: message,
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
  }


}
