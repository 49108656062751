// used for staff details page - schedule tab
import { Component, OnInit, Input } from '@angular/core';
import { Components } from '@ionic/core';
import { StudentsService } from '../students/students.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StaffService } from '../staff/staff.service';
import { StaffDetailsService } from '../staff-details/staff-details.service';
import _ from 'lodash';

@Component({
  selector: 'remove-staff-activity',
  templateUrl: './remove-staff-activity.component.html',
  styleUrls: ['./remove-staff-activity.component.scss'],
})
export class RemoveStaffActivityComponent implements OnInit {

  public withdrawStudentForm: FormGroup;
  @Input() modal: Components.IonModal;
  @Input() staffActivity: any;
  @Input() scheduledActivityId: any;
  @Input() primaryStaffId: any;
  @Input() secondaryStaff: any;
  @Input() currentId: any;
  @Input() staffName: any;
  staff: any;
  staffIdArr: any[];

  constructor(public staffService: StaffService, public staffDetailsService: StaffDetailsService) { }

  ngOnInit() {
    // collects all secondary staff members selected
    this.staffIdArr = [];
    for (let i = 0; i < this.staffActivity.secondaryStaff.length; i++) {
      this.staffIdArr.push(this.staffActivity.secondaryStaff[i].staffId);
    }
    console.log(this.staffActivity);
    if (this.primaryStaffId === this.currentId) {
      this.getStaffList();
    }
  }
  // fetches staff members 
  get sortedStaff() {
    return _.sortBy(this.staff, ['concatName']);
  }
  // fetches staff by school ID
  getStaffList() {
    const schoolId = Number(this.staffActivity.school.id);
    const agencyId = Number(this.staffActivity.agencyId);
    console.log('Getting data for School: ', schoolId);
    this.staffService.getStaffBySchool(schoolId).subscribe((data: any) => {
      data = data.filter(x => x.schoolId === schoolId && x.agencyId === agencyId && x.id !== this.primaryStaffId);
      console.log('getStaffBySchool(): data from service:', data);
      data.forEach(element => {
        element.concatName = element.lastName + ', ' + element.firstName;
      });
      // this.loading = false;
      console.log('getStaffBySchool(): done');
      this.staff = data;
    });
  }
  // limits primary staff selection to secondary staff members
  selectionChanged($event) {
    this.secondaryStaff = [];
    if ($event.length) {
      $event.forEach(element => {
        this.secondaryStaff.push(element.data.id);
      });
    }
    console.log(this.secondaryStaff);
  }
  // used when current staff member is ONLY the primary staff member for scheduled activity
  // removes primary staff from scheduled activity and replaces primary staff with user's alt option
  removePrimeStaff() {
    this.staffService.removePrimaryStaffActivity(this.staffActivity.id, this.primaryStaffId, this.secondaryStaff[0]).subscribe(() => {
      this.modal.dismiss();
    });
  }
  // used when current staff member is ONLY a secondary staff member for scheduled activity
  // removes staff member from secondary staff list
  removeStaff() {
    this.staffService.removeStaffActivity(this.staffActivity.id, this.currentId).subscribe(() => {
      this.modal.dismiss();
    });
  }
}
