// used on student details page
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { SchoolsService } from '../schools/schools.service';
import { StudentsService } from '../students/students.service';
import { Components } from '@ionic/core';
import { AlertController } from '@ionic/angular';
import * as moment from 'moment';
import { ReportsService } from '../reports/reports.service';
import { GlobalShareService } from '../core/services/global-share.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'update-student',
  templateUrl: './update-student.component.html',
  styleUrls: ['./update-student.component.scss']
})

export class UpdateStudentComponent implements OnInit {
  public studentForm: FormGroup;
  loading = false;
  studentprogram: any = {};
  @Input() update;
  @Input() student;
  @Input() schoolIdContext;
  @Input() schoolNameContext;
  @Input() yearId;
  @Input() type;
  @Input() typeName;
  @Input() yearDescription;
  @Input() masterCalendarCode;

  schools: any;
  gradesOnlySameSchool = [];
  grades = [];
  visible = false;
  @Input() modal: Components.IonModal;
  fiscalYearList: any[];
  showFiscal: Boolean = false;
  initItem = { 'id': 0, 'description': 'All' };
  showCal: Boolean = false;
  calendarYearList: any[];
  isSchoolSame: Boolean = true;
  studentIdCaption: any = "LAUSD Student Id";
  isLausd:Boolean=true;
  maxDob: Date = new Date();

  constructor(public router: Router,
    public formBuilder: FormBuilder,
    private schoolsService: SchoolsService,
    private studentsService: StudentsService,
    private reportsService: ReportsService,
    private alertController: AlertController,
    private globalShareService: GlobalShareService
  ) { }
  // fetch schools
  fetchData() {
    this.loading = true;
    var cdsCodes = this.globalShareService.allSchools.map(s=>s.cdsCode);
    this.schoolsService.getSchoolsByCds(cdsCodes).then((result)=>{
      console.log(result);
      this.schools = result;
      if (this.update) {
        this.populateStudent();
      } else {
        this.populateSchool();
      }
    });
    //const data = [...this.globalShareService.allSchools];

  }
  compareFn(e1: any, e2: any): boolean {
    return e1 === e2;
  }


  onClickDropDown() {
    document.getElementById('dropdowndiv').style.height = '175px';
  }
  // resize modal to accomodate dropdown
  selectionChanged() {
    document.getElementById('dropdowndiv').style.height = '50px';
  }
  async confirmCreateStudent() {

    const school = this.schools.find(e => e.id === this.studentForm.controls.schoolId.value);
    if (this.studentForm.controls.schoolId.errors != null ||
      this.studentForm.controls.gradeConfig.errors != null ||
      this.studentForm.controls.dob.errors != null ||
      this.studentForm.controls.firstName.errors != null ||
      this.studentForm.controls.studentType.errors != null ||
      this.studentForm.controls.lastName.errors != null) {
      return;
    }

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Confirm Addition of Student',
      message: 'You are adding <strong>' + this.studentForm.value.lastName + ', ' + this.studentForm.value.firstName + '</strong> from school <strong>' +
        school.schoolName + '</strong> into a <strong>' + this.schoolNameContext + ' BTB Program</strong>. Are you sure you want to continue?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Yes',
          handler: () => {
            console.log('Confirm Okay');
            this.createStudent();
          }
        }
      ]
    });

    await alert.present();
  }
  // create new student
  createStudent() {
    console.log('Student Form:', this.studentForm);
    const student = this.studentForm.value;
    student.school = null;
    student.gradeConfig = JSON.stringify(student.gradeConfig);
    student.enrollmentDate = new Date();
    student.source = 'BTB';
    student.year = student.year;
    this.studentprogram.studentId = 0;
    this.studentprogram.agencyId = this.profile.agency;
    this.studentprogram.isActive = true;
    this.studentprogram.schoolId = student.programEnrollmentSchoolId;
    this.studentprogram.student = student;
    this.studentprogram.calendartype = this.type;
    this.studentprogram.calendarid = this.yearId;
    this.studentprogram.masterCalendarCode = this.masterCalendarCode;

    if(student.gradeConfig == 0){
      this.studentprogram.gradeConfig = 'K';
    }
    else if(student.gradeConfig == -1){
      this.studentprogram.gradeConfig = 'TK';
    }
    else{
      this.studentprogram.gradeConfig = student.gradeConfig;
    }
     this.studentprogram.student.gradeConfig = this.studentprogram.gradeConfig; 

    console.log(student);
    this.studentsService
      .assignStudentAgency(this.studentprogram, this.modal)
      .subscribe((response) => {
        this.modal.dismiss();
        console.log('/student-details/' + response.studentId + '/' + response.id);
        return this.router.navigate(['/student-details/' + response.studentId + '/' + response.id],
          { queryParams: { addAuthorizedPerson: true } });
      });
  }

  get profile() {
    return JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
  }
  // update existing student
  updateStudent() {
    if (this.student.source === 'BTB') {
      let studentGradeConfig = JSON.stringify(this.studentForm.value.gradeConfig)
      if(this.studentForm.value.gradeConfig == 0){
        this.student.gradeConfig = 'K';
      }
      else if(this.studentForm.value.gradeConfig == -1){
        this.student.gradeConfig = 'TK';
      }
      else{
        this.student.gradeConfig = this.studentForm.value.gradeConfig.toString();
      }

      // this.student.gradeConfig = JSON.stringify(this.studentForm.value.gradeConfig);
      this.student.firstName = this.studentForm.value.firstName;
      this.student.middleName = this.studentForm.value.middleName;
      this.student.lastName = this.studentForm.value.lastName;
      this.student.dob = moment(this.studentForm.value.dob).toDate();
      this.student.lausdId = this.studentForm.value.lausdId;
      this.student.stateId = this.studentForm.value.stateId;
      this.student.schoolId = this.studentForm.value.schoolId;
      this.student.homePhone = this.studentForm.value.homePhone;
      this.student.studentType = this.studentForm.value.studentType;
    } else {
      this.student.dob = moment(this.student.dob).toDate();
      this.student.homePhone = this.studentForm.value.homePhone;
    }
    this.studentsService.updateStudent(this.student).add(() => {
      this.modal.dismiss();
    });
    console.log(this.student);
  } 

  populateStudent() {
    console.log('Populate StudentData');

    if (this.student.source !== 'BTB') {
      this.studentForm.removeControl('lausdId');
    }

    this.studentForm.patchValue(this.student);

    if(this.student.gradeConfig === 'K'){
      this.studentForm.get('gradeConfig').setValue(0)
    }
    else if(this.student.gradeConfig === 'TK'){
      this.studentForm.get('gradeConfig').setValue(-1)
    }
    else{
      this.studentForm.get('gradeConfig').setValue(Number(this.student.gradeConfig));
    }

    this.populateSchool();
  }

  populateSchool() {
    console.log('Populate Student School');
    this.studentForm.get('schoolId').setValue(this.update ? this.student.school.id : Number(this.schoolIdContext));
    this.studentForm.get('schoolId').updateValueAndValidity();
    this.studentForm.get('programEnrollmentSchoolId').setValue(this.update ? this.student.programEnrollmentSchoolId : Number(this.schoolIdContext));
    this.studentForm.get('programEnrollmentSchoolId').updateValueAndValidity();
    console.log('config: ' + this.studentForm.get('schoolId').value);
    console.log('config: ' + this.studentForm.get('programEnrollmentSchoolId').value);
    setTimeout(() => {
      this.visible = true;
    });
  }

  ngOnInit() {
    this.gradesOnlySameSchool = [{ id: -1, value: 'TK' }, { id: 0, value: 'K' }, { id: 1, value: '1' }, { id: 2, value: '2' },
    { id: 3, value: '3' }, { id: 4, value: '4' }, { id: 5, value: '5' },
    { id: 6, value: '6' }, { id: 7, value: '7' }, { id: 8, value: '8' }];
    this.grades = this.gradesOnlySameSchool.concat([{ id: 9, value: '9' }, { id: 10, value: '10' },
    { id: 11, value: '11' }, { id: 12, value: '12' }]);
    if (!this.update || (this.update && this.student.source === 'BTB')) {
      this.studentForm = this.formBuilder.group({
        firstName: ['', [Validators.required, Validators.pattern("^[a-zA-Z '-]*$")]],
        middleName: ['', [Validators.pattern("^[a-zA-Z '-]*$")]],
        lastName: ['', [Validators.required, Validators.pattern("^[a-zA-Z '-]*$")]],
        schoolId: ['', [Validators.required, this.validateStudentAllowedToSelectedSchool(this.schoolIdContext)]],
        lausdId: ['', this.getLausdIdValidators()],
        stateId: ['', [Validators.pattern("^[0-9]*$")]],
        dob: ['', Validators.required],
        gradeConfig: ['', [Validators.required, this.validateStudentAllowedToSelectedSchool(this.schoolIdContext)]],
        homePhone: ['', [Validators.minLength(14)]],
        studentType: ['LAUSD'],
        programEnrollmentSchoolId: [''],
      });

    } else {
      this.studentForm = this.formBuilder.group({
        firstName: [''],
        middleName: [''],
        lastName: [''],
        schoolId: [''],
        lausdId: [''],
        stateId: [''],
        dob: [''],
        gradeConfig: [''],
        studentType: ['LAUSD'],
        programEnrollmentSchoolId: [''],
        homePhone: ['', [Validators.required, Validators.minLength(14)]]
      });

      this.studentForm.valueChanges.subscribe(change => {
        if (this.studentForm.dirty) {
          this.modal.backdropDismiss = false;
        }
      });
    }

    console.log(this);
    // tslint:disable-next-line: max-line-length
    this.fetchData();
  }
  ValidateInput(e){
    return new RegExp("^[a-zA-Z '-]*$").test(e.key);
    //return false;

  }
  validateStudentAllowedToSelectedSchool(schoolIdContext: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const gradesToSameSchool = [{ id: -1, value: 'TK' }, { id: 0, value: 'K' }, { id: 1, value: '1' }, { id: 2, value: '2' },
      { id: 3, value: '3' }, { id: 4, value: '4' }, { id: 5, value: '5' },
      { id: 6, value: '6' }, { id: 7, value: '7' }, { id: 8, value: '8' }];
      if (control.touched) {
        const controls: any = control.parent.controls;
        const schoolCtrl = controls.schoolId;
        const gradeCtrl = controls.gradeConfig;
        if (schoolCtrl.touched && gradeCtrl.touched) {
          schoolCtrl.setErrors(null);
        }
        gradeCtrl.setErrors(null);

        if (Number(schoolIdContext) != schoolCtrl.value && gradesToSameSchool.some(e => e.id === gradeCtrl.value)) {
          return { 'GradesInSameSchoolsViolation': true, 'requiredValue': schoolIdContext };
        }
      }
      return null;
    };
  }


  isStudentAllowedToBeOnDifferentSchool() {
    let result = false;
    const schoolCtrl = this.studentForm.controls.schoolId;
    const gradeCtrl = this.studentForm.controls.gradeConfig;
    if (schoolCtrl.errors != null) {
      if (schoolCtrl.errors.GradesInSameSchoolsViolation) {
        result = true;
      }
    }
    if (gradeCtrl.errors != null) {
      if (gradeCtrl.errors.GradesInSameSchoolsViolation) {
        result = true;
      }
    }
    return result;
  }


  studentTypeChange(event) {    
    if (event.detail.value == "LAUSD") {
      this.studentIdCaption = "LAUSD Student Id";
      this.isLausd = true;        
      this.studentForm .get('lausdId').setValidators([
        this.alphanumericValidator(),
        Validators.maxLength(10)
      ]);
    } else {
      this.studentIdCaption = "Non LAUSD Student Id";
      this.isLausd = false;      
      this.studentForm .get('lausdId').setValidators([Validators.required]);      
    }
    this.studentForm.get('lausdId').setValue('');
    this.studentForm .get('lausdId').updateValueAndValidity();
  }
  getLausdIdValidators(): ValidatorFn[] {
    const validators = [];
    if (this.studentIdCaption === "LAUSD Student Id") {
      validators.push(this.alphanumericValidator());
      validators.push(Validators.maxLength(10));
      validators.push(Validators.minLength(10));
    }
    return validators;
  }
  alphanumericValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = /^[a-zA-Z0-9]*$/.test(control.value);
      return valid ? null : { 'invalidChars': { value: control.value } };
    };
  }
}
