// used for activies, activity details, and scheduled activities
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { ToastController } from '@ionic/angular';
import { async } from '@angular/core/testing';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {

  public idToken: string;
  newActivity: any;

  constructor(public httpClient: HttpClient, public storage: Storage, private route: ActivatedRoute,
    private router: Router, public toastController: ToastController) { }
  getActivities() {
    console.log('getActivities()'); // todo: comment out logging as needed for prod
    return this.loadActivities().pipe(map(this.processActivities, this));
  }

  private loadActivities() {
    const profile = JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
    const adminRoles: number[] = [6,7,8];
    // if user has role 1 all activities are fetched
    if (adminRoles.includes(profile.roles[0])) {
      console.log('loadActivities() with url: ', environment.activitiesUrl + 'agency/' + profile.agency);
      return this.httpClient.get(environment.activitiesUrl + 'agency/' + profile.agency);
    }
    // fetching activities assigned to user's agency
    else {
      console.log('loadActivities() with url: ', environment.activitiesUrl + 'allactivities');
      return this.httpClient.get(environment.activitiesUrl + 'allactivities');
    }
  }

  getCodes(includeInactive = false) {
    console.log('getCodes()'); // todo: comment out logging as needed for prod
    return this.loadEnrichmentCodes(includeInactive).pipe(map(this.processActivities, this));
  }
  // fetching all enrichment codes
  private loadEnrichmentCodes(includeInactive: boolean) {
    console.log('loadEnrichmentCodes() with url: ', environment.enrichmentCodesUrl);
    return this.httpClient.get(environment.enrichmentCodesUrl + 'includeInactive/' + includeInactive);
  }
  // collecting user's input to create a new activity using a post request to activities endpoint
  createActivity(activity) {
    console.log('createActivity() with url: ', environment.activitiesUrl);
    // prevents user from submitting new activity without a name or enrichment code inputted
    if (activity.activityName === '' || activity.enrichmentCode === '') {
      this.showError(null, 'All fields are required.');
    } else {
      return this.httpClient.post(environment.activitiesUrl, activity).subscribe(
        result => {
          // Handle result
          console.log(result);
          this.newActivity = result;
        },
        error => {
          this.showError(error, null);
        },
        async () => {
          const toast = await this.toastController.create({
            color: 'success',
            duration: 3000,
            message: 'Success!',
            showCloseButton: true,
            position: 'top',
            buttons: [
              {
                side: 'end',
                text: 'Go To Newly Added Activity',
                handler: () => {
                  this.goToActivity(Number(this.newActivity.id));
                }
              }
            ]
          });
          // sets how long success message will show
          await toast.present();
          setTimeout(function () {
          }, 1500);
        }
      );
    }
  }
  // link created for user to navigate to newly created activity
  async goToActivity(id) {
    return this.router.navigateByUrl(
      '/activity-details/' +
      id
    );
  }
  // PUT request sent to activities endpoint to edit an activity
  updateActivity(activity) {
    console.log('updateActivity() with url: ', environment.activitiesUrl);
    // prevents user from submitting activity update without a name or enrichment code inputted
    if (activity.activityName === '' || activity.enrichmentCode === '') {
      this.showError(null, 'All fields are required.');
    } else {
      return this.httpClient.put(environment.activitiesUrl + activity.id, activity).subscribe(
        result => {
          // Handle result
          console.log(result);
        },
        error => {
          this.showError(error, null);
        },
        () => {
          this.showSuccess();
        }
      );
    }
  }
  // POST request to schedule an activity
  scheduleActivity(activity) {
    console.log('scheduleActivity() with url: ', environment.activitiesUrl + 'scheduledactivity', activity);
    return this.httpClient.post(environment.activitiesUrl + 'scheduledactivity', activity).subscribe(
      result => {
        // Handle result
        console.log(result);
        if (result === true) {
          this.showSuccess();
        } else {
          this.showError('not saved', null);
        }
      },
      error => {
        this.showError(error, null);
      },
      () => {
        this.showSuccess();
      }
    );
  }
  // PUT request to update a scheduled activity
  updateScheduledActivity(activity) {
    console.log('updateScheduledActivity() with url: ', environment.activitiesUrl + 'scheduledactivity', activity);
    return this.httpClient.put(environment.activitiesUrl + 'scheduledactivity', activity).subscribe(
      result => {
        // Handle result
        console.log(result);
        if (result === true) {
          this.showSuccess();
        } else {
          this.showError('not saved', null);
        }
      },
      error => {
        this.showError(error, null);
      },
      () => {
        this.showSuccess();
      }
    );
  }
  // fetch a student scheduled activity
  getStudentActivitiesForAgency(studentId, schoolId) {
    console.log('getStudentActivitiesForAgency()'); // todo: comment out logging as needed for prod
    return this.httpClient.get(environment.activitiesUrl +
      'checkinandtransferscheduledactivitiesofstudent/agency/' + this.profile.agency + '/student/' + studentId + '/school/' + schoolId);
  }


  // assign a student to a scheduled activity
  getActivitiesForAgency(studentId, schoolId,calendarCode) {
    console.log('getActivities()'); // todo: comment out logging as needed for prod
    return this.loadActivitiesForAgency(studentId, schoolId, calendarCode).pipe(map(this.processActivities, this));
  }

  private loadActivitiesForAgency(studentId, schoolId, calendarCode) {
    console.log('loadActivitiesForAgency() with url: ', environment.activitiesUrl + 'nonenrolledscheduledactivities/agency/' +
      this.profile.agency + '/student/' + studentId + '/school/' + schoolId + '/masterCalendarCode/' + calendarCode);
    return this.httpClient.get(environment.activitiesUrl +
      'nonenrolledscheduledactivities/agency/' + this.profile.agency + '/student/' + studentId + '/school/' + schoolId + '/masterCalendarCode/' + calendarCode);
  }
  // fetch a staff member's scheduled activities
  getActivitiesForStaffByAgency(staffId, schoolId, masterCalendarCode) {
    console.log('getActivitiesForStaffByAgency()'); // todo: comment out logging as needed for prod
    return this.loadActivitiesForStaffByAgency(staffId, schoolId, masterCalendarCode).pipe(map(this.processActivities, this));
  }

  private loadActivitiesForStaffByAgency(staffId, schoolId, masterCalendarCode) {
    console.log('nonenrolledscheduledactivities by sgency by staff by school () with url: ',
      environment.activitiesUrl + 'nonenrolledscheduledactivities/agency/' + this.profile.agency + '/staff/' +
      staffId + '/school/' + schoolId + '/masterCalendarCode/' + masterCalendarCode);
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    return this.httpClient.get(environment.activitiesUrl + 'nonenrolledscheduledactivities/agency/'
      + this.profile.agency + '/staff/' + staffId + '/school/' + schoolId + '/masterCalendarCode/' + masterCalendarCode);
  }

  private loadScheduledActivitiesForAgency() {
    console.log('loadScheduledActivitiesForAgency() with url: ', environment.activitiesUrl + 'ScheduledActivites/Agency/' +
      this.profile.agency);
    return this.httpClient.get(environment.activitiesUrl + 'scheduledactivities/agency/' + this.profile.agency);
  }


  processActivities(data: any) {
    console.log('processActivities() with', data.length, 'rows.');

    return data;
  }

  get profile() {
    return JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
  }
  // success message
  async showSuccess() {
    const toast = await this.toastController.create({
      color: 'success',
      duration: 2500,
      message: 'Success!',
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
    setTimeout(function () {
    }, 1500);
  }
  // error message
  async showError(error, message) {
    if (message === null) {
      message = 'Error!';
    }
    console.log(error);
    const toast = await this.toastController.create({
      color: 'danger',
      duration: 2500,
      message: message,
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
  }

}
