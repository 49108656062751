import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Components } from '@ionic/core';
import { PopoverController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import _ from 'lodash'
@Component({
  selector: 'signature-viewer',
  templateUrl: './signature-viewer.component.html',
  styleUrls: ['./signature-viewer.component.scss'],
})
export class SignatureViewerComponent implements OnInit {
  public staffForm: FormGroup;
  loading = false;
  @Input() image;
  @Input() modal: Components.IonModal;
  objectKeys = Object.keys;
  imagePath: any;

  constructor(public router: Router, public formBuilder: FormBuilder,
    public popoverController: PopoverController, private sanitizer: DomSanitizer) {
    this.staffForm = formBuilder.group({
      phone: ['', [Validators.required, Validators.minLength(14)]],
    });
  }

  ngOnInit() {
    console.log(this);
    this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(atob(this.image));
  }

  async cancelConfirmation(ev: any) {
    this.modal.dismiss('cancel');
  }

  get profile() {
    return JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
  }
}

