// used on funding page
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Components } from '@ionic/core';
import { FundingService } from '../funding/funding.service';
import { PopoverController } from '@ionic/angular';
import { SchoolsService } from '../schools/schools.service';
import { enumFundingSourceType } from '../funding/fundingtype.enum';
import { CancelConfirmationComponent } from '../cancel-confirmation/cancel-confirmation.component';

@Component({
  selector: 'update-funding',
  templateUrl: './update-funding.component.html',
  styleUrls: ['./update-funding.component.scss'],
})
export class UpdateFundingComponent implements OnInit {
  public fundingForm: FormGroup;
  public funderForm: FormGroup;
  loading = false;
  objectKeys = Object.keys;
  @Input() update;
  @Input() funding;
  agencies: any;
  @Input() modal: Components.IonModal;
  schools: any;
  showType = false;
  typeText: any;
  schoolIdSelected: '';
  touched: boolean;
  types = [{ id: 0, value: 'Internal' }, { id: 1, value: 'External' }, { id: 2, value: '2' }];
  items = [
    { id: -1, value: 'TK' }, { id: 0, value: 'K' }, { id: 1, value: '1' }, { id: 2, value: '2' },
    { id: 3, value: '3' }, { id: 4, value: '4' }, { id: 5, value: '5' },
    { id: 6, value: '6' }, { id: 7, value: '7' }, { id: 8, value: '8' },
    { id: 9, value: '9' }, { id: 10, value: '10' }, { id: 11, value: '11' }, { id: 12, value: '12' }];
  tab = 'funding';
  funder: any = null;
  fundersItems: any = [];
  customText: any = [];
  cohortsEnabled: boolean;
  gradeRules: any = [];

  constructor(public router: Router, public formBuilder: FormBuilder,
    private fundingService: FundingService, public popoverController: PopoverController, private schoolsService: SchoolsService) {
    this.fundingForm = formBuilder.group({
      name: ['', Validators.required],
      grantFunder: [''],
      grantId: ['', Validators.required],
      grantAmount: ['', Validators.required],
      // daysofOperation : ['', [Validators.required, Validators.pattern('^[0-9]*$')]]
    });
    this.funderForm = formBuilder.group({
      name: ['', Validators.required],
      shortCode: ['', Validators.required],
      isRestricted: ['', Validators.required],
      isTrackedbyCohorts: [false, Validators.required],
      isGovFund: [false, Validators.required],
      gradeRestriction: ['', Validators.required]
    });
  }

  optionsFn(item) {
    console.log(item);
  }

  addFunder() {
    this.tab = 'funder';
  }
  funderCancel() {
    this.tab = 'funding';
  }

  selectionChanged(event) {
    this.cohortsEnabled = event[0].data.isTrackedbyCohorts;
    this.fundingForm.get('grantFunder').patchValue(event[0].data);
    if (this.cohortsEnabled) {
      let control = new FormControl('', Validators.required);
      this.fundingForm.addControl('cohort', control);
    } else {
      this.fundingForm.removeControl('cohort');
    }
  }


  toggle(event: Event): void {
    const element: any = (event.target as Element);
    const classList = element.classList;
    const classes = element.className;
    const val = element.attributes.getNamedItem('id');
    if (classes.includes('btn-days-remove')) {
      classList.remove('btn-days-remove');
      classList.add('btn-days-add');
      this.gradeRules.push(val.value);
      // this.gradeRules.sort(function (a, b) { return a - b; });
    } else {
      classList.add('btn-days-remove');
      classList.remove('btn-days-add');
      this.gradeRules = this.gradeRules.filter(item => item !== val.value);
    }
    if (this.gradeRules.length >= 1) {
      this.funderForm.get('gradeRestriction').setValue(this.gradeRules.map(Number));
    } else {
      this.funderForm.get('gradeRestriction').setValue(null);
    }
  }
  // users cannot submit funder with gap in grades selected
  hasGap(array) {
    if (array == null || array.length === 0) {
      return false;
    }
    if (array[array.length - 1] - array[0] + 1 != array.length) {
      return true;
    }
    let i = 1;
    for (i = 1; i < array.length; i++) {
      if (array[i] !== array[i - 1] + 1) {
        return true;
      }
    }
    return false;
  }

  typeChange(): void {
    if (this.fundingForm.value.fundingSourceType === '1') {
      this.typeText = 'Attendance will only be valid on Instructional Days';
    } else if (this.fundingForm.value.fundingSourceType === '2') {
      this.typeText = 'Manually enter data ranges when to count attendance';
    }
  }
  // type input only shows if funder is restricted
  showTypeRestricted() {
    console.log(this.fundingForm.value.isRestricted);
    if (this.fundingForm.value.isRestricted === 'true') {
      this.showType = true;
    } else {
      this.showType = false;
    }
  }

  async cancelConfirmation(ev: any) {
    const popover = await this.popoverController.create({
      component: CancelConfirmationComponent,
      event: ev,
      translucent: true,
      componentProps: { modal: this.modal },
    });
    return await popover.present();
  }
  // create a new funder
  createFunder() {
    let funder = this.funderForm.value;
    // const start = this.gradeRules[0] === 0 ? 'K' : this.gradeRules[0];
    funder.gradeRestriction = this.gradeRules.join(); // start + '-' + this.gradeRules[this.gradeRules.length - 1];
    funder.source = 'BTB';
    this.fundingService.createFunder(funder).subscribe((funder: any) => {
      this.fundingService.funders().subscribe((funders: any) => {
        funders.forEach(x => {
          x.customText = x.shortCode + ' - ' + x.name;
        });
        const funderToAdd = funders.pop();
        funders.unshift(funderToAdd);
        this.fundersItems = funders;
        this.tab = 'funding';
        this.fundingForm.patchValue(funder);
        this.fundingForm.get('name').setValue(funder.name);
        this.fundingForm.get('grantFunder').setValue(funder);
      })
    })
  }
  // create new funding
  createFunding() {
    console.log('fundingform ' + this.fundingForm);
    console.log(this.fundingForm.value.fundingForm);
    const funding = this.fundingForm.value;
    funding.funderId = funding.grantFunder.id;
    funding.cohort = (funding.cohort === '' || funding.cohort === null || funding.cohort === undefined) ? 'N/A' : funding.cohort;
    this.fundingService.createFunding(funding, this.modal);
    this.modal.dismiss();
  }

  ngOnInit() {
    console.log(this);
    this.fundingForm.statusChanges.subscribe(change => {
      if (this.fundingForm.dirty) {
        this.modal.backdropDismiss = false;
      }
    });
    this.funderForm.statusChanges.subscribe(change => {
      if (this.funderForm.dirty) {
        this.modal.backdropDismiss = false;
      }
    });
    // tslint:disable-next-line: max-line-length
  }
  ngAfterViewInit() {
    this.fundingService.funders().subscribe((funders: any) => {
      // format funder name to: short code - name
      funders.forEach(x => {
        x.customText = x.shortCode + ' - ' + x.name;
      })
      this.fundersItems = funders;
    });
  }

}

