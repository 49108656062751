export class StringUtil {

    static allPeriods = /\./g;
    static oneOrMoreWhitespace = /\s+/;
    static twoOrMoreWhitespace = /\s{2,}/;
    static allMultipleWhitespace = /\s+/g;
    static endingInSpaceDot = /\s\.$/;
    static nbsp = /\u00a0/g;
    static ampersand = /&amp;/g;
    static numberDashNumber = /^\d+-\d+/;
    static plainNumber = /^\d+$/;
    // See: https://html.spec.whatwg.org/#valid-e-mail-address
    // tslint:disable-next-line:max-line-length
    static emailAddress = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    static escapeSpecialCharacters(input: string): string {
        return input.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    }
}
