// used in update award modal
import { Component, OnInit, Input } from '@angular/core';
import { Components } from '@ionic/core';
import { AwardsService } from '../awards/awards.service';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'deactivate-award',
  templateUrl: './deactivate-award.component.html',
  styleUrls: ['./deactivate-award.component.scss'],
})
export class DeactivateAwardComponent implements OnInit {
  @Input() popover: Components.IonPopover;
  @Input() awardId: any;
  constructor(public awardsService: AwardsService, private toastCtrl: ToastController) { }

  ngOnInit() {
    console.log(this.awardId);
  }
  // deactivate award
  deactivateAward() {
    return this.awardsService.deactivateAward(this.awardId).add(() => {
      this.popover.dismiss();
    });
  }
}
