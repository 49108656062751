// used for award details page
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AwardDetailsService {
  public idToken: string;
  constructor(public httpClient: HttpClient) { }
  // fetch award details by ID
  getAwardDetails(id: number) {
    console.log('getAwardDetails()'); // todo: comment out logging as needed for prod
    return this.loadAwardDetails(id);
  }
  // fetch award details by ID
  private loadAwardDetails(id: number) {
    console.log('loadAwardDetails() with url: ', environment.awardsUrl + id);
    return this.httpClient.get(environment.awardsUrl + id);
  }
  // fetch dashboard data by agency ID
  getDashboard(id: number) {
    console.log('getDashboard()'); // todo: comment out logging as needed for prod
    return this.loadDashboard(id).pipe(map(this.processDashboard, this));
  }
  // fetch dashboard data by agency ID
  private loadDashboard(id: number) {
    console.log('loadDashboard() with url: ', environment.agenciesUrl + id);
    return this.httpClient.get(environment.agenciesUrl + id);
  }
  // fetch dashboard data by agency ID
  processDashboard(data: any) {

    return data;
  }

}
