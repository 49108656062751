import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss'],
})
export class ModalAlertComponent implements OnInit {

  @Input() public props: ModalComponent;

  constructor(private modalController: ModalController) { }

  ngOnInit() {
    if (this.props.messageDialog === undefined) {
      this.props.messageDialog = false;
    }
  }

  async closeModal() {
    await this.modalController.dismiss(this.props);
  }
}

export interface ModalComponent {
  header: string;
  body: string;
  okText?: string;
  cancelText?: string;
  model: any;
  messageDialog?: boolean;
}
