const Holidays = [
  {
    date: 1,
    month: 1,
    title: 'New Year\'s Day',
  },
  {
    date: 2,
    month: 2,
    title: 'Groundhog Day',
  },
  {
    date: 14,
    month: 2,
    title: 'Valentine\'s Day',
  },
  {
    date: 17,
    month: 3,
    title: 'St. Patrick\'s Day',
  },
  {
    date: 22,
    month: 4,
    title: 'Earth Day',
  },
  {
    date: 4,
    month: 7,
    title: 'Independence Day',
  },
  {
    date: 19,
    month: 4,
    title: 'Patriot Day',
  },
  {
    date: 31,
    month: 10,
    title: 'Halloween',
  }, {
    date: 11,
    month: 11,
    title: 'Veterans\' Day',
  },
  {
    date: 7,
    month: 12,
    title: 'Pearl Harbor Day',
  },
  {
    date: 25,
    month: 12,
    title: 'Christmas Day',
  },
  {
    date: 31,
    month: 12,
    title: 'New Year\'s Eve',
  },
];

export default Holidays;
