import { SupplementalCalendarComponent } from './master-calendar-editor/supplemental-calendar/supplemental-calendar.component';
import { MasterCalendarViewComponent } from './master-calendar-editor/master-calendar-view.component';
import { MasterCalendarPageComponent } from './master-calendar-page/master-calendar-page.component';
import { CalendarEditorComponent } from './calendar-editor/calendar-editor.component';
import { AppModule } from './app.module';
import { ScheduleActivityComponent } from './schedule-activity/schedule-activity.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, Params } from '@angular/router';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthServiceProvider } from './users/auth-service/auth-service';
import { AllSchoolsResolver } from './core/resolver/all-schools.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'home',
    data: {
      title: ' ',
    },
    loadChildren: () => import('./home/home-module').then((m) => m.HomeModule),
  },

  {
    path: 'funding',
    data: {
      parent: 'Funding',
      title: 'Funding',
      subTitle: '',
      root: true,
      allowedRoles: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    loadChildren: () =>
      import('./funding/funding.module').then((m) => m.FundingPageModule),
  },
  {
    path: 'schools',
    resolve: { schoolList: AllSchoolsResolver },
    data: {
      parent: 'Schools',
      title: 'Schools',
      subTitle: '',
      root: true,
      allowedRoles: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    loadChildren: () =>
      import('./schools/schools.module').then((m) => m.SchoolsPageModule),
  },
  {
    path: 'school-students',
    resolve: { schoolList: AllSchoolsResolver },
    canActivate: [AuthServiceProvider],
    data: {
      parent: 'Students',
      title: 'Students',
      subTitle: '',
      root: true,
      allowedRoles: [4, 6, 7, 8]
    },
    loadChildren: () =>
      import('./school-to-students/schoolToStudents.module').then(
        (m) => m.SchoolToStudentsModule
      ),
  },
  {
    path: 'school-staff',
    resolve: { schoolList: AllSchoolsResolver },
    data: {
      parent: 'Staff',
      title: 'Staff',
      subTitle: '',
      root: true,
      allowedRoles: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    loadChildren: () =>
      import('./school-to-staff/schoolToStaff.module').then(
        (m) => m.SchoolToStaffModule
      ),
  },
  {
    path: 'school-details/:id',
    data: {
      parent: 'Schools',
      title: 'Schools',
      subTitle: '',
      tabs: [
        { name: 'Activities', path: 'activities' },
        { name: 'Awards', path: 'awards' },
        { name: 'Missed Attendance', path: 'attendance' },
        { name: 'Summer Calendar', path: 'calendar' },
        { name: 'Details', path: 'details' },
        { name: 'Delete Attendance', path: 'deleteattendance' },
      ],
      allowedRoles: [1, 2, 3, 4, 5, 6, 7, 8],
    },
    loadChildren: () =>
      import('./school-details/school-details.module').then(
        (m) => m.SchoolDetailsPageModule
      ),
  },
  {
    path: 'school-details/:id/activity/:actId',
    data: {
      parent: 'Schools',
      title: 'Schools',
    },
    loadChildren: () =>
      import(
        './school-details/school-activities/school-activities.module'
      ).then((m) => m.SchoolActivitiesPageModule),
  },
  {
    path: 'school-details/:id/activity/:actId/mastercalendar/:mc',
    data: {
      parent: 'Schools',
      title: 'Schools',
    },
    loadChildren: () =>
      import(
        './school-details/school-activities/school-activities.module'
      ).then((m) => m.SchoolActivitiesPageModule),
  },
  {
    path: 'school-details/:id/activity/:actId/scheduled-activity/:scheduledId/:mc',
    canActivate: [AuthServiceProvider],
    resolve: { schoolList: AllSchoolsResolver },
    data: {
      parent: 'Schools',
      title: 'Scheduled Activity',
      subTitle: '',
      tabs: [
        { name: 'Details', path: 'details' },
        { name: 'Roster', path: 'roster' },
        { name: 'History', path: 'history' }
      ],
      allowedRoles: [6, 7, 8]
    },
    loadChildren: () =>
      import('./scheduled-activity-details/scheduled-activity-details.module').then(m => m.ScheduledActivityDetailsPageModule)
  },
  // {
  //   path: 'school-details/:id/activity/:actId/scheduled-activity/:scheduledId/:mc',
  //   canActivate: [AuthServiceProvider],
  //   resolve: { schoolList: AllSchoolsResolver },
  //   data: {
  //     parent: 'Schools',
  //     title: 'Scheduled Activity',
  //     subTitle: '',
  //     tabs: [
  //       { name: 'Details', path: 'details' },
  //       { name: 'Roster', path: 'roster' },
  //       { name: 'History', path: 'history' }
  //     ],
  //     allowedRoles: [6, 7, 8]
  //   },
  //   loadChildren: () =>
  //     import('./scheduled-activity-details/scheduled-activity-details.module').then(m => m.ScheduledActivityDetailsPageModule)
  // },
  // {
  //   path: 'school-details/:id/activity/:actId/scheduled-activity/:scheduledId/:caltype/:calid/:caliddes/:tab',
  //   canActivate: [AuthServiceProvider],
  //   resolve: { schoolList: AllSchoolsResolver },
  //   data: {
  //     parent: 'Schools',
  //     title: 'Scheduled Activity',
  //     subTitle: '',
  //     tabs: [
  //       { name: 'Details', path: 'details' },
  //       { name: 'Roster', path: 'roster' },
  //       { name: 'History', path: 'history' }
  //     ],
  //     allowedRoles: [6, 7, 8]
  //   },
  //   loadChildren: () =>
  //     import('./scheduled-activity-details/scheduled-activity-details.module').then(m => m.ScheduledActivityDetailsPageModule)
  // },
  {
    path: 'school-details/:id/award/:id',
    data: {
      parent: 'Schools',
      tabs: [
        { name: 'Performance', path: 'performance' },
        { name: 'Details', path: 'details' }
      ],
      allowedRoles: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    loadChildren: () =>
      import('./award-details/award-details.module').then(
        (m) => m.AwardDetailsPageModule
      ),
  },
  {
    path: 'students',
    data: {
      parent: 'Students',
      title: 'Students',
    },
    loadChildren: () =>
      import('./students/students.module').then((m) => m.StudentsPageModule),
  },
  {
    path: 'dashboard',
    data: {
      parent: 'Dashboard',
      title: 'Dashboard',
      root: true,
      allowedRoles: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
  },
  {
    path: 'school-students/:id',
    canActivate: [AuthServiceProvider],
    data: {
      parent: 'Students',
      title: 'Students',
      root: true,
      allowedRoles: [4, 6, 7, 8],
    },
    loadChildren: () =>
      import('./students/students.module').then((m) => m.StudentsPageModule),
  },
  {
    path: 'school-staff/:id',
    data: {
      parent: 'Staff',
      title: 'Staff',
    },
    loadChildren: () =>
      import('./staff/staff.module').then((m) => m.StaffPageModule),
  },
  {
    path: 'funding-details/:id',
    data: {
      parent: 'Funding',
      title: 'Funding',
    },
    loadChildren: () =>
      import('./funding-details/funding-details.module').then(
        (m) => m.FundingDetailsPageModule
      ),
  },
  {
    path: 'student-details/:id/:schoolid',
    canActivate: [AuthServiceProvider],
    data: {
      parent: 'Students',
      title: 'Students',
      subTitle: '',
      allowedRoles: [4, 6, 7, 8],
      tabs: [
        { name: 'Profile', path: 'profile' },
        { name: 'Schedule', path: 'schedule' },
        { name: 'History', path: 'history' },
      ],
    },
    loadChildren: () =>
      import('./student-details/student-details.module').then(
        (m) => m.StudentDetailsPageModule
      ),
  },
  {
    path: 'student-details/:id/:schoolid/:caltype/:calid',
    canActivate: [AuthServiceProvider],
    data: {
      parent: 'Students',
      title: 'Students',
      subTitle: '',
      allowedRoles: [4, 6, 7, 8],
      tabs: [
        { name: 'Profile', path: 'profile' },
        { name: 'Schedule', path: 'schedule' },
        { name: 'History', path: 'history' },
      ],
    },
    loadChildren: () =>
      import('./student-details/student-details.module').then(
        (m) => m.StudentDetailsPageModule
      ),
  },
  {
    path: 'staff',
    data: {
      parent: 'Staff',
      title: 'Staff',
    },
    canActivate: [AuthServiceProvider],
    loadChildren: () =>
      import('./staff/staff.module').then((m) => m.StaffPageModule),
  },
  {
    path: 'staff-details/:id',
    canActivate: [AuthServiceProvider],
    data: {
      parent: 'Staff',
      title: 'Staff',
      subTitle: '',
      tabs: [
        { name: 'Details', path: 'details' },
        { name: 'Schedule', path: 'schedule' },
        { name: 'History', path: 'history' },
      ],
      allowedRoles: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    loadChildren: () =>
      import('./staff-details/staff-details.module').then(
        (m) => m.StaffDetailsPageModule
      ),
  },
  {
    path: 'activities',
    canActivate: [AuthServiceProvider],
    data: {
      parent: 'Activities',
      title: 'Activities',
      subTitle: '',
      root: true,
      allowedRoles: [6, 7, 8]
    },
    loadChildren: () =>
      import('./activities/activities.module').then(
        (m) => m.ActivitiesPageModule
      ),
  },

  {
    path: 'activity-details/:id',
    canActivate: [AuthServiceProvider],
    resolve: { schoolList: AllSchoolsResolver },
    data: {
      parent: 'Activities',
      title: 'Activities',
      subTitle: '',
      tabs: [
        { name: 'Details', path: 'details' },
        { name: 'Schools', path: 'schools' },
      ],
      allowedRoles: [6, 7, 8]
    },
    loadChildren: () =>
      import('./activity-details/activity-details.module').then(
        (m) => m.ActivityDetailsPageModule
      ),
  },
  {
    path: 'agencies',
    canActivate: [AuthServiceProvider],
    data: {
      parent: 'Agencies',
      title: 'Agencies',
      subTitle: '',
      root: true,
      allowedRoles: [1, 2, 3, 4, 5, 8]
    },
    loadChildren: () =>
      import('./agencies/agencies.module').then((m) => m.AgenciesPageModule),
  },
  {
    path: 'agency-details/:id',
    canActivate: [AuthServiceProvider],
    data: {
      parent: 'Agencies',
      title: 'Agencies',
      subTitle: '',
      allowedRoles: [1, 2, 3, 4, 5, 8]
    },
    loadChildren: () =>
      import('./agency-details/agency-details.module').then(
        (m) => m.AgencyDetailsPageModule
      ),
  },
  {
    path: 'auth-callback',
    component: AuthCallbackComponent,
  },
  {
    path: 'awards/:id',
    data: {
      parent: 'Funding',
      title: 'Funding',
      subTitle: '',
      root: true,
      allowedRoles: [1, 2, 3, 4, 5],
      tabs: [
        { name: 'Awards', path: 'awards' },
        { name: 'Funding Details', path: 'details', roles: [1, 2, 3, 4, 5] }
      ]
    },
    loadChildren: () =>
      import('./awards/awards.module').then((m) => m.AwardsPageModule),
  },
  {
    path: 'awards',
    data: {
      parent: 'Funding',
      title: 'Awards',
      subTitle: '',
      root: true,
      allowedRoles: [6, 7, 8, 9],
      tabs: [
        { name: 'Awards', path: 'awards' }
      ]
    },
    loadChildren: () =>
      import('./awards/awards.module').then((m) => m.AwardsPageModule),
  },
  {
    path: 'award-details/:id',
    data: {
      parent: 'Funding',
      tabs: [
        { name: 'Performance', path: 'performance' },
        { name: 'Details', path: 'details' }
      ],
      allowedRoles: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    loadChildren: () =>
      import('./award-details/award-details.module').then(
        (m) => m.AwardDetailsPageModule
      ),
  },
  {
    path: 'reports',
    data: {
      parent: 'Reports',
      title: 'Reports',
      root: true,
      allowedRoles: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsPageModule),
  },
  {
    path: 'master-calendar',
    canActivate: [AuthServiceProvider],
    resolve: { shoolList: AllSchoolsResolver },
    data: {
      parent: 'Admin',
      title: 'Core Calendar',
      subTitle: '',
      root: true,
      allowedRoles: [1, 2, 3, 4, 5],
      tabs: [
        { name: 'Calendar', path: 'calendar' },
        { name: 'Exceptions', path: 'exceptions', roles: [1, 2, 3, 4, 5] },
        { name: 'Master Calendar', path: 'mastercalendar', roles: [1, 2, 3, 4, 5] },
        { name: 'Missed Attendance', path: 'attendance', roles: [1,2,3,4,5] },
        { name: 'Delete Attendances', path: 'deleteattendances', roles: [1,2,3,4,5] },
        { name: 'Student Attendance Disputes', path: 'disputes', roles: [4] },
        { name: 'Enrichment Codes', path: 'enrichment-codes', roles: [1, 4] },
        { name: 'Certification Types', path: 'certs', roles: [1, 4] }
      ],
    },

    component: MasterCalendarPageComponent,
  },
  {
    path: 'master-calendar-editor/:id',
    canActivate: [AuthServiceProvider],
    resolve: { schoolList: AllSchoolsResolver },
    data: {
      parent: 'Admin',
      title: 'Calendar',
      subTitle: '',
      tabs: [
        { name: 'Calendar', path: 'calendar' },
        // { name: 'Schools', path: 'schools' },
      ],
      allowedRoles: [1, 2, 3, 4, 5]
    },
    component: MasterCalendarViewComponent,
  },
  {
    path: 'master-calendar-editor',
    resolve: { schoolList: AllSchoolsResolver },
    data: {
      parent: 'Admin',
      title: 'Calendar',
      subTitle: '',
      tabs: [
        { name: 'Calendar', path: 'calendar' },
        // { name: 'Schools', path: 'schools' },
      ],
      allowedRoles: [1, 2, 3, 4, 5]
    },
    component: MasterCalendarViewComponent,
  },
  {
    path: 'school-details/:id/calendar/:calendarId',
    component: SupplementalCalendarComponent,
    canActivate: [AuthServiceProvider],
    resolve: { schoolList: AllSchoolsResolver },
    data: {
      parent: 'Schools',
      tabs: [
        { name: 'Activities', path: 'activities', customRoute: `/school-details/{id}` },
        { name: 'Awards', path: 'awards', customRoute: `/school-details/{id}` },
        { name: 'Missed Attendance', path: 'attendance', customRoute: `/school-details/{id}` },
        { name: 'Summer Calendar', path: 'calendar', selected: true },
        { name: 'Details', path: 'details', customRoute: `/school-details/{id}` },
        { name: 'Delete Attendance', path: 'deleteattendance', customRoute: `/school-details/{id}` }
      ],
      allowedRoles: [1, 2, 3, 4, 5, 6, 7, 8]
    }
  },
  {
    path: 'unauthorized',
    data: {
      title: 'Unauthorized',
    },
    loadChildren: () => import('./unauthorized/unauthorized.module').then(m => m.UnauthorizedPageModule)
  },
  {
    path: 'errors/:id/:msg',
    data: {
      title: 'Errors',
    },
    loadChildren: () => import('./errors/errors.module').then(m => m.ErrorsPageModule)
  },

];
@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

