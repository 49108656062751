// used on awa
import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Components } from '@ionic/core';
import { StaffService } from '../staff/staff.service';
import { ModalComponent, ModalAlertComponent } from '../modal-alert/modal-alert.component';
import { ModalController } from '@ionic/angular';
import { UserData } from '../users/user-data';
import { AuthServiceProvider } from '../users/auth-service/auth-service';

@Component({
  selector: 'user-agency',
  templateUrl: './user-agency.component.html',
  styleUrls: ['./user-agency.component.scss'],
})
export class UserAgencyComponent implements OnInit {
  public userAgencyForm: FormGroup;
  loading = false;
  userAgencies: any = [];
  selectedDatasource: any;
  selectedAgencyText: any = 'Loading. Please wait...';
  roles: any;
  @Input() userName;
  @Input() modal: Components.IonModal;

  constructor(public router: Router, public formBuilder: FormBuilder,
    public modalController: ModalController,
    public userData: UserData,
    public authService: AuthServiceProvider,
    public staffService: StaffService) {
    this.userAgencyForm = formBuilder.group({
      agencyId: ['', [Validators.required, Validators.minLength(14)]],
    });
  }

  ngOnInit() {
    console.log(this);
    this.userAgencyForm.valueChanges.subscribe(change => {
      if (this.userAgencyForm.dirty) {
        this.modal.backdropDismiss = false;
      }
    });
    if(this.userName != null){
      this.fetchData();
    }
  }

  fetchData() {
    setTimeout(() => {
      this.staffService.getStaffInfoByLoginName(this.userName).subscribe(async (data: any) => {
        this.userAgencies = data.agencies.filter(x => x.isActive);
        this.roles = data.roles;
        console.log('user-agency componenet :  fetchData()',this.userAgencies);
        this.selectedDatasource = this.userAgencies[0];
        this.selectedAgencyText = 'Choose an Agency to Proceed';
      });
    },300)
  }
  agencyType: any
  selectionChanged(event) {
    console.log('Use ', event[0].data.name)
    this.selectedAgencyText = 'Use ' + event[0].data.name;
    this.agencyType = event[0].data.type;
  }

  updateUserAgency() {
    console.log(this.userAgencyForm.value);
    var data = {
      agencyId: this.userAgencyForm.value.agencyId,
      agencyType: this.agencyType,
      role: this.roles[this.userAgencyForm.value.agencyId]
    }
    this.modal.dismiss(data);
  }

  async logout() {
    const options: ModalComponent = {
      header: 'Logout',
      body: 'Are you sure you want to log out?',
      model: { canLogout: true }
    };

    const modal = await this.modalController.create({
      component: ModalAlertComponent,
      componentProps: {
        props: options
      }
    });

    modal.onDidDismiss().then(({ data }) => {
      if (data.model) {
        const { canLogout } = data.model;
        if (canLogout) {
          this.userData.logout().then(() => {
            this.authService.signOut();
          });
        }
      }
    });
    return await modal.present();
  }
}

