import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import * as m from 'moment';
import _ from 'lodash';
import { CalendarEvent } from './CalendarEvent';
import { CalendarLayer } from './CalendarLayer';
import { CalendarEntry } from './CalendarEntry';
import { CalendarGroup } from './CalendarGroup';
import { DateRange } from './DateRange';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'calendar-editor',
  templateUrl: './calendar-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./calendar-editor.component.scss'],
})
export class CalendarEditorComponent implements OnInit {
  weekHeader: Array<string> = [];
  calendar: Array<CalendarGroup> = new Array<CalendarGroup>();
  layers: Array<CalendarLayer> = new Array<CalendarLayer>();
  @Input() events: Array<CalendarEvent> = new Array<CalendarEvent>();
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() dateRanges: DateRange[];
  @Input() removeWeekends = false;
  ranges = [];
  @Input() dayColor = '#85e085';
  constructor(private ref: ChangeDetectorRef) { }

  ngOnInit() {
    console.log('here- ---CALENDAR EDITOR INPUT DATERANGES', this.dateRanges);
    this.initLayers();
    // this.fillCalendar();
    // this.ref.detectChanges();
  }
  initLayers() {
    if (this.layers.length == 0 && this.startDate && this.endDate) {
      this.layers.push({
        color: this.dayColor,
        dateRanges: this.dateRanges,
        layerStart: this.startDate,
        layerEnd: this.endDate,
        title: 'default',
      });
    }
    if (this.layers.length > 0) {
      this.layers[0].color = this.dayColor;
      this.layers[0].layerStart = this.startDate;
      this.layers[0].layerEnd = this.endDate;
      this.layers[0].dateRanges = this.dateRanges;
    }
    console.log('here- , DateRanges Init Layer', this.layers);
  }
  refresh() {
    this.initLayers();
    this.fillCalendar();
    this.ref.detectChanges();
  }
  // toggleWeekend(){
  //   this.removeWeekends = !this.removeWeekends;
  //   this.fillCalendar();
  // }
  addPeriod() {
    this.ranges.push({});
  }

  header() { }
  monthName(group: CalendarGroup) {
    return m(group.date).format('MMM YYYY');
  }
  getLayerColor(group: CalendarGroup, entry: CalendarEntry) {
    if (entry.date == 0 || group.layers.length == 0) {
      return null;
    }

    const date = m(group.date).date(entry.date);

    console.log(this.startDate);
    console.log(this.endDate);
    if(!date.isBetween(this.startDate, this.endDate)){
      let transparentStyle = {
        'background-color': 'transparent',
      };
      return transparentStyle;
    }

    const priorityLayer = group.layers[0];

    let color = priorityLayer.color;
    if (priorityLayer != null) {
      const ranges = priorityLayer.dateRanges.filter((range) =>
        date.isBetween(range.start, range.end, 'day', '[]')
      );
      color = ranges.length > 0 ? ranges[0].color : color;

      if (ranges.length > 0) {
        const supplementalExceptionalDay = ranges.filter((range) => range.isExceptionalDay);
        color = supplementalExceptionalDay.length > 0 ? supplementalExceptionalDay[0].color : color;
      }
    }

    let style = {
      'background-color': color,
    };
    style = this.weekendStyle(style, date);
    style = this.eventStyle(style, date);

    return style;
  }

  eventStyle(style: any, date: m.Moment) {
    const event = this.events.find(
      (e) => m(e.date).isSame(date, 'day') && e.isEnabled
    );
    if (event != null) {
      style['background-color'] = '#ECC9C7';
      // style["background-color"] = "#ff4d4d";
    }
    return style;
  }
  weekendStyle(style: any, date: m.Moment) {
    if (this.removeWeekends) {
      const dayOfWeek = date.day();
      if (dayOfWeek == 6 || dayOfWeek == 0) {
        style['background-color'] = 'gray';
      }
    }
    return style;
  }
  toJson() {
    const calendar = {
      startDate: this.startDate,
      endDate: this.endDate,
      dateRanges: this.dateRanges,
      events: this.events,
      removeWeekends: this.removeWeekends,
    };
    return calendar;
  }
  fillCalendar() {
    this.calendar = [];
    const startDate = this.startDate;
    const endDate = this.endDate;
    const calendarDate = m(startDate);
    const diffMonth = m(endDate).diff(startDate, 'month');
    this.layers = _.sortBy(this.layers, 'layerStart');

    // for each month in the year
    for (let i = 0; i <= diffMonth + 1; i++) {
      const ctd = i == 0 ? 0 : 1;
      const month = calendarDate.add(ctd, 'M').date(1);
      // start weekday on the month
      const startWeekDay = month.isoWeekday() - 1;
      const endMonth = month.endOf('month').date();
      let monthCounter = 0;
      // retrieve events for current month
      const monthEvents = this.events.filter(
        (e) => month.month() == e.date.getMonth()
      );
      const calendarGroup: CalendarGroup = {
        date: month.clone(), // month.clone(),
        entries: new Array<CalendarEntry>(),
        layers: new Array<CalendarLayer>(),
      };
      for (let j = 0; j < 42; j++) {
        const monthDay = startWeekDay <= j && monthCounter <= endMonth ? 1 : 0;
        if (monthCounter < endMonth) {
          monthCounter = monthDay == 0 ? 0 : monthCounter + monthDay;
          const entry: CalendarEntry = {
            date: monthCounter,
            events: monthEvents.filter((e) => e.date.getDate() == monthCounter),
          };
          calendarGroup.entries.push(entry);
        } else {
          const entry: CalendarEntry = {
            date: 0,
          };
          calendarGroup.entries.push(entry);
        }
      }
      calendarGroup.layers = this.layers;
      this.calendar.push(calendarGroup);
    }
    this.weekHeader = [];
    const weekDayNames = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
    for (let i = 0; i < 6; i++) {
      for (let j = 0; j < weekDayNames.length; j++) {
        this.weekHeader.push(weekDayNames[j]);
      }
    }
  }
}
