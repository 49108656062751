// used for add assigned agencies, awards page, deactivate awards, generate reports, update award amts, and update awards
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { ToastController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AwardsService {

  public idToken: string;
  newAward: any;

  constructor(public httpClient: HttpClient, public storage: Storage, private route: ActivatedRoute,
    private router: Router, public toastController: ToastController) { }
  // fetch awards
  getAwards(id: number) {
    console.log('getAwards()' + id); // todo: comment out logging as needed for prod
    return this.loadAwards(id).pipe(map(this.processAwards, this));
  }
  // fetch awards
  private loadAwards(id: number) {
    const profile = JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
    const adminRoles: number[] = [1, 2, 3, 4, 5];
    if (adminRoles.includes(profile.roles[0])) {
      console.log('loadAwards() with url: ', environment.awardsUrl + 'funding/' + id);
      return this.httpClient.get(environment.awardsUrl + 'funding/' + id);
    } else if(profile.roles[0] == 6){
      console.log('loadAwards() with url: ', environment.awardsUrl + 'agency/' + profile.agency);
      return this.httpClient.get(environment.awardsUrl + 'agency/' + profile.agency);
    } else if(profile.roles[0] == 7 || profile.roles[0] == 8){
      console.log('loadAwards() with url: ', environment.awardsUrl + 'staff/' + profile.name + '/agency/' + profile.agency);
      return this.httpClient.get(environment.awardsUrl + 'staff/' + profile.name + '/agency/' + profile.agency);
    } else {
      console.log('loadAwards() with url: ', environment.awardsUrl);
      return this.httpClient.get(environment.awardsUrl + 'allawards');
    }
  }

  getAwardsByCriteria(id: number, criteria: string, page: number, pageSize: number) {
    console.log('getAwards()' + criteria); // todo: comment out logging as needed for prod
    return this.loadAwardsByCriteria(id, criteria, page, pageSize).pipe(map(this.processAwards, this));
  }
  private loadAwardsByCriteria(id: number, criteria: string, page: number, pageSize: number) {
    const profile = JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
    const adminRoles: number[] = [1, 2, 3, 4, 5];
    if (adminRoles.includes(profile.roles[0])) {
      console.log('loadAwards() with url: ', environment.awardsUrl + 'funding/' + id + '/search/'
        + criteria + '/paging/' + page + '/' + pageSize);
      return this.httpClient.get(environment.awardsUrl + 'funding/' + id + '/search/' + criteria + '/paging/' + page + '/' + pageSize);
    } else if(profile.roles[0] == 6 || profile.roles[0] == 7 || profile.roles[0] == 8){
      console.log('loadAwards() with url: ', environment.awardsUrl + 'agency/' + profile.agency + '/search/' + criteria + '/paging/' + page + '/' + pageSize);
      return this.httpClient.get(environment.awardsUrl + 'agency/' + profile.agency + '/search/' + criteria + '/paging/' + page + '/' + pageSize);
    } else {
      console.log('loadAwards() with url: ', environment.awardsUrl + 'searchall/' + criteria + '/paging/' + page + '/' + pageSize);
      return this.httpClient.get(environment.awardsUrl + 'searchall/' + criteria + '/paging/' + page + '/' + pageSize);
    }
  }
  // fetch awards by school ID
  getAwardsBySchoolId(id: number) {
    console.log('getAwards()' + id); // todo: comment out logging as needed for prod
    return this.loadAwardsBySchoolId(id).pipe(map(this.processAwards, this));
  }
  private loadAwardsBySchoolId(id: number) {
    const profile = JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
    const adminRoles: number[] = [1, 2, 3, 4, 5];
    if (adminRoles.includes(profile.roles[0])) {
      console.log('loadAwards() with url: ', environment.awardsUrl + 'school/' + id);
      return this.httpClient.get(environment.awardsUrl + 'school/' + id);
    } else if(profile.roles[0] == 6 || profile.roles[0] == 7 || profile.roles[0] == 8){
      console.log('loadAwards() with url: ', environment.awardsUrl + 'location/' + id + '/agency/' + environment.agencyId);
      return this.httpClient.get(environment.awardsUrl + 'location/' + id + '/agency/' + environment.agencyId);
    } else {
      console.log('loadAwards() with url: ', environment.awardsUrl + 'school/' + id);
      return this.httpClient.get(environment.awardsUrl + 'school/' + id);
    }
  }

  processAwards(data: any) {
    // console.log('processAwards() with', data.length, 'rows.');
    return data;
  }
  // PUT request to update award details
  updateAward(award) {
    console.log('createAward() with url: ', environment.awardsUrl);
    return this.httpClient.put(environment.awardsUrl + award.id, award).subscribe(
      result => {
        // Handle result
        console.log(result);
      },
      error => {
        this.showError(error);
      },
      () => {
        this.showSuccess();
      }
    );
  }
  // DELETE request to deactivate award
  deactivateAward(awardId) {
    console.log('deactivateAward() with url: ', environment.awardsUrl + awardId);
    return this.httpClient.delete(environment.awardsUrl + awardId).subscribe(
      result => {
        // Handle result
        console.log(result);
      },
      error => {
        this.showError(error);
      },
      () => {
        this.showSuccess();
      }
    );
  }
  // update agency details
  updateAgency(agency) {
    return this.httpClient.put(environment.agenciesUrl + agency.id, agency)
      .subscribe(
        result => {
          // Handle result
          console.log(result);
        },
        error => {
          this.showError(error);
        },
        () => {
          this.showSuccess();
        }
      );
  }
  // GET request to create new award
  createAward(award, modal) {
    console.log('createAgency() with url: ', environment.agenciesUrl);
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    let subject = new Subject<any>();
    this.httpClient.post(environment.awardsUrl, award, { headers: header })
      .subscribe(
        result => {
          // Handle result
          console.log(result);
          this.newAward = result;
        },
        error => {
          this.showError(error);
        },
        async () => {
          subject.next();
          const toast = await this.toastController.create({
            color: 'success',
            duration: 3000,
            message: 'Success!',
            showCloseButton: true,
            position: 'top',
            buttons: [
              {
                side: 'end',
                text: 'Go To Newly Added Award',
                handler: () => {
                  this.goToAwards(Number(this.newAward.id));
                }
              }
            ]
          });
          await toast.present();
          setTimeout(function () {
          }, 1500);
        }
      );
    return subject;
  }
  // route user to newly created award's details page
  async goToAwards(id) {
    return this.router.navigateByUrl(
      '/award-details/' +
      id
    );
  }
  // POST request to create new award amount
  createAmt(amt) {
    console.log('createAmt() with url: ', environment.awardsUrl + 'awardamount');
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    return this.httpClient.post(environment.awardsUrl + 'awardamount',
      amt, { headers: header }).subscribe(
        result => {
          // Handle result
          console.log(result);
        },
        error => {
          this.showError(error);
        },
        () => {
          this.showSuccess();
        }
      );
  }
  assignAgency(agency: any, modal: any) {
    console.log('assignAgency()'); // todo: comment out logging as needed for prod
    return this.assignAgencyAward(agency, modal);
  }
  // POST request to assign an agency to an award
  private assignAgencyAward(agency, modal) {
    console.log(agency);
    console.log('assignAgencyAward() with url: ', environment.awardsUrl + 'assignagency');
    return this.httpClient.post(environment.awardsUrl + 'assignagency', agency).subscribe(
      result => {
        // Handle result
        console.log(result);
        if (result === true) {
          modal.dismiss({ 'success': true });
        } else {
          this.showError('There was an error trying to add this agency.');
          modal.dismiss({ 'success': false });
        }
      },
      error => {
        console.log('error catch while assignAgency ========', error);
        this.showError(error.error.split('\n')[0].split(':')[1]);
      }
    );
  }
  async showSuccess() {
    const toast = await this.toastController.create({
      color: 'success',
      duration: 2500,
      message: 'Success!',
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
    setTimeout(function () {
      // window.location.reload();
    }, 1500);
  }

  async showError(error) {
    console.log(error);
    const toast = await this.toastController.create({
      color: 'danger',
      duration: 2500,
      cssClass: 'error-message',
      message: error,
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
  }
}
