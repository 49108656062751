import { Component, OnInit, Input } from '@angular/core';
import { Components } from '@ionic/core';

@Component({
  selector: 'cancel-confirmation',
  templateUrl: './cancel-confirmation.component.html',
  styleUrls: ['./cancel-confirmation.component.scss'],
})
export class CancelConfirmationComponent implements OnInit {
  @Input() popover: Components.IonPopover;
  @Input() modal: Components.IonModal;

  constructor() { }

  closeAll() {
    this.modal.dismiss('cancel');
    this.popover.dismiss('cancel');
  }

  ngOnInit() {}

}
