// used on award details page
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Components } from '@ionic/core';
import { AwardsService } from '../awards/awards.service';
import { PopoverController } from '@ionic/angular';
import { ReportsService } from '../reports/reports.service';

@Component({
  selector: 'update-award-amt',
  templateUrl: './update-award-amt.component.html',
  styleUrls: ['./update-award-amt.component.scss'],
})
export class UpdateAwardAmtComponent implements OnInit {
  public amtForm: FormGroup;
  loading = false;
  yearList = new Date().getFullYear();
  @Input() update;
  @Input() amt;
  @Input() award;
  @Input() modal: Components.IonModal;
  fiscalYearList: any;
  indexCurrent: any;
  constructor(public router: Router, public formBuilder: FormBuilder, private reportService: ReportsService,
    private awardsService: AwardsService, public popoverController: PopoverController) {
    this.amtForm = formBuilder.group({
      amount: ['', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
    //  fiscalYear: ['', Validators.required],
      stateRate: ['', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]]
    });
  }
  // create new ammount
  createAwardAmt() {
    console.log(this.amtForm);
    const amt = this.amtForm.value;
    amt.awardId = this.award.award.id;
    amt.isActive = true;
    amt.amount = parseFloat(amt.amount);
    amt.stateRate = parseFloat(amt.stateRate);
    amt.fiscalYear = this.fiscalYearList[0];
    amt.effectiveDate = this.fiscalYearList[0].fromDate;
    amt.expirationDate = this.fiscalYearList[0].toDate;
    amt.updateDate = new Date();
    console.log(amt);
    this.awardsService.createAmt(amt).add(() => {
      this.modal.dismiss();
    });
  }
  fetchFiscalYear() {
    this.reportService.getFiscalYears().subscribe((data: any) => {
      console.log('fetchFiscalYear(): data from fiscalYear service:', data);
      // this.fiscalYearList = data.filter(x => x.isActive);
      for (let i = 0; i < data.length; i++) {
        if (data[i].isCurrent) {
          this.indexCurrent = data[i].id;
        }
      }
      // retrieve current year
      this.fiscalYearList = data.filter(x => x.isCurrent);
    });
  }

  selectionChanged(event) {
    console.log(event);
    event = event.sort((a, b) => (a.value > b.value) ? 1 : -1);
    this.update = true;
  }

  optionsFn(item) {
    console.log(item);
  }

  ngOnInit() {
    console.log(this);
    this.update = false;
    this.amtForm.valueChanges.subscribe(change => {
      if (this.amtForm.dirty) {
        this.modal.backdropDismiss = false;
      }
    });
    this.fetchFiscalYear();
  }
}
