import { ModalAlertComponent } from './../modal-alert/modal-alert.component';
import { TabsService } from './../components/tabs.service';
import { CalendarService } from './../core/services/calendar.service';
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CalendarPeriod } from '../master-calendar-page/CalendarPeriod';
import { ModalController } from '@ionic/angular';
import { CalendarEditorComponent } from '../calendar-editor/calendar-editor.component';
import { CalendarEvent } from '../calendar-editor/CalendarEvent';
import * as m from 'moment';
import { DateRange, EditDateRangeModel, CalendarLegend } from '../calendar-editor/DateRange';
import { Router, ActivatedRoute } from '@angular/router';
import Exceptions from './holidays';
import * as moment from 'moment';
import _ from 'lodash';
import { UserData } from '../users/user-data';
import { ReportsService } from '../reports/reports.service';
import { BreadCrumbService } from '../components/bread-crumb/bread-crumb.service';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { zip } from 'rxjs';
@Component({
  selector: 'master-calendar-view',
  templateUrl: './master-calendar-view.component.html',
  styleUrls: ['./master-calendar-view.component.scss'],
})
export class MasterCalendarViewComponent implements OnInit {

  calendarType: string = 'core';
  step = 0; // accordion Step

  @ViewChild(CalendarEditorComponent, { static: false }) calendarEditor: CalendarEditorComponent;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  startDate: Date; // = new Date(2019, 6, 1);
  endDate: Date; // = new Date(2020, 6, 1);
  dateRanges: DateRange[] = [];
  removeWeekends = true;
  events: CalendarEvent[] = [];
  schools: any = [];
  innerWidthGreater: boolean;
  loading = false;
  allSchoolsSelected = false;
  calendarSchools: any = [];
  moment = m;
  columnMode = ColumnMode.force;
  calendars = [];
  name = '';
  fiscalYearId = '';
  calendarYearId = '';
  showCore = false;
  type: any = 1;
  id = 0;
  sortedItems: any = [];
  fiscalYearList: any = [];
  calendarYearList: any = [];
  assigned = true;
  calendarId: any = 0;
  dayColor = '#85e085';
  loaded = false;
  supplementalCalendar = false;
  calendarButton = 'Add non-operation period';
  calButton = 'Add Exception Days';
  days = 0;
  local: any;
  isExceptionalDay: boolean;
  endDateMaxDate: Date;

  selectedMessage = false;

  get sortedSchools() {
    return this.sortedItems;
  }
  get modifiedSchools() {
    return this.sortedItems.filter((s) => s.modified == true);
  }
  get assignedSchools() {
    return this.schools.filter((s) => s.selected == true);
  }
  get hasNonOperationalDay(): boolean {
    return this.dateRanges.length > 0;
  }

  editMode = false;
  firstLoad = true;
  constructor(
    private modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private calendarService: CalendarService,
    public tc: TabsService,
    private reportsService: ReportsService,
    public userData: UserData,
    private bc: BreadCrumbService,
    public calendarPeriod: CalendarPeriod,
    private cdr: ChangeDetectorRef
  ) {
    this.fetchFiscalYears();
    this.fetchCalendarYears();
    this.activatedRoute.queryParams.subscribe(params => {
      this.calendarType = params.type;
      console.log('Query Param ===', this.calendarType);
      if (params.type === 'supplemental') {
        this.supplementalCalendar = true;
        this.type = 2;
        this.updateCalendar2();
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidthGreater = window.innerWidth <= 728;
  }

  ngOnInit() {
    this.innerWidthGreater = window.innerWidth <= 728;
    this.calendarId = this.activatedRoute.snapshot.params['id'];
    if (this.calendarId != null) {
      this.editMode = true;
      // console.log('here- EDIT MODE ENTER ------');
      this.calendarId = parseInt(this.calendarId);
      this.calendarService.calendar(this.calendarId).subscribe((calendar) => {
        // console.log('here- calendar data for EDIT mode', calendar);
        // this.calendar = calendar;
        this.id = calendar.id;
        this.name = calendar.name;
        this.bc.breadCrumb$.next(calendar.name);
        // FIXME: Core OR Sup
        this.type = calendar.operationalCalendarType;
        this.local = JSON.parse(calendar.calenderRawData);
        this.removeWeekends = this.local.removeWeekends;
        this.dateRanges = this.local.dateRanges;
        this.startDate = m(this.local.startDate).toDate();
        this.endDate = m(this.local.endDate).toDate();
        this.fiscalYearId = calendar.fiscalYearId;
        this.showCore = calendar.operationalCalendarType === 1 ? true : false;
        this.calendarYearId = calendar.calendarYearId;
        this.days = calendar.operationalDays;

        this.local.events.map((e) => {
          e.date = m(e.date).toDate();
          e.month = e.date.getMonth() + 1;
          e.day = e.date.getDate();
          return e;
        });

        this.loadEvents();

        this.type = calendar.operationalCalendarType;
        this.updateCalendar({ target: { value: this.type.toString() } });
        setTimeout(() => {
          this.loaded = true;
        }, 500);
        this.loadSchools();
      });
    } else {
      // console.log('here- CREATE MODE ENTER ------');
      this.startDate = new Date();
      this.endDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
      this.loadEvents();
      this.allSchools();
      setTimeout(() => {
        this.loaded = true;
      }, 500);
    }
  }



  ngAfterViewInit() {
    // this.calendarEditor = new CalendarEditorComponent(null);
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  fromStep1() {
    if (this.type === 2) {
      this.step = 2;
    } else {
      this.step++;
    }
  }

  fromStep3() {
    if (this.type === 2) {
      this.step = 0;
    } else {
      this.step--;
    }
  }

  prevStep() {
    this.step--;
  }

  loadEvents() {
    this.events = Exceptions.map(e => {

      let year = 0;
      if (e.month >= this.startDate.getMonth() && e.month <= 12) {
        year = this.startDate.getFullYear();
      } else {
        year = this.endDate.getFullYear();
      }

      if (this.local === undefined) {
        const event: CalendarEvent = {
          date: new Date(year, e.month - 1, e.date),
          description: '',
          title: e.title,
          isNational: false,
          isEnabled: false
        };
        return event;
      } else {
        const event: CalendarEvent = {
          date: new Date(year, e.month - 1, e.date),
          description: '',
          title: e.title,
          isNational: false,
          isEnabled: this.local.events.some(l => l.month == e.month && l.day == e.date && l.isEnabled)
        };
        return event;
      }
    });
  }

  getSupplementalLegends(): Array<CalendarLegend> {
    return [
      { color: '#AED6F1', description: '3h Working Hours' }, // Green
      { color: '#ABEBC6', description: '6h Working Hours' }, // Blue
      { color: '#FAD7A0', description: '9h Working Hours' }, // Orange
    ];
  }

  getCoreLegends(): Array<CalendarLegend> {
    return [
      { color: '#ADADAB', description: 'Non-operational days' },  // Soft Grey
      { color: '#FAD5DE', description: 'Exception days' }, // Soft Red
      { color: '#D5FAD8', description: 'Operational days' }, // Soft Green
    ];
  }
  async fetchFiscalYears() {
    const data = await this.reportsService.getFiscalYears().toPromise()
    this.fiscalYearList = data;
  }

  async fetchCalendarYears() {
    const data = await this.reportsService.getCalendarYears().toPromise()
    this.calendarYearList = data;
  }
  onDateChange() {
    this.loadEvents();
    this.refresh();
  }
  onFiscalChange(event) {
    const selectedValue = event[0].data;

    console.log(event);
    if (this.editMode && this.firstLoad) {
      this.loadEvents();
      this.firstLoad = false;
    } else {
      this.startDate = m(selectedValue.fromDate).toDate();
      this.endDate = m(selectedValue.toDate).toDate();

      this.loadEvents();
    }
  }

  updateEvents() {
    const dateFormat = 'MM/DD/YYYY';
    const startLimit = m(this.startDate).format(dateFormat);
    const endLimit = m(this.endDate).format(dateFormat);

    if (this.local) {
      this.events = this.events
        .map((e) => {
          e.date = m(e.date).toDate();
          return e;
        })
        .filter((e) => {
          const currentDate = m(e.date).format(dateFormat);
          const validationEvent = m(currentDate).isBetween(
            startLimit,
            endLimit,
            undefined,
            '[]'
          );
          return validationEvent;
        });
    }
    return true;
  }

  updateCalendar($event) {
    console.log($event.target.value);
    if (Number($event.target.value) === 2) {
      this.dayColor = '#fff';
      this.supplementalCalendar = true;
      this.calendarButton = 'Add operation period';
      this.events.forEach(item => item.isEnabled = false);
    } else {
      this.dayColor = '#85e085';
      this.supplementalCalendar = false;
      this.calendarButton = 'Add non-operation period';
    }

    this.refresh();
  }
  updateCalendar2() {
    this.dayColor = '#fff';
    this.supplementalCalendar = true;
    this.calendarButton = 'Add operation period';
    this.events.forEach(item => item.isEnabled = false);

    this.refresh();
  }
  loadSchools() {
    console.log('here- loading Schools - calendar ID - ', this.calendarId);
    this.calendarService
      .getSchoolsByCalendar(this.calendarId, true)
      .subscribe((schools) => {
        this.schools = schools.map((s) => {
          s.selected = s.operationalCalendarId != null;
          s.modified = false;
          return s;
        });
        this.sortedItems = this.schools;
        console.log('here-- loadSchools', this.schools);
      });
  }

  allSchools() {
    this.calendarService
      .getAllSchools()
      .subscribe((schools) => {
        this.schools = schools.map((s) => {
          // s.selected = s.operationalCalendarId != null;
          if (s.operationalCalendarId) {
            s.selected = true;
          }
          s.modified = false;
          return s;
        });
        this.sortedItems = this.schools;
        console.log('here-- ALL Schools', this.schools);
      });
  }

  assignCalendar(row: any) {
    row.modified = !row.modified;
  }

  eventsUpdate($event) {
    this.refresh();
  }
  notAssigned() {
    this.assigned = !this.assigned;
    if (this.assigned) {
      this.sortedItems = this.schools;
    } else {
      this.sortedItems = this.schools.filter(
        (s) => s.selected == this.assigned
      );
    }
  }
  cancel() {
    this.router.navigate(['master-calendar']);
  }
  selectAll() {
    this.schools.forEach((school) => {
      if (school.selected == false && school.modified == false) {
        school.modified = true;
      }
      school.selected = true;
    });
    this.allSchoolsSelected = true;
  }

  unselectAll() {
    this.schools.forEach((school) => {
      if (school.selected && school.modified) {
        school.modified = false;
      }
      school.selected = false;
    });
    this.allSchoolsSelected = false;
  }

  async assignCalendars(id?) {
    let operationalCalendarId;
    let modalBody;
    if (id) {
      operationalCalendarId = id;
      modalBody = `Calendar Created`;
    } else {
      operationalCalendarId = this.calendarId;
      modalBody = `Calendar Updated`;
    }

    const selectedSchools = this.schools
      .filter((s) => s.selected && s.modified)
      .map((school) => {
        return {
          schoolId: school.school.id,
          OperationalCalendarId: operationalCalendarId,
        };
      });
    const removedSchools = this.schools
      .filter((s) => !s.selected && s.modified)
      .map((school) => {
        return {
          id: school.id,
          schoolId: school.school.id,
          OperationalCalendarId: operationalCalendarId,
        };
      });
    const modal = await this.modalController.create({
      component: ModalAlertComponent,
      componentProps: {
        props: {
          header: 'School Calendars',
          body: 'School\'s calendar updated.',
          okText: 'Ok',
          messageDialog: true,
        },
      },
    });

    zip(
      this.calendarService.deleteCalendar(removedSchools),
      this.calendarService.assignCalendar(selectedSchools)
    ).subscribe((res) => {
      modal.present().then(() => { });
    });
  }

  filterRows($event) {
    const val = $event.toLowerCase();
    if (val.length == 0) {
      this.sortedItems = this.schools;
      return;
    }
    if (val.length < 1) {
      return;
    }
    this.sortedItems = this.schools.filter(
      (s) => s.school.schoolName.toLowerCase().indexOf(val) >= 0
    );
  }

  isFormValid() {
    return !this.name || !this.type || !this.startDate || !this.endDate;
  }

  get stepOneValidation() {
    if (this.type === 1) {
      return !(this.name && this.fiscalYearId && this.startDate && this.endDate)
    } else {
      return !(this.name && this.startDate && this.endDate)
    }
  }

  saveCalendar() {
    const calendarJson = this.calendarEditor.toJson();
    const calendar = {
      id: this.id,
      name: this.name,
      startDate: this.startDate,
      endDate: this.endDate,
      fiscalYearId:
        this.fiscalYearId !== '' && this.fiscalYearId !== null
          ? this.fiscalYearId
          : null,
      calendarYearId:
        this.calendarYearId !== '' && this.calendarYearId !== null
          ? this.calendarYearId
          : null,
      operationalCalendarType: parseInt(this.type),
      calenderRawData: JSON.stringify(calendarJson),
      removeWeekends: this.removeWeekends,
      isActive: true,
      operationalDays: this.days,
    };

    this.calendarService.addOrUpdate(calendar).subscribe((res) => {
      console.log('here- SaveCalendar', res);
      (res && res.id) ? this.assignCalendars(res.id) : this.assignCalendars();
      setTimeout(() => {
        return true;
      }, 500);
      this.router.navigate(['/master-calendar'], {
        queryParams: {
          type: this.type,
        },
      });
    });
  }

  refresh() {
    console.log('here- refresh() calendar');
    this.endDateMaxDate = m(this.startDate).add(12, 'M').toDate();
    if (this.endDate.getTime() > this.endDateMaxDate.getTime()) {
      this.endDate = this.endDateMaxDate;
    }
    if (this.startDate && this.endDate && this.calendarEditor) {
      setTimeout(() => {
        this.calendarEditor.refresh();
        if (this.supplementalCalendar) {
          this.days = this.calculateSupDays();
          this.type = 2;
        } else {
          this.days = this.calculateDays();
          this.type = 1;
        }
        this.updateEvents();
      }, 400);
    }
  }

  daysDiff(end: Date, start: Date) {
    return m(end).diff(start, 'days') + 1;
  }
  deletePeriod(index) {
    this.dateRanges.splice(index, 1);
    this.dateRanges = [...this.dateRanges];
    this.refresh();
  }

  calculateSupDays() {
    let days = 0;
    if (this.calendarEditor == undefined) { return 0; }
    if (this.calendarEditor.layers.length == 0) { return 0; }


    this.calendarEditor.dateRanges.forEach((dateRange) => {
      let diff = m(dateRange.end).diff(dateRange.start, 'day') + 1;
      const startDate = m(dateRange.start);
      const endDate = m(dateRange.end);

      if (this.removeWeekends) {
        while (startDate.isSameOrBefore(endDate)) {
          if (startDate.isoWeekday() > 5) {
            diff--;
          }
          startDate.add(1, 'day');
        }
      }
      days = days + diff;
    });

    return days;
  }
  calculateDays() {
    let days = 0;
    if (this.calendarEditor == undefined) { return 0; }
    if (this.calendarEditor.layers.length == 0) { return 0; }
    const layer = this.calendarEditor.layers[0];

    const selectedHolidays = this.calendarEditor.events.filter((e) => e.isEnabled);
    let holidaysList = selectedHolidays;
    if (this.removeWeekends) {
      holidaysList = selectedHolidays.filter(holiday => {
        return m(holiday.date).isoWeekday() < 6;
      });
    }
    const daysOff = holidaysList.length;
    console.log('cal: dayoff---', selectedHolidays, daysOff);

    this.calendarEditor.layers.forEach((layer) => {
      console.log('cal: layers foreach', layer);
      const diff = m(layer.layerEnd).diff(layer.layerStart, 'day') + 1;
      days = days + diff;
    });
    this.calendarEditor.dateRanges.forEach((dateRange) => {

      const diff = m(dateRange.end).diff(dateRange.start, 'day') + 1;
      if (this.removeWeekends) {
        const weekends = this.getWeekendDays(new Date(dateRange.start), new Date(dateRange.end));
        days = days - diff + weekends;
      } else {
        days = days - diff;
      }

      let holidaysInDateRanges = 0;
      holidaysList.forEach(h => {
        if (new Date(h.date).getTime() >= new Date(dateRange.start).getTime() && new Date(h.date).getTime() <= new Date(dateRange.end).getTime()) {
          holidaysInDateRanges = holidaysInDateRanges + 1;
        }
      });
      // console.log('cal: hol---', holidaysInDateRanges);
      days = days + holidaysInDateRanges;
    });
    if (this.removeWeekends) {
      let ctd = 0;
      const startDate = m(layer.layerStart);

      while (!startDate.isSame(layer.layerEnd, 'day')) {
        if (startDate.isoWeekday() > 5) {
          ctd = ctd + 1;
        }
        startDate.add(1, 'day');
      }
      days = days - ctd;
    }
    days = days - daysOff;
    return days;
  }

  getWeekendDays(startDate, endDate) {
    let count = 0;
    const curDate = new Date(startDate.getTime());
    while (curDate <= endDate) {
      const dayOfWeek = curDate.getDay();
      if ((dayOfWeek == 0 || dayOfWeek == 6)) count++;
      curDate.setDate(curDate.getDate() + 1);
    }
    return count;
  }

  async editPeriod(index: number, isExcepionalDay: boolean) {
    const editModal: EditDateRangeModel = {
      model: this.dateRanges[index],
      index: index,
    };

    const modal = await this.modalController.create({
      component: CalendarPeriod,
      cssClass: 'calendar-period',
      componentProps: {
        supplemental: this.supplementalCalendar,
        showSchools: false,
        dataRanges: this.dateRanges,
        editModel: editModal,
        isExceptionalDay: isExcepionalDay,
      },
    });
    modal.onDidDismiss().then((result) => {
      if (result.data == undefined || result.data == 'cancel') { return; }
      const range: DateRange = this.loadDateRange(result);
      this.dateRanges[index] = range;
      this.dateRanges = [...this.dateRanges];
      this.refresh();
    });
    await modal.present();
  }

  async addPeriod(isExcepionalDay: boolean) {
    const modal = await this.modalController.create({
      component: CalendarPeriod,
      cssClass: 'calendar-period',
      componentProps: {
        supplemental: this.supplementalCalendar,
        showSchools: false,
        dataRanges: this.dateRanges,
        isExceptionalDay: isExcepionalDay,
        page: true
      },
    });
    modal.onDidDismiss().then((result) => {
      if (result.data == undefined || result.data == 'cancel') { return; }
      const range: DateRange = this.loadDateRange(result);
      this.dateRanges.push(range);
      this.dateRanges = [...this.dateRanges];
      this.refresh();
    });
    await modal.present();
  }

  private loadDateRange(result) {
    let color = '#ECC9C7'; // Soft Red
    if (Number(this.type) === 2) {
      switch (result.data.workingHours) {
        case 3:
          color = '#AED6F1'; // Blue
          break;
        case 6:
          color = '#ABEBC6'; // Green
          break;
        case 9:
          color = '#FAD7A0'; // Orange
          break;
      }
    } else {
      color = '#ADADAB'; // Soft Green
    }

    const range: DateRange = {
      title: result.data.title,
      start: result.data.startDate,
      end: result.data.endDate,
      workingHours: result.data.workingHours,
      color: color,
      isExceptionalDay: result.data.isExceptionalDay,
    };
    return range;
  }
}
