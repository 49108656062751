import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Component, Input, Injectable } from '@angular/core';

import * as m from 'moment';
import { SchoolsService } from '../schools/schools.service';
import _ from 'lodash';
import { Components } from '@ionic/core';
import { EditDateRangeModel } from '../calendar-editor/DateRange';
import { CalendarEditorComponent } from '../calendar-editor/calendar-editor.component';
import { AgenciesService } from '../agencies/agencies.service';
import { StudentsService } from '../students/students.service';
import { SchoolToStudentsService } from '../school-to-students/schoolToStudents.service';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { GlobalShareService } from '../core/services/global-share.service';

@Component({
  templateUrl: './calendar-period.html',
})
@Injectable({
  providedIn: 'root'
})
export class CalendarPeriod {
  @Input() modal: Components.IonModal;
  form: FormGroup;
  supplemental: Boolean = false;
  schoolItems: any = [];
  studentItems: any = [];
  agencyItems: any = [];
  showAgencies: Boolean = false;
  showSchools: Boolean = false;
  showStudents: Boolean = false;
  page: Boolean;
  showTitle: Boolean = true;
  dataRanges: any = [];
  startDateInvalid = false;
  endDateInvalid = false;
  isExceptionalDay = false;
  editModel: EditDateRangeModel;
  selected = [];
  queryText = '';
  columnMode = ColumnMode.force;
  selectionType = SelectionType.single;
  filterColumns = [
    'firstName',
    'lausdId',
    'school.schoolName',
    'school.id',
    'source',
  ];
  defaultSort = [{ prop: 'lastName', dir: 'asc' }];

  get sortedSchools() {
    return _.sortBy(this.schoolItems, ['schoolName']);
  }

  get sortedAgency() {
    return _.sortBy(this.agencyItems, ['name']);
  }

  get startDateFormmated() {
    const startDate = this.form.value.startDate;
    return startDate != null ? m(startDate).format('MM-DD-YYYY') : '';
  }

  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private schoolsService: SchoolsService,
    private studentsService: SchoolToStudentsService,
    private agencyService: AgenciesService,
    private globalShareService: GlobalShareService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      title: ['', [Validators.required]],
      startDate: [
        '',
        [Validators.required],
      ],
      endDate: ['', [Validators.required]],
      workingHours: [''],
    });
    if (this.showStudents) {
      this.form.addControl(
        'studentId',
        new FormControl('', Validators.required)
      );
    }
    if (this.page = true) {
      console.log('page here-');
    }
    if (this.showSchools) {
      if (this.showStudents) {
        this.form.addControl(
          'schoolId',
          new FormControl('', Validators.required)
        );
        this.form.controls.schoolId.disable();
      } else {
        this.loadSchools();
        this.form.addControl(
          'schoolIds',
          new FormControl('', Validators.required)
        );
      }
      this.form.removeControl('workingHours');
    }

    if (this.showAgencies) {
      this.form.addControl(
        'agencyId',
        new FormControl('', Validators.required)
      );
      this.loadAgencies();
      this.form.removeControl('workingHours');
    }

    if (!this.showTitle) {
      this.form.removeControl('title');
    }

    if (this.supplemental) {
      this.form.controls.workingHours.setValidators([Validators.required]);
      this.form.controls.title.disable();
    }
    this.form.valueChanges.subscribe(change => {
      if (this.form.dirty) {
        this.modal.backdropDismiss = false;
      }
    });
    if (this.editModel) {
      this.loadEditModal();
    }
  }

  loadEditModal() {
    this.form.controls.title.setValue(this.editModel.model.title);
    this.form.controls.startDate.setValue(new Date(this.editModel.model.start));
    this.form.controls.endDate.setValue(new Date(this.editModel.model.end));
    this.form.controls.workingHours.setValue(this.editModel.model.workingHours);
  }

  onAgencyChange(agency) {
    this.form.get('studentId').setValue(null);
    this.studentItems = [];
    this.loadSchoolsByAgency(agency[0].data.id);
  }

  onSchoolChange(school) {
    this.form.get('studentId').setValue(null);
    this.studentItems = [];
    this.loadStudents(school[0].data.id, this.form.get('agencyId').value);
  }

  onStudentSelect(student) {
    this.form.get('studentId').setValue(student.selected[0].id);
  }

  onStartDateChange(date) {
    this.startDateInvalid = this.dateRangeValidation(date);
  }
  onEndDateChange(date) {
    this.endDateInvalid = this.dateRangeValidation(date);
  }


  dateRangeValidation(date): boolean {
    const dateFormat = 'MM/DD/YYYY';
    let isSelectedInvalidDate = false;
    const array = this.dataRanges;
    const currentDate = m(date).format(dateFormat);

    for (let index = 0; index < array.length; index++) {
      const item = array[index];
      const isEditionItem = (this.editModel != null && this.editModel != undefined);
      const currentEditIndex = isEditionItem ? this.editModel.index : -1;

      if (index !== currentEditIndex && !this.isExceptionalDay) {
        const startLimit = m(item.start).format(dateFormat);
        const endLimit = m(item.end).format(dateFormat);
        isSelectedInvalidDate = m(currentDate).isBetween(
          startLimit,
          endLimit,
          undefined,
          '[]'
        );
        if (isSelectedInvalidDate) {
          break;
        }
      }
    }
    return isSelectedInvalidDate;
  }

  onWokingHourChange(value) {
    if (value) {
      this.form.controls.title.setValue(`${value} Hour Sessions`);
    }
  }

  loadSchools() {
    const data = [...this.globalShareService.allSchools];
    this.schoolItems = data.map((x) => {
      x.displayName = x.schoolName + ' - ' + x.cdsCode;
      return x;
    });

  }

  loadSchoolsByAgency(agencyId) {
    this.schoolsService.getSchoolsByAgency(agencyId).subscribe((data: any) => {
      this.schoolItems = data.map((x) => {
        x.displayName = x.schoolName + ' - ' + x.cdsCode;
        return x;
      });
      if (this.schoolItems.length && this.schoolItems != null) {
        this.form.controls.schoolId.enable();
      } else {
        this.form.controls.schoolId.disable();
      }
    });
  }

  loadStudents(schoolId, agencyId) {
    this.studentsService.getStudentsByAgencyBySchool(schoolId, agencyId).subscribe((data: any) => {
      // this.queryText = null;
      data.forEach((element) => {
        element.concatName = element.lastName;
        element.concatName += element.middleName == null || element.middleName === '' ?
          ', ' + element.firstName :
          ', ' + element.firstName + ' ' + element.middleName;
        element.dobFormatted = moment(element.dob).format('MM/DD/YYYY');
      });
      this.studentItems = data;
    });
  }

  loadAgencies() {
    this.agencyService.getAgencies().subscribe((data: any) => {
      this.agencyItems = data;
    });
  }

  close() {
    if (this.supplemental) {

      if (this.startDateInvalid && this.endDateInvalid) {
        return false;
      }
      this.form.controls.title.enable();
      this.form.controls.title.setValue(this.form.controls.workingHours.value);
    }
    this.form.value.isExceptionalDay = this.isExceptionalDay;
    this.modalController.dismiss(this.form.value);
  }




  // Common functions
  toUtcDate(date: string) {
    const currentDate = new Date(date);
    return new Date(
      currentDate.getTime() + currentDate.getTimezoneOffset() * 60000
    );
  }

  calculateSupDays(calendar: CalendarEditorComponent, removeWeekends: boolean): number {
    let days = 0;
    if (calendar == undefined) { return 0; }
    if (calendar.layers.length == 0) { return 0; }
    const layer = calendar.layers[0];
    // days = this.calcBusinessDays(layer.layerStart, layer.layerEnd);

    calendar.dateRanges.forEach((dateRange) => {
      let diff = m(dateRange.end).diff(dateRange.start, 'day') + 1;
      const startDate = m(dateRange.start);
      const endDate = m(dateRange.end);

      if (removeWeekends) {
        while (startDate.isSameOrBefore(endDate)) {
          if (startDate.isoWeekday() > 5) {
            diff--;
          }
          startDate.add(1, 'day');
        }
      }
      days = days + diff;
    });

    return days;
  }


}
