import { RouterModule } from '@angular/router';
import { BreadCrumbService } from './bread-crumb/bread-crumb.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadCrumbComponent } from './bread-crumb/bread-crumb.component';



@NgModule({
  declarations: [BreadCrumbComponent],
  exports:[BreadCrumbComponent],
  imports: [
    CommonModule,
    RouterModule
  ],

})
export class ComponentsModule { }
