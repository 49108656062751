// tslint:disable-next-line: import-spacing
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceProvider } from '../users/auth-service/auth-service';
import { Authentication, AdalConfig, User } from 'adal-ts';
import { UserDecoder } from './user.decoder';
import { Storage } from '@ionic/storage';
import { UserData } from '../users/user-data';
import { AppComponent } from '../app.component';
import { RolePermissionsService } from '../users/rolepermissions-service/rolepermissions-service';
import { ModalController } from '@ionic/angular';
import { UserAgencyComponent } from '../user-agency/user-agency.component';
import { AgencyDetailsService } from '../agency-details/agency-details.service';
import { LoadingController } from '@ionic/angular';
import { StaffService } from '../staff/staff.service';

@Component({
  selector: 'app-auth-callback',
  template: '<div><h3></h3></div>'
})

export class AuthCallbackComponent implements OnInit {

  public message: string;
  user: User;
  appComponent: AppComponent;
  indicator: HTMLIonLoadingElement = null;
  requestsQueue: Array<any> = [];
  constructor(
    private authService: AuthServiceProvider,
    private router: Router,
    public storage: Storage,
    public userData: UserData,
    public agencyDetailsService: AgencyDetailsService,
    public staffService: StaffService,
    public modalController: ModalController,
    private rolePermissionsService: RolePermissionsService,
    private loading: LoadingController
  ) { }

  async ngOnInit() {
    this.showLoader();
    const context = Authentication.getContext(this.authService.createConfig());
    this.user = context.getUser();
    console.log('auth--- USER', this.user);
    // tslint:disable-next-line: prefer-const
    let params = this.parseQueryString(location.hash);
    console.log('auth--- Params in Auth Callback', params);
    if (params['id_token'] != null) {
      this.user = new UserDecoder().decode(params['id_token']);
      console.log(this.user);
    }
    this.storage.set('userfromIdToken', this.user);
    this.storage.set('idToken', params['id_token']);
    this.userData.login(this.user.unique_name).then(async () => {
      const userAgencyData = this.user['http://net.lausd.btb/agencyId'];
      const agencies = Array.isArray(userAgencyData) ? userAgencyData : [userAgencyData];
      const unique = [...new Set(agencies)];
      if (unique.length > 1) {
        const modal = await this.modalController.create({
          component: UserAgencyComponent,
          componentProps: { userName: this.user.unique_name },
          backdropDismiss: false
        });
        await modal.present();
        modal.onDidDismiss().then((result) => {
          const userData = {
            name: this.user.unique_name,
            // tslint:disable-next-line: radix
            roles: [parseInt(result.data.role)],
            agency: parseInt(result.data.agencyId),
            agencyType: result.data.agencyType
          };
          // if (userData.roles == null) {
          //     this.userData.logout().then( () => {
          //             this.authService.signOut();
          //         });
          // }
          this.storage.set('selectedAgencyId', userData.agency);
          this.storage.set('userSubject', userData).then(() => {

            window.dispatchEvent(new CustomEvent('user:login'));
            const profile = JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
            if (profile && profile.roles.includes(9)) {
              this.userData.logout().then(() => {
                this.authService.signOut();
              });
            } else {
              window.location.href = '/dashboard';
            }
          });
        });
      } else {
        const agencyId = Number(unique);
        if (agencyId > 0) {
          this.storage.set('selectedAgencyId', agencyId);
          this.agencyDetailsService.getAgencyDetails(agencyId).subscribe((data) => {
            const agencyIndex = agencies.findIndex(a => a == agencyId);
            const roleId = Number(this.user['role'][agencyIndex]);
            const userData = {
              name: this.user.unique_name,
              // tslint:disable-next-line: radix
              roles: [roleId],
              agency: agencyId,
              agencyType: data.type,
              agencyName: data.name
            };

            if(this.user !== undefined && this.user.unique_name !== undefined && this.user.unique_name !== ''){
              this.staffService.getStaffByLoginName(this.user.unique_name).subscribe(
                (res:any)=>{
                  console.log(res);
                  if(res == undefined){
                      this.router.navigate(['/errors/' + 3 + '/' + userData.agencyName]);
                    }
                    else{
                      // if (userData.roles == null) {
                      //     this.userData.logout().then( () => {
                      //             this.authService.signOut();
                      //         });
                      // }
                      this.storage.set('userSubject', userData).then(() => {
                        window.dispatchEvent(new CustomEvent('user:login'));
                        const profile = JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
                        if (profile && profile.roles.includes(9)) {
                          this.router.navigate(['/errors/' + 2 + '/403']);
                          /*this.userData.logout().then( () => {
                              this.authService.signOut();
                          });*/
                        } else {
                          window.location.href = '/dashboard';
                        }
                      });
                    }
                  }
              );
            }
          });


          // this.agencyDetailsService.setSelectedAgency(Number(this.user['http://net.lausd.btb/agencyId'])).subscribe((data) => {
          //     this.agencyDetailsService.getSelectedAgencyDetails().subscribe((data1) => {
          //         console.log(data1);
          //     });
          // });

        } else {
          this.router.navigate(['/errors/' + 1 + '/401']);
        }
      }
    });
    this.hideLoader();
  }

  private parseQueryString = function (url) {
    // tslint:disable-next-line: prefer-const
    let params = {}, queryString = url.substring(1),
      // tslint:disable-next-line: prefer-const
      regex = /([^&=]+)=([^&]*)/g, m;
    while (m = regex.exec(queryString)) {
      params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
    }
    return params;
  };

  showLoader() {
    if (this.indicator == null) {
      this.indicator = {} as HTMLIonLoadingElement;
      if (this.requestsQueue.length == 0) {
        this.loading
          .create({
            message: 'Please wait...',
            duration: 2000,
          })
          .then((res) => {
            this.indicator = res;
            res.present();

            // res.onDidDismiss().then((dis) => {
            // console.log("Loading dismissed!");
            // });
          });
      }
    }
    // this.requestsQueue.push({});
  }

  hideLoader() {
    this.requestsQueue.pop();
    if (this.requestsQueue.length == 0) {
      if (this.indicator != null || this.indicator != undefined) {
        if (this.indicator.dismiss) { this.indicator.dismiss(); }
      }
      this.indicator = null;
    }
  }

}
