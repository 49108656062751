import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';

@Component({
  selector: 'award-details',
  templateUrl: './award-details.component.html',
  styleUrls: ['./award-details.component.scss'],
})
export class AwardDetailsComponent implements OnInit {
  @Input() details;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    const paramId = this.route.snapshot.params['id'];
    console.log(paramId);
    console.log(this.details);
  }
}
