import { Injectable } from '@angular/core';
import {  Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TabsService {

  tabChannel:string = 'details';
  constructor() {

  }
}
