import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'submit-attendance-confirmation',
  templateUrl: './submit-attendance-confirmation.component.html',
  styleUrls: ['./submit-attendance-confirmation.component.scss'],
})
export class SubmitAttendanceConfirmationComponent implements OnInit {

  @Input() title: any
  @Input() text: any
  @Input() reject: boolean = false;
  @Input() warning: boolean = false;

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    console.log(this.title,this.text, this.reject, 'reject=======')
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss(null, 'confirm');
  }
}
