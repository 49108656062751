// used for UpdateCodeComponent modal
import { Component, OnInit, Input } from '@angular/core';
import { Components } from '@ionic/core';
import { ModalController } from '@ionic/angular';
import { UpdateCodeComponent } from '../update-enrichmentCode/update-code.component';
import { RowHeightCache } from '@swimlane/ngx-datatable';
@Component({
  selector: 'pop-enrichment-codes',
  templateUrl: './pop-enrichment-codes.component.html',
  styleUrls: ['./pop-enrichment-codes.component.scss'],
})
export class PopEnrichmentCodesComponent implements OnInit {
  @Input() popover: Components.IonPopover;
  @Input() type;
  constructor(public modalController: ModalController) { }

  ngOnInit() {
  }

  // triggers update UpdateCodeComponent modal
  async updateEnrichmentCode() {
    event.stopPropagation();
    const modal = await this.modalController.create({
      component: UpdateCodeComponent,
      componentProps: {
        update: true,
        updateCode: this.type
      }
    });
    modal.onDidDismiss().then((result) => {
      this.popover.dismiss(result.data);
    });
    return await modal.present();
  }
}
