// used on awards page and award details page
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Components } from '@ionic/core';
import { AwardsService } from '../awards/awards.service';
import { PopoverController } from '@ionic/angular';
import { SchoolsService } from '../schools/schools.service';
import { FundingDetailsService } from '../funding-details/funding-details.service';
import { FundingService } from '../funding/funding.service';
import { DeactivateAwardComponent } from '../deactivate-award/deactivate-award.component';
import { awardType } from '../awards/awardType.enum';
import { environment } from '../../environments/environment';
import _ from 'lodash';
import { CancelConfirmationComponent } from '../cancel-confirmation/cancel-confirmation.component';
import { GlobalShareService } from '../core/services/global-share.service';
import { ReportsService } from '../reports/reports.service';
import { AgenciesService } from '../agencies/agencies.service';
import * as moment from 'moment';
// import { DeactivateAgencyComponent } from '../deactivate-agency/deactivate-agency.component';
@Component({
  selector: 'update-award',
  templateUrl: './update-award.component.html',
  styleUrls: ['./update-award.component.scss'],
})
export class UpdateAwardComponent implements OnInit {
  public awardForm: FormGroup;
  loading = false;
  @Input() update;
  @Input() award;
  @Input() fundingSourceId;
  agencies: any;
  @Input() modal: Components.IonModal;
  schools: any;
  funders: any;
  schoolName: any;
  showCohort: any;
  objectKeys = Object.keys;
  types = awardType;
  agenciesList: any = [];
  calendarYearList: any = [];
  gradeRestrictions:any = [];
  endMinDate: Date = new Date();
  currentFiscalYear:any;
  agenciesLoaded: Promise<boolean>;
  gradeRules: any = [];
  items = [
    { id: -1, value: 'TK' }, { id: 0, value: 'K' }, { id: 1, value: '1' }, { id: 2, value: '2' },
    { id: 3, value: '3' }, { id: 4, value: '4' }, { id: 5, value: '5' },
    { id: 6, value: '6' }, { id: 7, value: '7' }, { id: 8, value: '8' },
    { id: 9, value: '9' }, { id: 10, value: '10' }, { id: 11, value: '11' }, { id: 12, value: '12' }];

  get sortedSchools() {
    return _.sortBy(this.schools, ['schoolName']);
  }
  get sortedAgencies(){
    return this.agenciesList;
  }
  constructor(public router: Router, public formBuilder: FormBuilder,
    private awardService: AwardsService, private fundingService: FundingService,
    public popoverController: PopoverController, private schoolsService: SchoolsService,
    private globalShareService: GlobalShareService,
    private reportService: ReportsService, public agenciesService: AgenciesService,
  ) {
    this.awardForm = formBuilder.group({
      schoolId: [''],
      fundingSourceId: [this.fundingSourceId],
      dooType: ['', Validators.required],
      awardAmount: ['', [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      stateRate: ['', [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{0,4})?$')]],
      dailyTarget: ['',Validators.required],
      type: ['', Validators.required],
      priorityGoal: [85, Validators.required],
      gradeRestriction: ['', Validators.required],
      effectiveDate: ['', [Validators.required]],
      agencyId:['']
    });
  }
  // cancel award creation
  async cancelConfirmation(ev: any) {
    const form = this.awardForm.value;
    if (form.schoolId !== '' || form.awardId !== ''
      || form.awardAmount !== '' || form.stateRate !== '' || form.type !== '') {
      const popover = await this.popoverController.create({
        component: CancelConfirmationComponent,
        event: ev,
        translucent: true,
        componentProps: { modal: this.modal },
      });
      return await popover.present();
    } else {
      this.modal.dismiss('cancel');
    }
  }
  fetchCalendarYears() {
    this.reportService.getCalendarYears().subscribe((data: any) => {
      console.log('fetchCalendarYears(): data from fiscalYear service:', data);
      this.calendarYearList = data.filter(x => x.isActive);
      this.currentFiscalYear = this.calendarYearList.find(x => x.isCurrent);
      this.endMinDate = new Date(this.currentFiscalYear.fromDate);
    });
   

  }
  // update existing award
  updateAward() {
    console.log(this.awardForm.value);
    this.awardForm.value.agencyId? '': this.awardForm.get('agencyId').setValue(this.award.award.agencyId);
    this.award.award.schoolId = this.awardForm.value.schoolId;
    this.award.award.school.id = this.awardForm.value.schoolId;
    //this.award.award.agency.id = 
    this.award.award.priorityGoal = this.awardForm.value.priorityGoal;
    this.award.award.dooType = this.awardForm.value.dooType;
    //this.award.award.agencyId = parseInt(this.profile.agency);
    this.award.award.school = null;
    this.award.award.agencies = null;
    this.award.award.fundingSource = null;
    this.award.award.awardId = 0;
    this.award.unearned = null;
    this.award.award.awardAmount = parseFloat(this.awardForm.value.awardAmount);
    this.award.award.stateRate = parseFloat(this.awardForm.value.stateRate);
    this.award.award.type = parseInt(this.awardForm.value.type);
    this.award.award.dailyTarget = parseFloat(this.awardForm.value.dailyTarget);
    this.award.award.awardAmounts = [];
    this.award.award.agencyId = parseInt(this.awardForm.value.agencyId);
    //this.award.award.effectiveDate = this.awardForm.value.effectiveDate;
    this.award.award.gradeRestriction = this.gradeRules.join();
    this.awardService.updateAward(this.award.award).add(() => {
      this.modal.dismiss();
    });
  }
  // create new award
  async createAward() {
    console.log('awardForm ' + this.awardForm);
    const award =this.awardForm.value;
    award.awardAmount = parseFloat(award.awardAmount);
    // tslint:disable-next-line: radix
    award.awardId = 0; // parseInt(award.awardId);
  //  award.agencyId = parseInt(this.profile.agency);
    award.stateRate = parseFloat(this.awardForm.value.stateRate);
    award.dailyTarget = parseFloat(this.awardForm.value.dailyTarget);
    award.isActive = true;
    award.unearned = 0;
    // award.grantId = this.awardForm.value.grantId.toString();
    award.schoolId = parseInt(award.schoolId);
    award.source = 'BTB';
    award.fundingSourceId = parseInt(this.fundingSourceId);
    award.type = parseInt(award.type);
    award.dooType = parseInt(award.dooType);
    award.gradeRestriction = this.gradeRules.join();
    award.agencyId = parseInt(this.awardForm.value.agencyId);
    award.effectiveDate = this.awardForm.value.effectiveDate;
    this.awardService.createAward(award, this.modal).subscribe(() => {
      this.modal.dismiss();
    });
    console.log(award.unearned);
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: DeactivateAwardComponent,
      event: ev,
      translucent: true,
      componentProps: { awardId: this.award.award.id },
    });
    popover.onDidDismiss().then(() => {
      this.modal.dismiss();
    });
    return await popover.present();
  }

  populateAward = async () => {
    this.gradeRestrictions.push('0');
   
    const data = [...this.globalShareService.allSchools];
    data.forEach(x => {
      x.customText = x.schoolName + ' - ' + x.cdsCode;
    });
    this.schools = data;

    if (this.update) {
      this.awardForm.patchValue(this.award.award);
     
        this.gradeRestrictions =  this.award.award.gradeRestriction.split(",");
        this.gradeRestrictions.forEach(v=>{
         this.gradeRules.push(v);
          if (this.gradeRules.length >= 1) {
            this.awardForm.get('gradeRestriction').setValue(this.gradeRules.map(Number));
          }
        })
        
      this.awardForm.get('effectiveDate').setValue(Date());
      this.awardForm.get('stateRate').setValue(Number(this.awardForm.value.stateRate.replace(/[^0-9.-]+/g, '')));
      this.awardForm.get('type').setValue(this.award.award.type);
      if (this.award.award.school) {
        this.awardForm.get('schoolId').setValue(this.award.award.school.id);
      }
    }
  }

  selectionChanged() {
    console.log(this.awardForm.value.schoolId);
  }

  get profile() {
    return JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
  }

  ngOnInit() {
    console.log(this);
    this.awardForm.statusChanges.subscribe(change => {
      if (this.awardForm.dirty) {
        this.modal.backdropDismiss = false;
      }
    });
    this.agenciesLoaded=Promise.resolve(false);
    this.fetchAgencyList();
    this.populateAward();
    this.fetchCalendarYears();
  }
  
  fetchAgencyList() {
    this.agenciesService.getAgencies().subscribe((data: any) => {
      console.log('fetchAgencyList(): data from agencies service:', data);
      this.agenciesList = data.filter(x => x.isActive);
      this.agenciesLoaded=Promise.resolve(true);
    });
  }
  onAgencyChange(event) {
    const agencies = [];
    event.forEach(element => {
      
      this.awardForm.get('agencyId').setValue(element.data.agencyId);
    });

  }
  changeEndDate(event){
    this.awardForm.get('effectiveDate').setValue(moment(event).format('MM/DD/YYYY'));
  }
  ValidatedailyTarget($event){
    debugger;
    if($event.target.value <= 9999){
     return true;
    }
    return false;
   }
   ValidateStateRate(event){
    var regex = new RegExp("^[0-9]{0,9}(?:[.][0-9]{0,3})?$");
    var res = regex.test(event.target.value);
    if(res){
      return true;
    }
    event.preventDefault();
    event.target.value = event.target.value.replace("/^[0-9]{0,9}(?:[.][0-9]{0,3})?$/",'');
    return false;
  }
  ValidateAwardAmt(event){
    var regex = new RegExp("^[0-9]{0,9}(?:[.][0-9]{0,1})?$");
    var res = regex.test(event.target.value);
    if(res){
      return true;
    }
    event.preventDefault();
    event.target.value = event.target.value.replace("/^[0-9]{0,9}(?:[.][0-9]{0,3})?$/",'');
    return false;
  }
  toggle(event: Event): void {
    const element: any = (event.target as Element);
    const classList = element.classList;
    const classes = element.className;
    const val = element.attributes.getNamedItem('id');
    if (classes.includes('btn-days-remove')) {
      classList.remove('btn-days-remove');
      classList.add('btn-days-add');
      this.gradeRules.push(val.value);
      // this.gradeRules.sort(function (a, b) { return a - b; });
    } else {
      classList.add('btn-days-remove');
      classList.remove('btn-days-add');
      this.gradeRules = this.gradeRules.filter(item => item !== val.value);
    }
    if (this.gradeRules.length >= 1) {
      this.awardForm.get('gradeRestriction').setValue(this.gradeRules.map(Number));
    } else {
      this.awardForm.get('gradeRestriction').setValue(null);
    }
  }
}

