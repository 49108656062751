import { BreadCrumbService } from './bread-crumb.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss'],
})
export class BreadCrumbComponent implements OnInit {

  constructor(public breadCrumbService: BreadCrumbService) { }

  ngOnInit() {}

}
