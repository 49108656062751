// used on activity details, school activity details, and school details
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Components } from '@ionic/core';
import { ActivitiesService } from '../activities/activities.service';
import { StaffService } from '../staff/staff.service';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
import { ModalController, ToastController } from '@ionic/angular';
import _ from 'lodash';
import { isAfter } from 'ngx-bootstrap/chronos/public_api';
import { ReportsService } from '../reports/reports.service';
@Component({
  selector: 'schedule-activity',
  templateUrl: './schedule-activity.component.html',
  styleUrls: ['./schedule-activity.component.scss'],
})
export class ScheduleActivityComponent implements OnInit {
  public scheduleActivityForm: FormGroup;
  loading = false;
  @Input() participatingSchools: any = [];
  @Input() participatingActivities: any = [];
  @Input() update: boolean;
  @Input() isActivity: boolean;
  @Input() scheduledActivity: any;
  @Input() modal: Components.IonModal;
  @Input() calanderType: any;
  @Input() calanderId: any;
  @Input() calanderIdDesc: any;
  @Input() preselectCal: any = false;
  calanderTypeDesc: any;
  showSpinners = false;
  staffMembers: any = [];
  primaryStaff: any = [];
  daysOfOp: any = [];
  private minStartDate: Date = new Date();
  private minEndDate: Date = new Date();
  private maxEndDate: Date = new Date();
  actSchoolId: number;
  schoolId: any;
  primaryStaffPlaceholder: any;
   //Start - Below variables for Core/ Supp selection
   indexCurrent: any;
   yearDescription: any;
   yearSelected: any;
   typeSelected: any = 1;
   fiscalYearList: any = [];
   masterCalendarList: any = [];
   currentMasterCalendar: any;
   calendarYearList: any = [];
   showFiscal: Boolean = true;
   showCal: Boolean;
   descriptionFirst: any;
   //calendertype: any = 1;
   iscalenderdisabled: Boolean;
   //End - variables for Core/ Supp selection
  get sortedActivities() {
    console.log(this.participatingActivities);
    return _.sortBy(this.participatingActivities, ['activityName']).filter(a => a.isActive == true);
  }
  get sortedStaff() {
    return _.sortBy(this.staffMembers, ['concatName']);
  }
  get sortedSchools() {
    return _.sortBy(this.participatingSchools, ['schoolName']);
  }

  constructor(public router: Router, public formBuilder: FormBuilder,
    private activityService: ActivitiesService, private staffService: StaffService,
    private modal2: ModalController, public toastController: ToastController,
    private reportsService: ReportsService) {
    this.scheduleActivityForm = formBuilder.group({
      schoolId: ['', [Validators.required]],
      activityId: ['', [Validators.required]],
      groupName: ['', [Validators.required, Validators.maxLength(50)]],
      primaryStaffId: ['', [Validators.required]],
      secondaryStaff: ['', [Validators.required]],
      dailyAttendanceTarget: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      startDate: ['', [Validators.required]],
      startTime: ['', [Validators.required]],
      timeRangeType: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      endTime: ['', [Validators.required]],
      daysofOperation: ['', [Validators.required]]
    });
  }


  ngOnInit() {
    this.fetchMasterCalendars();

    console.log("scheduledActivity ngOnInit()",this.calanderType, this.calanderId);
    this.scheduleActivityForm.statusChanges.subscribe(change => {
      if (this.scheduleActivityForm.dirty) {
        this.modal.backdropDismiss = false;
      }
    });
    // tslint:disable-next-line: max-line-length
    this.fetchData();

    if(!this.preselectCal){
      this.iscalenderdisabled = false;
    }

    if(this.calanderType == 1){
      this.fetchFiscalYears();
      this.showCal = false;
      this.showFiscal = true;
      this.typeSelected = 1;
    }else if(this.calanderType == 2){
      this.fetchCalendarYears();
      this.showCal = true;
      this.showFiscal = false;
      this.typeSelected = 2;
    }
    this.yearSelected = this.calanderId;
    this.indexCurrent = this.yearSelected;
  }

  fetchData() {
    // method to load any data required
    this.minEndDate.setDate(this.minEndDate.getDate() + 1);
    //To allow activity end date to end of current school year + 1 month
    this.maxEndDate.setMonth(6);
    this.maxEndDate.setFullYear(this.maxEndDate.getFullYear() + 1);
    // preload values
    this.populateForm().then(() => {
      this.getStaffList();
    });
    // this.fetchFiscalYears();
  }
  // limits primary staff selection to staff members at school chosen
  selectionChanged($event) {
    this.primaryStaff = [];
    if ($event.length) {
      $event.forEach(element => {
        if (!this.primaryStaff.includes(element.data)) {
          this.primaryStaff.push(element.data);
        }
      });
    }
  }
  selectionChanged2($event) {
    console.log('event here', $event)
    console.log('event here2', $event[0].value)
    this.actSchoolId = Number($event[0].value);
    this.getStaffList();
  }
  // fetch staff data
  async getStaffList() {
    if (this.isActivity) {
      this.schoolId = Number(this.actSchoolId);
    } else {
      this.schoolId = this.scheduleActivityForm.controls.schoolId.value;
    }
    // get staff by school id and user's agency id
    if (this.hasRoles([6, 7, 8])) {
      this.staffService.getStaffBySchoolBySchoolByAgency(this.schoolId, this.profile.agency, false).subscribe((data: any) => {
        console.log('getStaffBySchoolBySchoolByAgency(): data from service:', data);
        data.forEach(element => {
          element.concatName = element.lastName + ', ' + element.firstName;
        });
        // this.loading = false;
        console.log('getStaffBySchoolBySchoolByAgency(): done');
        this.staffMembers = data.filter(x => x.isActive == true && (x.role.id == '8' ||  x.role.id == '9' ));
      });
      // get staff by school id
    } else {
      this.staffService.getStaffBySchool(this.schoolId).subscribe((data: any) => {
        console.log('getStaffBySchool(): data from service:', data);
        data.forEach(element => {
          element.concatName = element.lastName + ', ' + element.firstName;
        });
        // this.loading = false;
        console.log('getStaffBySchool(): done');
        this.staffMembers = data.filter(x => x.isActive == true && (x.role.id == '8' ||  x.role.id == '9' ));
      });
    }
  }
  // enables choice of days of operation
  toggle(event: Event): void {
    const element: any = (event.target as Element);
    const classList = element.classList;
    const classes = element.className;
    const val = element.attributes.getNamedItem('value');
    if (classes.includes('btn-days-remove')) {
      classList.remove('btn-days-remove');
      classList.add('btn-days-add');
      this.daysOfOp.push(Number(val.value));
    } else {
      classList.add('btn-days-remove');
      classList.remove('btn-days-add');
      this.daysOfOp = this.daysOfOp.filter(item => item !== Number(val.value));
    }
    this.scheduleActivityForm.get('daysofOperation').setValue(this.daysOfOp.map(Number));
  }
  // populates form with locked data
  async populateForm() {
    let scheduleActivityFrm = this.scheduleActivityForm.value;
    // if there is only 1 participating school, user will not be asked to select an alt school
    if (this.participatingSchools.length === 1) {
      scheduleActivityFrm.schoolId = this.participatingSchools[0].id;
      this.scheduleActivityForm.controls.schoolId.disable();
    }
    // if there is only 1 participating activity, user will not be asked to select an alt activity
    if (this.participatingActivities.length === 1) {
      scheduleActivityFrm.activityId = this.participatingActivities[0].id;
      this.scheduleActivityForm.controls.activityId.disable();
    }
    // if user is updated a scheduled activity, the modal will be populated with the scheduled activity's current details
    if (this.update) {
      this.scheduledActivity.startDate = moment(this.scheduledActivity.startDate).format('MM/DD/YYYY');
      this.scheduledActivity.endDate = moment(this.scheduledActivity.endDate).format('MM/DD/YYYY');
      this.scheduledActivity.startTime = moment(this.scheduledActivity.startTime, 'HH:mm').toDate();
      this.scheduledActivity.endTime = moment(this.scheduledActivity.endTime, 'HH:mm').toDate();
      var secondaryStaffMembers = [];
      this.scheduledActivity.secondaryStaff.forEach(s => {
        if (!secondaryStaffMembers.includes(s.staffId)) {
          secondaryStaffMembers.push(s.staffId);
        }
      });
      if (!secondaryStaffMembers.includes(this.scheduledActivity.primaryStaffId )) {
        secondaryStaffMembers.push(this.scheduledActivity.primaryStaffId);
      }
      this.scheduledActivity.secondaryStaff = secondaryStaffMembers;
      if (this.scheduledActivity.daysofOperation) {
        this.scheduledActivity.daysofOperation.forEach(element => {
          const day = 'day' + element;
          const classList = document.getElementById(day).classList;
          classList.remove('btn-days-remove');
          classList.add('btn-days-add');
          this.daysOfOp.push(element);
        });
      }
      scheduleActivityFrm = this.scheduledActivity;
    }
    this.scheduleActivityForm.patchValue(scheduleActivityFrm);
  }
  onSelect3($event) {
    console.log('type event ', $event.target.value, $event);
    if (Number($event.target.value) === 1) {
      this.fetchFiscalYears();
      this.showFiscal = true;
      this.showCal = false;
      this.typeSelected = 1;
    } else {
      this.fetchCalendarYears();
      this.showCal = true;
      this.showFiscal = false;
      this.typeSelected = 2;
     // this.fetchData(true, this.typeSelected, this.indexCurrent);
    }
    this.calanderType = this.typeSelected;
    this.calanderId = this.yearSelected;
    //this.filterSchActivitesbyCalType(this.typeSelected, this.yearSelected);
  }

  onMasterCalendarChange(masterCalendarCode){
    this.currentMasterCalendar = masterCalendarCode;
  }

  onSelect2($event) {
    console.log('year event ', $event);
    this.yearSelected = $event;
   // this.fetchData(true, this.typeSelected, this.yearSelected);
    if (this.typeSelected === 1) {
      for (let i = 0; i < this.fiscalYearList.length; i++) {
        if (this.fiscalYearList[i].id === $event) {
          this.yearDescription = this.fiscalYearList[i].description;
        }
      }
    } else {
      for (let i = 0; i < this.calendarYearList.length; i++) {
        if (this.calendarYearList[i].id === $event) {
          this.yearDescription = this.calendarYearList[i].description;
        }
      }
    }
    this.calanderIdDesc = this.yearDescription;
    this.calanderId = this.yearSelected;
  }
  fetchFiscalYears() {
    this.reportsService.getFiscalYears().subscribe((data: any) => {
      console.log('fetchFiscalYears(): data from fiscalYear service:', data);
      data.forEach(element => {
        if (element.isCurrent) {
          this.indexCurrent = element.id;
          this.yearDescription = element.description;
        }
      });
      this.yearSelected = this.indexCurrent;
      console.log('step 1 idex here ', this.yearDescription, 'year ', this.yearSelected)

      this.fiscalYearList = data.filter(x => x.id >= this.indexCurrent);
      this.fiscalYearList.forEach(element => {
        if (element.id === this.indexCurrent) {
          this.descriptionFirst = element.description;
        }
      });
      this.calanderIdDesc = this.yearDescription;
      this.calanderId = this.yearSelected;
    });
  }
  fetchCalendarYears() {
    this.reportsService.getCalendarYears().subscribe((data: any) => {
      console.log('fetchCalendarYears(): data from calendarYear service:', data);
      data.forEach(element => {
        if (element.isCurrent) {
          this.indexCurrent = element.id;
          this.yearDescription = element.description;
        }
      });
      this.yearSelected = this.indexCurrent;
      console.log('step 1 idex here ', this.indexCurrent, 'year ', this.yearSelected)
      this.calendarYearList = data.filter(x => x.id >= this.indexCurrent);
      this.calanderIdDesc = this.yearDescription;
      this.calanderId = this.yearSelected;
    });
  }
  SetFiscalYears() {
    this.reportsService.getFiscalYears().subscribe((data: any) => {
      console.log('fetchFiscalYears(): data from fiscalYear service:', data);
      data.forEach(element => {
        if (element.isCurrent) {
          this.indexCurrent = element.id;
          this.yearDescription = element.description;
        }
      });
      this.yearSelected = this.indexCurrent;
      console.log('step 1 idex here ', this.yearDescription, 'year ', this.yearSelected)

      this.fiscalYearList = data.filter(x => x.id >= this.indexCurrent);
      this.fiscalYearList.forEach(element => {
        if (element.id === this.indexCurrent) {
          this.descriptionFirst = element.description;
        }
      });
      this.calanderIdDesc = this.yearDescription;
      this.calanderId = this.yearSelected;
    });
  }
  SetCalendarYears() {
    this.reportsService.getCalendarYears().subscribe((data: any) => {
      console.log('fetchCalendarYears(): data from calendarYear service:', data);
      data.forEach(element => {
        if (element.isCurrent) {
          this.indexCurrent = element.id;
          this.yearDescription = element.description;
        }
      });
      this.yearSelected = this.indexCurrent;
      console.log('step 1 idex here ', this.indexCurrent, 'year ', this.yearSelected)
      this.calendarYearList = data.filter(x => x.id >= this.indexCurrent);
      this.calanderIdDesc = this.yearDescription;
      this.calanderId = this.yearSelected;
    });
  }
  // creates a newly scheduled activity
  async scheduleActivity() {
    console.log(this.scheduleActivityForm);
    const activity = this.scheduleActivityForm.value;
    activity.masterCalendarCode = this.currentMasterCalendar;
    activity.activityId = Number(this.scheduleActivityForm.controls.activityId.value);
    activity.agencyId = this.profile.agency;
    activity.notes = '';
    activity.startTime = moment(new Date(activity.startTime)).format('HH:mm');
    activity.endTime = moment(new Date(activity.endTime)).format('HH:mm');
    activity.isActive = true;
    activity.calendarType = this.calanderType == 1 ? "CORE" : "SUPP";
    activity.calendarId = this.calanderId;
    activity.school = null;
    activity.dailyAttendanceTarget = Number(activity.dailyAttendanceTarget);
    activity.schoolId = Number(this.scheduleActivityForm.controls.schoolId.value);
    activity.activity = null;
    const secondaryStaffMembers = [];
    activity.secondaryStaff.forEach(element => {
      secondaryStaffMembers.push({ id: element, isActive: true, staffId: element });
    });
    activity.secondaryStaff = secondaryStaffMembers;
    activity.daysofOperation = this.daysOfOp.map(Number);
    activity.primaryStaffId = activity.primaryStaffId === '' ? null : activity.primaryStaffId;
    const isDateTimeAfter = moment(activity.startDate).isAfter(moment(activity.endDate))
      || moment(activity.startTime, 'h:mma').isAfter(moment(activity.endTime, 'h:mma'));
    const selectedActivity = this.participatingActivities.find(a => a.id === Number(activity.activityId));
    const eventActivity = {
      title: selectedActivity.activityName,
      startDate: activity.startDate,
      endDate: activity.endDate,
      daysofOperation: activity.daysofOperation
      // startTime: moment(new Date(activity.startDate)).format('hh:mm a'),
      // endTime:  moment(new Date(activity.endDate)).format('hh:mm a'),
    };
    if (isDateTimeAfter) {
      this.throwValidationError('The Start Date/Time cannot be before End Date/Time!');
    } else if (this.scheduleActivityForm.controls.schoolId.errors ||
      this.scheduleActivityForm.controls.activityId.errors ||
      this.scheduleActivityForm.controls.groupName.errors ||
      this.scheduleActivityForm.controls.primaryStaffId.errors ||
      this.scheduleActivityForm.controls.secondaryStaff.errors ||
      this.scheduleActivityForm.controls.dailyAttendanceTarget.errors ||
      this.scheduleActivityForm.controls.startDate.errors ||
      this.scheduleActivityForm.value.daysofOperation.length === 0 ||
      this.scheduleActivityForm.controls.timeRangeType.errors ||
      this.scheduleActivityForm.controls.startTime.errors ||
      this.scheduleActivityForm.controls.endTime.errors) {
      this.throwValidationError('All fields are required!');
    } else {
      this.activityService.scheduleActivity(activity).add(() => {
        this.modal2.dismiss(eventActivity);
      });
    }
    // this.activityService.scheduleActivity(activity);
  }

  async throwValidationError(message: string) {
    const toast = await this.toastController.create({
      color: 'danger',
      duration: 2500,
      message: message,
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
  }
  // updates an existing scheduled activity
  updateScheduleActivity() {
    this.scheduleActivityForm.controls.schoolId.enable();
    const activityVal = this.scheduleActivityForm.value;
    this.scheduledActivity.schoolId = activityVal.schoolId;
    this.scheduledActivity.activityId = Number(this.scheduleActivityForm.controls.activityId.value);
    this.scheduledActivity.groupName = activityVal.groupName;
    this.scheduledActivity.primaryStaffId = activityVal.primaryStaffId;
    this.scheduledActivity.masterCalendarCode = this.currentMasterCalendar;
    const secondaryStaffMembers = [];
    activityVal.secondaryStaff.forEach(element => {
      secondaryStaffMembers.push({ isActive: true, staffId: element });
    });
    this.scheduledActivity.secondaryStaff = secondaryStaffMembers;
    this.scheduledActivity.dailyAttendanceTarget = Number(activityVal.dailyAttendanceTarget);
    this.scheduledActivity.startDate = new Date(activityVal.startDate);
    this.scheduledActivity.startTime = moment(new Date(activityVal.startTime)).format('HH:mm');
    this.scheduledActivity.timeRangeType = activityVal.timeRangeType;
    this.scheduledActivity.endDate = new Date(activityVal.endDate);
    this.scheduledActivity.endTime = moment(new Date(activityVal.endTime)).format('HH:mm');
    const isDateTimeAfter = moment(this.scheduledActivity.startDate).isAfter(moment(this.scheduledActivity.endDate))
      || moment(this.scheduledActivity.startTime, 'h:mma').isAfter(moment(this.scheduledActivity.endTime, 'h:mma'));
    this.scheduledActivity.daysofOperation = this.daysOfOp.map(Number);
    this.scheduledActivity.calendarId = this.calanderId;
    this.scheduledActivity.calendarType = this.calanderType == 1 ? "CORE" : "SUPP";
    if (isDateTimeAfter) {
      this.throwValidationError('The Start Date/Time cannot be before End Date/Time!');
    }else {
      this.activityService.updateScheduledActivity(this.scheduledActivity).add(() => {
        this.modal.dismiss();
      });
    }
  }


  get profile() {
    return JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
  }

  hasRoles(roles: number[]): boolean {

    if (this.profile) {
      for (const aRole of this.profile.roles) {
        if (roles.includes(aRole)) {
          return true;
        }
      }
    }
    return false;
  }

  fetchMasterCalendars() {
    this.reportsService.getMasterCalendars().subscribe((data: any) => {
      console.log('fetchMasterCalendars(): data from calendarYear service:', data);
      // data.unshift(this.initItem);
      this.masterCalendarList = data.sort((a, b) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime()).slice(0,3);
      if(this.masterCalendarList != undefined || this.masterCalendarList == null){
        if(this.update){
          var currentMasterCalendar = this.masterCalendarList.find( mc => mc.masterCalendarCode == this.scheduledActivity.masterCalendarCode);
          if(currentMasterCalendar!= undefined){
            this.currentMasterCalendar = currentMasterCalendar.masterCalendarCode;
          }
        }
        else{
          var currentActiveMasterCalendar = this.masterCalendarList.find(m => m.isCurrent);
          if(currentActiveMasterCalendar!= undefined){
            this.currentMasterCalendar = currentActiveMasterCalendar.masterCalendarCode;
          }
        }
      }
    });
  }

}
