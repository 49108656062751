import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';

@Component({
  selector: 'school-details',
  templateUrl: './funding-details.component.html',
  styleUrls: ['./funding-details.component.scss'],
})
export class SchoolDetailsComponent2 implements OnInit {
  @Input() details;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    const paramId = this.route.snapshot.params['id'];
    console.log(paramId);
    console.log(this.details);
  }
}
