// used for ScheduleActivityComponent modal
import { Component, OnInit, Input } from '@angular/core';
import { Components } from '@ionic/core';
import { ModalController } from '@ionic/angular';
import { ScheduleActivityComponent } from '../schedule-activity/schedule-activity.component';

@Component({
  selector: 'pop-schedule-activity',
  templateUrl: './pop-schedule-activity.component.html',
  styleUrls: ['./pop-schedule-activity.component.scss'],
})
export class PopScheduleActivityComponent implements OnInit {
  @Input() popover: Components.IonPopover;
  @Input() schoolDetails: any;
  @Input() activities: any = [];
  constructor(public modalController: ModalController) { }

  ngOnInit() {
    console.log(this.schoolDetails);
  }
  // triggers ScheduleActivityComponent modal
  async scheduleActivity() {
    event.stopPropagation();
    const modal = await this.modalController.create({
      component: ScheduleActivityComponent,
      cssClass: 'my-custom-modal-css',
      componentProps: {
        participatingSchools: [this.schoolDetails],
        participatingActivities: this.activities
      }
    });
    return await modal.present();
  }
}
