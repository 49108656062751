import { mergeMap, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Calendar } from '../../calendar-editor/CalendarEvent';
import { environment } from '../../../environments/environment';
import { Storage } from '@ionic/storage';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class CalendarService {
    public idToken: string;
    public headers: HttpHeaders;

    constructor(private http: HttpClient, public storage: Storage, public toastController: ToastController) {
        this.storage.get('idToken').then(res => {
            this.idToken = res;
        });
        this.headers = new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.idToken
        );
    }
    getEnrichmentCodes() {
        console.log('getEnrichmentCodes()'); // todo: comment out logging as needed for prod
        return this.loadEnrichmentCodes().pipe(map(this.processEnrichmentCodes, this));
    }
    private loadEnrichmentCodes() {
        console.log('loadEnrichmentCodes() with url: ', environment.enrichmentCodesUrl);
        return this.http.get(environment.enrichmentCodesUrl);
    }
    processEnrichmentCodes(data: any) {
        console.log('processEnrichmentCodes() with', data.length, 'rows.');
        return data;
    }
    createCode(code) {
        console.log('createCode() with url: ', environment.enrichmentCodesUrl);
        this.storage.get('idToken').then(res => {
            this.idToken = res;
        });
        const header = new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.idToken
        );
        return this.http.post(environment.enrichmentCodesUrl, code, { headers: header }).subscribe(
            result => {
                // Handle result
                console.log(result);
            },
            error => {
                this.showError(error);
            },
            () => {
                this.showSuccess();
            }
        );
    }
    updateCode(code) {
        console.log('updateCode() with url: ', environment.enrichmentCodesUrl);
        // tslint:disable-next-line: max-line-length
        return this.http.put(environment.enrichmentCodesUrl, code).subscribe(
            result => {
                // Handle result
                console.log(result);
            },
            error => {
                this.showError(error);
            },
            () => {
                this.showSuccess();
            }
        );
    }
    addException(range: any) {
        const route = `${environment.calendarApiUrl}`;
        return this.http.post(route, range);
    }
    addStudentAttendanceException(range: any) {
        const route = `${environment.studentsUrl}attendance/exceptions`;
        return this.http.post(route, range, { headers: this.headers });
    }
    removeException(range: any) {
        const route = `${environment.calendarApiUrl}/${range.id}`;
        return this.http.delete(route, range);
    }
    removeStudentAttendanceException(range: any) {
        const route = `${environment.studentsUrl}attendance/exceptions/${range.id}`;
        return this.http.delete(route, range);
    }
    updateException(range: any) {
        const route = `${environment.calendarApiUrl}/${range.id}`;
        return this.http.put(route, range);
    }
    exceptions() {
        const route = `${environment.calendarApiUrl}`;
        return this.http.get(route);
    }

    studentAttendanceExceptions() {
        const route = `${environment.studentsUrl}attendance/exceptions`;
        return this.http.get(route, { headers: this.headers });
    }

    calendars() {
        const route = `${environment.operationalCalendarApiUrl}`;
        return this.http.get<any>(route);
    }

    calendarBySchoolCalendar(calendarId: number) {
        const route = `${environment.operationalCalendarApiUrl}school/calendar/${calendarId}`;
        return this.http.get<any>(route);
    }
    calendarsBySchool(id: any) {
        const route = `${environment.operationalCalendarApiUrl}school/${id}`;
        return this.http.get<any>(route);
    }
    assignCalendar(selectedSchools: any) {
        const route = `${environment.operationalCalendarApiUrl}school/batch`;
        return this.http.post<any>(route, selectedSchools);
    }
    deleteCalendar(selectedSchools: any) {
        const route = `${environment.operationalCalendarApiUrl}school/remove/batch`;
        return this.http.post<any>(route, selectedSchools);
    }
    removeCalendar(row: any) {
        const route = `${environment.operationalCalendarApiUrl}${row.id}`;
        return this.http.delete<any>(route);
    }
    getSchoolsByCalendar(id: number, includeNotAssigned: boolean) {
        let route = `${environment.operationalCalendarApiUrl}${id}/schools`;
        if (includeNotAssigned) {
            route = `${route}?notassignedschools=true`;
        }
        return this.http.get<any>(route);
    }
    getAllSchools() {
        const route = `${environment.operationalCalendarApiUrl}new/schools`;
        return this.http.get<any>(route);
    }
    updateSchoolCalendar(calendar: any) {
        const route = `${environment.operationalCalendarApiUrl}school/calendar/${calendar.id}`;
        const request = this.http.put(`${route}`, calendar);
        return request;
    }
    addOrUpdate(calendar: any): Observable<any> {
        const route = `${environment.operationalCalendarApiUrl}`;
        const request = calendar.id ?
            this.http.put(`${route}${calendar.id}`, calendar) :
            this.http.post(route, calendar);
        return request;
    }

    calendar(calendarId: any): Observable<any> {
        const route = `${environment.operationalCalendarApiUrl}${calendarId}`;
        return this.http.get<any>(route);
    }
    async showSuccess() {
        const toast = await this.toastController.create({
            color: 'success',
            duration: 2500,
            message: 'Success!',
            showCloseButton: true,
            position: 'top'
        });
        await toast.present();
        setTimeout(function () {
        }, 1500);
    }

    async showError(error) {
        console.log(error);
        const toast = await this.toastController.create({
            color: 'danger',
            duration: 2500,
            message: 'Error!',
            showCloseButton: true,
            position: 'top'
        });
        await toast.present();
    }
}


