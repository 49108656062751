import { ModalAlertComponent, ModalComponent } from './../modal-alert/modal-alert.component';
import { UserData } from './../users/user-data';
import { CalendarService } from './../core/services/calendar.service';
import { TabsService } from './../components/tabs.service';
import { AlertController, Config, ModalController, PopoverController, ToastController } from '@ionic/angular';
import { StringUtil } from '../shared/string-util';
import { CalendarEditorComponent } from './../calendar-editor/calendar-editor.component';
import { AgenciesService } from '../agencies/agencies.service';
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  AfterContentInit,
  OnDestroy,
  HostListener,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { DateRange } from '../calendar-editor/DateRange';
import { CalendarEvent } from '../calendar-editor/CalendarEvent';
import { MomentLongDateFormat } from 'moment';
import * as m from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { DatatableComponent, ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { map, startWith } from 'rxjs/operators';
import { CalendarPeriod } from './CalendarPeriod';
import { ReportsService } from '../reports/reports.service';
import { BreadCrumbService } from '../components/bread-crumb/bread-crumb.service';
import { StaffService } from '../staff/staff.service';
import { PopCertificationTypesComponent } from '../pop-certification-types/pop-certification-types.component';
import { UpdateCertificationTypeComponent } from '../update-certification-type/update-certification-type.component';
import * as moment from 'moment';
import { UpdateCodeComponent } from '../update-enrichmentCode/update-code.component';
import { PopEnrichmentCodesComponent } from '../pop-enrichmentCodes/pop-enrichment-codes.component';
import { NgxSelectOption } from 'ngx-select-ex';
import { SubmitAttendanceConfirmationComponent } from '../submit-attendance-confirmation/submit-attendance-confirmation.component';
import { StudentsService } from '../students/students.service';
import _ from 'lodash';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SchoolDetailsService } from '../school-details/school-details.service';
import { SchoolsService } from '../schools/schools.service';
import { AppComponent } from '../app.component';
import { PopRetroActionsComponent } from '../pop-retro-actions/pop-retro-actions.component';
import { PopDeleteAttendanceActionsComponent } from '../pop-delete-attendance-actions/pop-delete-attendance-actions.component';
import { Observable, of } from 'rxjs';
import { env } from 'process';
@Component({
  selector: 'master-calendar-page',
  templateUrl: './master-calendar-page.component.html',
  styleUrls: ['./master-calendar-page.component.scss'],
})
export class MasterCalendarPageComponent implements OnInit, AfterViewInit, OnDestroy {
  toppings = new FormControl('');
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild(DatatableComponent, { static: false }) table2: DatatableComponent;
  moment = m;
  calendars = [];
  selected = [];
  fiscalYearList = [];
  calendarYearList = [];
  masterCalendarList = [];
  currentMasterCalendar: any;
  currentMasterCalendarCode: string;
  selectedMasterCalendarCode: string;
  types = [];
  filteredTypes = [];
  filteredRows: any = [];
  showHideInactiveCodes: string = 'Show Inactivated Codes';
  codeButtonColor: string = this.showHideInactiveCodes == 'Show Inactivated Codes' ? 'secondary' : 'medium';
  queryText = '';
  queryTextCert = '';
  exceptions: any = [];
  filteredExceptions: any = [];
  studentExceptions: any = [];
  filteredStudentExceptions: any = [];
  type = 'Core';
  filteredCalendars = [];
  initItem = { 'id': 0, 'description': 'All' };
  innerWidthGreater: boolean;
  filterColumnsCert = ['name', 'code', 'description', 'effectiveDate', 'certificateExpDays', 'active'];
  filterColumnsCalendar = ['name', 'type', 'start_date', 'end_date'];
  filterColumnsException = ['description', 'start_date', 'end_date'];
  filterColumnsStudentException = ['description', 'start_date', 'end_date'];
  enrichmentCodes: any;
  loading = false;
  columnMode = ColumnMode.force;
  filteredCodes: any;
  defaultSort = [{ prop: 'code', dir: 'asc' }];
  columns = [
    { name: 'Name', prop: 'code' },
    { name: 'Description', prop: 'description' },
    { name: 'Active', prop: 'active' },
  ];
  filterColumns = ['code', 'description', 'active'];
  currentFiscalYear: any;
  currentCalendarYear: any;
  selectedDate: any;
  selectedStartDate: any
  selectedEndDate: any
  selectedDeleteAttendanceStartDate: any
  selectedDeleteAttendanceEndDate: any
  limitCnt: any = 100;
  list: 'list';
  ios: boolean;
  public retroMultiApprovalForm: FormGroup;
  public delAttendanceMultiApprovalForm: FormGroup;
  selectedRetrosForApproval: any = [];
  noOfAttendanceCount: any = 0;
  noOfSelectedRetroCount: any = 0;
  noOfDelAttendanceCount: any = 0;
  noOfDelAttendanceSelectedCount: any = 0;
  minAttendanceDateRange: Date = new Date(2023, 0, 1);
  maxAttendanceDateRange: Date = new Date();
  minAttendanceEndDateRange: Date = new Date();

  minDeleteAttendanceDateRange: Date = new Date(2023, 0, 1);
  maxDeleteAttendanceDateRange: Date = new Date();
  minDeleteAttendanceEndDateRange: Date = new Date();

  constructor(
    private modalController: ModalController,
    private router: Router,
    private route: ActivatedRoute,
    public tc: TabsService,
    private calendarService: CalendarService,
    private staffService: StaffService,
    private reportsService: ReportsService,
    private popoverController: PopoverController,
    private activatedRoute: ActivatedRoute,
    public ud: UserData,
    private bc: BreadCrumbService,
    public alertController: AlertController,
    public toastController: ToastController,
    private studentsService: StudentsService,
    private schoolDetailsService: SchoolDetailsService,
    public config: Config,
    private schoolsService: SchoolsService,
    private appComponent: AppComponent,
    private cdref: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    public agenciesService: AgenciesService
  ) {
    this.attendanceApproval = formBuilder.group({
      students: new FormArray([]),
      approverNotes: ['']
    });
    this.retroMultiApprovalForm = formBuilder.group({
      retros: new FormArray([]),
    });
    this.delAttendanceApproval = formBuilder.group({
      students: new FormArray([]),
      approverNotes: ['']
    });
    this.delAttendanceMultiApprovalForm = formBuilder.group({
      retros: new FormArray([]),
    });


  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidthGreater = window.innerWidth <= 728;
  }

@ViewChild('searchAgency', { static: false }) searchAgencyTextBox: ElementRef;
@ViewChild('searchSchool', { static: false }) searchSchoolTextBox: ElementRef;

@ViewChild('searchDeleteAttendanceAgency', { static: false }) searchDeleteAgencyTextBox: ElementRef;
@ViewChild('searchDeleteAttendanceSchool', { static: false }) searchDeleteSchoolTextBox: ElementRef;

selectAgencyFormControl = new FormControl();
searchAgencyInputTxt = new FormControl();
selectedAgency = [];
filteredAgencyOptions: Observable<any[]>;

selectSchoolFormControl = new FormControl();
searchSchoolInputTxt = new FormControl();
selectedSchool = [];
filteredSchoolOptions: Observable<any[]>;

selectDeleteAgencyFormControl = new FormControl();
searchDeleteAgencyInputTxt = new FormControl();
delAttendanceSelectedAgency = [];
filteredDeleteAgencyOptions: Observable<any[]>;

selectDeleteSchoolFormControl = new FormControl();
searchDeleteSchoolInputTxt = new FormControl();
delAttendanceSelectedSchool = [];
filteredDeleteSchoolOptions: Observable<any[]>;

private _agencyFilter(name: string): String[] {
  const filterValue = name.toLowerCase();
  this.setAgencySelectedValues();
  this.selectAgencyFormControl.patchValue(this.selectedAgency);
  let filteredList = this.agencyList.filter(option => option.agencyName.toLowerCase().indexOf(filterValue) === 0);
  return filteredList;
}

private _deleteAttendanceAgencyFilter(name: string): String[] {
  const filterValue = name.toLowerCase();
  this.setDeleteAttendanceAgencySelectedValues();
  this.selectDeleteAgencyFormControl.patchValue(this.delAttendanceSelectedAgency);
  let filteredList = this.delAttendanceAgencyList.filter(option => option.agencyName.toLowerCase().indexOf(filterValue) === 0);
  return filteredList;
}

private _deleteAttendanceSchoolFilter(name: string): String[] {
  const filterValue = name.toLowerCase();
  this.setDeleteAttendanceSchoolSelectedValues();
  this.selectDeleteSchoolFormControl.patchValue(this.delAttendanceSelectedSchool);
  let filteredList = this.delAttendanceSchoolList.filter(option => option.schoolName.toLowerCase().indexOf(filterValue) === 0);
  return filteredList;
}

private _schoolFilter(name: string): String[] {
  const filterValue = name.toLowerCase();
  this.setSchoolSelectedValues();
  this.selectSchoolFormControl.patchValue(this.selectedSchool);
  let filteredList = this.schoolList.filter(option => option.schoolName.toLowerCase().indexOf(filterValue) === 0);
  return filteredList;
}



selectionAgencyChange(event) {
  if (event.isUserInput) 
  {
    if(event.source.selected == true)
    {
        event.source.value=='All Agencies'? this.selectedAgency = this.agencyList.map(x=>{return x.agencyShortCode}) : this.selectedAgency.push(event.source.value);
    }
    else
    {
      if(event.source.value=='All Agencies')
      {
        this.selectedAgency =[];
      }
      else
      {
        let index = this.selectedAgency.indexOf(event.source.value);
        this.selectedAgency.splice(index, 1)
        let allindex = this.selectedAgency.indexOf('All Agencies');
        this.selectAgencyFormControl.value.includes('All Agencies')? this.selectedAgency.splice(allindex, 1):'';
      }
    }
    this.selectAgencyFormControl.patchValue(this.selectedAgency);
  }
}

selectionDeleteAttendanceAgencyChange(event) {
  if (event.isUserInput) 
  {
    if(event.source.selected == true)
    {
        event.source.value=='All Agencies'? this.delAttendanceSelectedAgency = this.delAttendanceAgencyList.map(x=>{return x.agencyShortCode}) : this.delAttendanceSelectedAgency.push(event.source.value);
    }
    else
    {
      if(event.source.value=='All Agencies')
      {
        this.delAttendanceSelectedAgency =[];
      }
      else
      {
        let index = this.delAttendanceSelectedAgency.indexOf(event.source.value);
        this.delAttendanceSelectedAgency.splice(index, 1)
        let allindex = this.delAttendanceSelectedAgency.indexOf('All Agencies');
        this.selectDeleteAgencyFormControl.value.includes('All Agencies')? this.delAttendanceSelectedAgency.splice(allindex, 1):'';
      }
    }
    this.selectDeleteAgencyFormControl.patchValue(this.delAttendanceSelectedAgency);
  }
}

selectionSchoolChange(event) {
  if (event.isUserInput) 
  {
    if(event.source.selected == true)
    {
        event.source.value=='All Schools'? this.selectedSchool = this.schoolList.map(x=>{return x.schoolName}) : this.selectedSchool.push(event.source.value);
    }
    else
    {
      if(event.source.value=='All Schools')
      {
        this.selectedSchool =[];
      }
      else
      {
        let index = this.selectedSchool.indexOf(event.source.value);
        this.selectedSchool.splice(index, 1)
        let allindex = this.selectedSchool.indexOf('All Schools');
        this.selectSchoolFormControl.value.includes('All Schools')? this.selectedSchool.splice(allindex, 1):'';
      }
    }
    this.selectSchoolFormControl.patchValue(this.selectedSchool);
  }
}

selectionDeleteAttendanceSchoolChange(event) {
  if (event.isUserInput) 
  {
    if(event.source.selected == true)
    {
        event.source.value=='All Schools'? this.delAttendanceSelectedSchool = this.delAttendanceSchoolList.map(x=>{return x.schoolName}) : this.delAttendanceSelectedSchool.push(event.source.value);
    }
    else
    {
      if(event.source.value=='All Schools')
      {
        this.delAttendanceSelectedSchool =[];
      }
      else
      {
        let index = this.delAttendanceSelectedSchool.indexOf(event.source.value);
        this.delAttendanceSelectedSchool.splice(index, 1)
        let allindex = this.delAttendanceSelectedSchool.indexOf('All Schools');
        this.selectDeleteSchoolFormControl.value.includes('All Schools')? this.delAttendanceSelectedSchool.splice(allindex, 1):'';
      }
    }
    this.selectDeleteSchoolFormControl.patchValue(this.delAttendanceSelectedSchool);
  }
}

openedAgencyChange(e) {
  this.searchAgencyInputTxt.patchValue('');
  if (e == true) {
    this.searchAgencyTextBox.nativeElement.focus();
  }
}

openedDeleteAttendanceAgencyChange(e) {
  this.searchDeleteAgencyInputTxt.patchValue('');
  if (e == true) {
    this.searchDeleteAgencyTextBox.nativeElement.focus();
  }
}


openedDeleteAttendanceSchoolChange(e) {
  this.searchDeleteSchoolInputTxt.patchValue('');
  if (e == true) {
    this.searchDeleteSchoolTextBox.nativeElement.focus();
  }
}

openedSchoolChange(e) {
  this.searchSchoolInputTxt.patchValue('');
  if (e == true) {
    this.searchSchoolTextBox.nativeElement.focus();
  }
}

clearAgencySearch(event) {
  event.stopPropagation();
  this.searchAgencyInputTxt.patchValue('');
}

clearDeleteAttendanceAgencySearch(event) {
  event.stopPropagation();
  this.searchDeleteAgencyInputTxt.patchValue('');
}

clearDeleteAttendanceSchoolSearch(event) {
  event.stopPropagation();
  this.searchDeleteSchoolInputTxt.patchValue('');
}

clearSchoolSearch(event) {
  event.stopPropagation();
  this.searchSchoolInputTxt.patchValue('');
}

setAgencySelectedValues() {
  if (this.selectAgencyFormControl.value && this.selectAgencyFormControl.value.length > 0) {
      this.selectAgencyFormControl.value.forEach((e) => {
        if (this.selectedAgency.indexOf(e) == -1) {
          this.selectedAgency.push(e);
        }
      });
  }
}

setDeleteAttendanceAgencySelectedValues() {
  if (this.selectDeleteAgencyFormControl.value && this.selectDeleteAgencyFormControl.value.length > 0) {
      this.selectDeleteAgencyFormControl.value.forEach((e) => {
        if (this.delAttendanceSelectedAgency.indexOf(e) == -1) {
          this.delAttendanceSelectedAgency.push(e);
        }
      });
  }
}

setSchoolSelectedValues() {
  if (this.selectSchoolFormControl.value && this.selectSchoolFormControl.value.length > 0) {
      this.selectSchoolFormControl.value.forEach((e) => {
        if (this.selectedSchool.indexOf(e) == -1) {
          this.selectedSchool.push(e);
        }
      });
  }
}

setDeleteAttendanceSchoolSelectedValues() {
  if (this.selectDeleteSchoolFormControl.value && this.selectDeleteSchoolFormControl.value.length > 0) {
      this.selectDeleteSchoolFormControl.value.forEach((e) => {
        if (this.delAttendanceSelectedSchool.indexOf(e) == -1) {
          this.delAttendanceSelectedSchool.push(e);
        }
      });
  }
}
fetchAgencyList() {
  this.agenciesService.getMissedAttendanceAgencies().subscribe((data: any) => {
    console.log('fetchAgencyList(): data from agencies service:', data);
    _.each(data, r => {
      this.agencyList.push({
        agencyName: r.agencyName,
        agencyShortCode: r.agencyShortCode
      })
    })
    this.agencyList = _.uniqBy(this.agencyList, 'agencyShortCode');
    let agencyEvent = {isUserInput:true,source:{selected:true,value:'All Agencies'}}
    this.selectionAgencyChange(agencyEvent);
  });
}

fetchSchoolList() {
  
  this.schoolsService.getMissedAttendanceSchools().subscribe((data: any) => {
    console.log('fetchSchoolList(): data from agencies service:', data);
    var list =  data.reduce((acc,current)=>acc.concat(current),[]);
    _.each(list, r => {
        this.schoolList.push({
          schoolName:r.schoolName
        })
    })
    this.schoolList = _.uniqBy(this.schoolList, 'schoolName');
    let schoolEvent = {isUserInput:true,source:{selected:true,value:'All Schools'}}
    this.selectionSchoolChange(schoolEvent)
   
  });
}

fetchDeleteAttendanceAgencyList() {
  this.agenciesService.getDeleteAttendanceAgencies().subscribe((data: any) => {
    console.log('fetchDeleteAttendanceAgencyList(): data from agencies service:', data);
    _.each(data, r => {
      this.delAttendanceAgencyList.push({
        agencyName: r.agencyName,
        agencyShortCode: r.agencyShortCode
      })
    })
    this.delAttendanceAgencyList = _.uniqBy(this.delAttendanceAgencyList, 'agencyShortCode');
    let agencyEvent = {isUserInput:true,source:{selected:true,value:'All Agencies'}}
    this.selectionDeleteAttendanceAgencyChange(agencyEvent);
  });
}

fetchDeleteAttendanceSchoolList() {
  
  this.schoolsService.getDeleteAttendanceSchools().subscribe((data: any) => {
    console.log('fetchDeleteAttendanceSchoolList(): data from agencies service:', data);
    var list =  data.reduce((acc,current)=>acc.concat(current),[]);
    _.each(list, r => {
        this.delAttendanceSchoolList.push({
          schoolName:r.schoolName
        })
    })
    this.delAttendanceSchoolList = _.uniqBy(this.delAttendanceSchoolList, 'schoolName');
    let schoolEvent = {isUserInput:true,source:{selected:true,value:'All Schools'}}
    this.selectionDeleteAttendanceSchoolChange(schoolEvent)
   
  });
}

  ngOnInit(): void {

    this.selectedFilter = 'Pending';
    this.delAttendanceSelectedFilter = 'Pending';


    this.fetchAgencyList();
    this.fetchDeleteAttendanceAgencyList();

    this.fetchSchoolList();
    this.fetchDeleteAttendanceSchoolList();

    this.filteredAgencyOptions = this.searchAgencyInputTxt.valueChanges
    .pipe(
      startWith<string>(''),
      map(name => this._agencyFilter(name))
    );

    this.filteredDeleteAgencyOptions = this.searchDeleteAgencyInputTxt.valueChanges
    .pipe(
      startWith<string>(''),
      map(name => this._deleteAttendanceAgencyFilter(name))
    );

    this.filteredSchoolOptions = this.searchSchoolInputTxt.valueChanges
    .pipe(
      startWith<string>(''),
      map(name => this._schoolFilter(name))
    );

    this.filteredDeleteSchoolOptions = this.searchDeleteSchoolInputTxt.valueChanges
    .pipe(
      startWith<string>(''),
      map(name => this._deleteAttendanceSchoolFilter(name))
    );

    this.innerWidthGreater = window.innerWidth <= 728;
    var last30today = new Date();
    last30today.setDate(last30today.getDate() - 30);



    
    this.fetchFiscalYears();
    this.fetchCalendarYears();
    this.fetchMasterCalendars();
    this.fetchCodes();
    if (this.ud.hasRoles([1, 4])) {
      this.fetchCertificationTypes();
    }
    this.bc.breadCrumb$.next('Calendar');
    this.type = 'details';
    this.list = 'list';
    this.ios = this.config.get('mode') === 'ios';
    this.schoolId = Number(this.route.snapshot.params['id']);

    // blocks roles 6 - 8 from seeing school details
    if (this.profile.roles.length > 0 &&
      (this.profile.roles.includes(6) || this.profile.roles.includes(7) || this.profile.roles.includes(8))) {
      this.schoolsService.getSchoolsByAgency(this.profile.agency).subscribe((data: any) => {
        const allowed = data.some(x => x.id === Number(this.schoolId));
        if (!allowed) {
          this.router.navigateByUrl('/unauthorized');
          event.stopPropagation();
        }
      });
    }

    let tabSelected = this.route.snapshot.queryParams['tabSelected'];

    if (tabSelected) {
      this.tc.tabChannel = tabSelected;
    }

    if (this.route.snapshot.params['schoolId']) {
      this.schoolId = Number(this.route.snapshot.params['schoolId']);
    }

      this.fetchMasterCalendars();
      // this.fetchActivityData();
      this.fetchAttendanceRetro(this.currentMasterCalendar);
      // this.fetchAttendanceRetroList();
      // this.fetchDeleteAttendanceList();
      this.calendarService
        .calendarsBySchool(this.schoolId)
        .pipe(
          map((calendars) => {

          const hasCalendar = calendars != null && calendars.length > 0;

          if (hasCalendar) {
            return this.parseCalendar(calendars);
          } else {
            // Remove calendar option from page tabs
            this.appComponent.removeTabOption(this.route, "calendar");
          }
        })
      )
      .subscribe((calendars) => {
        if (calendars) {
          this.calendars = calendars.reverse();
        }
      });

  }


  ngAfterViewInit() {
    console.log('ngAfterViewInit() Start ->>>>>>>>>XXXXXXX');
    this.loadCalendars();
    this.checkPermissions();

    const typeParam = this.route.snapshot.queryParams['type'];
    if (typeParam) {
      this.type = typeParam === '2' ? 'Supplemental' : 'Core';
    }
  }
  getCellClass({ row, column, value }) {
    let applyStyle: string = '';
    if (row.isApproved) {
      applyStyle = applyStyle + ' compliant_green';
    }

    let disableRow: string = '';
    if (row.isActive == false) {
      // changes CSS properties for codes that are inactive
      disableRow = ' row-disabled ';
    }

    if (column.prop === 'name' || column.prop === 'description') {
      return disableRow + applyStyle + ' left-aligned-col';
    } else if (column.prop === 'agencyId' || column.prop === 'enrolledSchoolCount') {
      return applyStyle + ' right-aligned-col';
    } else if (column.prop === 'code' || column.prop === 'active') {
      return disableRow;
    } else {
      return applyStyle;
    }
  }

  fetchCertificationTypes() {
    this.staffService.getTypes().subscribe((data: any) => {
      console.log('fetchCertificationTypes(): data from service:', data);
      // this.loading = false;
      data.forEach(element => {
        element.effectiveDate = new Date(element.effectiveDate);
        element.effectiveDateDisp = moment(element.effectiveDate).format('MM/DD/YYYY');
        element.active = element.isActive ? 'Yes' : 'No';
      });
      console.log('fetchCertificationTypes(): done');
      this.types = data;
      this.filteredTypes = data;
    });
  }
  async createCode(update) {
    console.log(update);
    const modal = await this.modalController.create({
      component: UpdateCodeComponent,
      componentProps: { update: false }
    });
    modal.onDidDismiss().then(() => {
      this.fetchCodes();
    });
    return await modal.present();
  }
  /*async updateCode(update) {
    console.log(update);
    const modal = await this.modalController.create({
      component: UpdateCodeComponent,
      componentProps: { update: true, updateCode: update }
    });
    modal.onDidDismiss().then(() => {
      this.fetchCodes();
    });
    return await modal.present();
  }*/
  async presentPopover2(ev: any, row: any) {
    event.stopPropagation();
    const popover = await this.popoverController.create({
      component: PopEnrichmentCodesComponent,
      cssClass: 'popover-custom-css',
      event: ev,
      translucent: true,
      componentProps: { type: row, update: true },
    });
    popover.onDidDismiss().then((result) => {
      if (result.data !== 'cancel' && result.data !== undefined) {
        this.calendarService.updateCode(result.data).add(() => {
          this.fetchCodes();
        });
      }
    });
    return await popover.present();
  }
  clearEnrichSearch() {
    this.queryText = '';
    this.filteredCodes = this.enrichmentCodes;
  }

  filterRows() {
    const safeQueryText = StringUtil.escapeSpecialCharacters(
      this.queryText.trim().toLowerCase()
    );

    this.filteredCalendars = this.calendars.filter((row: any) => {
      if (!safeQueryText) {
        return true;
      }
      for (const filterColumn of this.filterColumnsCalendar) {
        let colValue = row[filterColumn];
        if (moment.isDate(colValue)) {
          colValue = moment(colValue).format('MM/DD/YYYY');
        }
        // toString() is needed if we want to search on numbers
        if (
          colValue &&
          colValue
            .toString()
            .toLowerCase()
            .match(safeQueryText)
        ) {
          return true;
        }
      }
      return false;
    });


  }

  showHideInactivatedCodes(){
    if(this.showHideInactiveCodes == 'Show Inactivated Codes'){
      this.filteredCodes= this.enrichmentCodes;
      this.codeButtonColor = 'medium'; 
    }
    else{
      var allRows = this.enrichmentCodes;
      this.filteredCodes = allRows.filter(a => a.isActive == true);
      this.codeButtonColor = 'secondary';
    }    
    this.showHideInactiveCodes = this.showHideInactiveCodes == 'Show Inactivated Codes' ? 'Hide Inactivated Codes' : 'Show Inactivated Codes';
  }

  filterExceptionRows(queryTextExc) {
    const safeQueryText = queryTextExc.trim().toLowerCase();
    this.filteredExceptions = this.exceptions.filter((row: any) => {
      if (!safeQueryText) {
        return true;
      }
      for (const filterColumn of this.filterColumnsException) {
        let colValue = row[filterColumn];
        if (moment.isDate(colValue)) {
          colValue = moment(colValue).format('MM/DD/YYYY');
        }
        // toString() is needed if we want to search on numbers
        if (
          colValue &&
          colValue
            .toString()
            .toLowerCase()
            .match(safeQueryText)
        ) {
          return true;
        }
      }
      return false;
    });

  }

  filterStudentExceptionRows(queryTextStudentExc) {
    const safeQueryText = queryTextStudentExc.trim().toLowerCase();
    this.filteredStudentExceptions = this.studentExceptions.filter((row: any) => {
      if (!safeQueryText) {
        return true;
      }
      for (const filterColumn of this.filterColumnsStudentException) {
        let colValue = row[filterColumn];
        if (moment.isDate(colValue)) {
          colValue = moment(colValue).format('MM/DD/YYYY');
        }
        // toString() is needed if we want to search on numbers
        if (
          colValue &&
          colValue
            .toString()
            .toLowerCase()
            .match(safeQueryText)
        ) {
          return true;
        }
      }
      return false;
    });
  }

  filterRetroRows(queryTextRetro) {

    const safeQueryText = queryTextRetro.trim().toLowerCase()


    this.filteredRetroList = this.retroList.filter((row: any) => {
      if (!safeQueryText) {
        return true;
      }
      var keys = Object.keys(row)

      for (const key of keys) {
        //console.log(row[key]);
        //console.log(key);
        if (key == "agencyName" || key == "schoolName" || key == "cdS7Code" || key == "createdBy" || key == "createdOn" || key == "agencyShortCode" || key == "attendanceTime" || key == "attendanceCount" || key == "status") {
          if (
            row[key] &&
            row[key]
              .toString()
              .toLowerCase()
              .match(safeQueryText)
          ) {
            //console.log(row[key], key);
            return true;
          }
        }
      }

      return false;
    });

    this.applyRetroListFilter();
    //this.filteredRetroList = this.filteredRetroList.filter(r =>  r.schoolName == this.selectedSchool);
  }

  filterDeleteRows(queryTextDelete){
    
    const safeQueryText = queryTextDelete.trim().toLowerCase()
    
    this.filteredDeleteAttendanceList = this.retroList.filter((row: any) => {
      if (!safeQueryText) {
        return true;
      }
      var keys = Object.keys(row)

      for (const key of keys) {
        //console.log(row[key]);
        //console.log(key);
        if(key == "agencyName" || key=="schoolName" || key=="cdS7Code" || key=="createdBy" || key=="createdOn" || key== "agencyShortCode" || key=="attendanceTime" || key=="attendanceCount" || key=="status"){
          if (
            row[key] &&
            row[key]
              .toString()
              .toLowerCase()
              .match(safeQueryText)
          ) {
            //console.log(row[key], key);
            return true;
          }
        }
        }
      return false;
    });

    this.applyDeleteAttendanceListFilter();
  }

  filterCodeRows() {
    const safeQueryText = StringUtil.escapeSpecialCharacters(
      this.queryText.trim().toLowerCase()
    );
    this.filteredCodes = this.enrichmentCodes.filter((row: any) => {
      if (!safeQueryText) {
        return true;
      }
      for (const filterColumn of this.filterColumns) {
        const colValue = row[filterColumn];
        if (
          colValue &&
          colValue
            .toString()
            .toLowerCase()
            .match(safeQueryText)
        ) {
          return true;
        }
      }
      return false;
    });
    this.table.offset = 0;
  }
  fetchFiscalYears() {
    this.reportsService.getFiscalYears().subscribe((data: any) => {
      console.log('fetchFiscalYears(): data from fiscalYear service:', data);
      // data.unshift(this.initItem);
      this.fiscalYearList = data;

      let fiscalCalendarYear = this.currentFiscalCalendarYear();
      let currentFiscalYear = `FY${fiscalCalendarYear - 1}-${fiscalCalendarYear}`;
      let defaultSelection = data.find(d => d.description === currentFiscalYear);

      if (defaultSelection != undefined) {
        this.currentFiscalYear = defaultSelection.id;
        this.filterCalendarList(this.currentFiscalYear);
      }
    });
  }
  fetchMasterCalendars() {
    this.reportsService.loadAdminMasterCalendars().subscribe((data: any) => {
      console.log('fetchMasterCalendars(): data from calendarYear service:', data);
      // data.unshift(this.initItem);
      this.masterCalendarList = data;
      if (this.masterCalendarList != undefined || this.masterCalendarList == null) {
        var currentActiveMasterCalendar = this.masterCalendarList.find(m => m.isCurrent);
        if (currentActiveMasterCalendar != undefined) {
          this.currentMasterCalendar = currentActiveMasterCalendar.masterCalendarName;
          this.currentMasterCalendarCode = currentActiveMasterCalendar.masterCalendarCode;
          this.selectedMasterCalendarCode = currentActiveMasterCalendar.masterCalendarCode;
        }
      }
    });
  }

  fetchCalendarYears() {
    this.reportsService.getCalendarYears().subscribe((data: any) => {
      console.log('fetchCalendarYears(): data from calendarYear service:', data);
      // data.unshift(this.initItem);
      this.calendarYearList = data;

      let fiscalCalendarYear = this.currentFiscalCalendarYear();
      let currentFiscalYear = `CY${fiscalCalendarYear}`;
      let defaultSelection = data.find(d => d.description === currentFiscalYear);

      if (defaultSelection != undefined) {
        this.currentCalendarYear = defaultSelection.id;
        this.filterCalendarList(this.currentCalendarYear);
      }
    });
  }

  currentFiscalCalendarYear() {
    var today = new Date();
    var month = today.getMonth() + 1;
    var year = today.getFullYear();

    return month > 6 ? year + 1 : year;
  }

  fetchCodes() {
    this.calendarService.getEnrichmentCodes().subscribe((data: any) => {
      console.log('fetchCodes(): data from calendarYear service:', data);
      data.forEach(element => {
        element.active = element.isActive ? 'Yes' : 'No';
      });
      this.enrichmentCodes = data;
      this.filteredCodes = data.filter(a => a.isActive == true);
      // this.loading = false;
      console.log('fetchData(): done');

      // test out the loading bar (todo: remove later)
      const that = this;
      setTimeout(function () {
        that.loading = false;
      }, 1000);
    });
  }

  loadCalendars() {
    this.calendarService
      .calendars()
      .pipe(
        map((calendars) => {
          return this.parseCalendar(calendars);
        })
      )
      .subscribe((calendars) => {
        this.calendars = calendars;
        //this.filteredCalendars = calendars;
        console.log('here---', this.filteredCalendars);
        this.updateTable({ detail: { value: this.type } });
      });
    this.calendarService.exceptions().subscribe((exceptions) => {
      this.exceptions = exceptions;
      this.filteredExceptions = exceptions;
    });
    if (this.ud.hasRoles([4])) {
      this.calendarService.studentAttendanceExceptions().subscribe((exceptions) => {
        this.studentExceptions = exceptions;
        this.studentExceptions = this.studentExceptions.filter(x => x.isActive);
        this.filteredStudentExceptions = exceptions;
        this.filteredStudentExceptions = this.filteredStudentExceptions.filter(x => x.isActive);
      });
    }
  }

  checkPermissions() {
    if (this.ud.hasRoles([1, 2, 3, 4, 5])) {
      this.type = 'Core';
    } else {
      this.type = 'Supplemental';
    }
  }
  parseCalendar(calendars) {
    return calendars.map((c) => {
      c.type = c.operationalCalendarType === 1 ? 'Core' : 'Supplemental';
      return c;
    });
  }
  async removeCalendar(row: any) {
    event.stopPropagation();
    const alertModal: ModalComponent = {
      body: 'Remove calendar',
      header: 'Calendar',
      cancelText: 'cancel',
      okText: 'Yes',
      model: {}
    };
    const modal = await this.modalController.create({
      component: ModalAlertComponent,
      // backdropDismiss: false,
      componentProps: {
        props: alertModal
      }
    });
    modal.onDidDismiss().then((result) => {
      console.log(result);
      if (result.data === 'cancel' || result.role === 'backdrop') {
        return;
      } else {
        this.calendarService.removeCalendar(row).subscribe(() => {
          this.loadCalendars();
        });
      }


      /*  if (result.data !== 'cancel' || result.data !== undefined) {
          row.isActive = false;

        }*/
    });
    await modal.present();
  }

  async createCertificationType() {
    const modal = await this.modalController.create({
      component: UpdateCertificationTypeComponent,
      componentProps: {
        update: false
      }
    });
    modal.onDidDismiss().then((result) => {
      if (result.data !== 'cancel' && result.data !== undefined) {
        this.staffService.updateCertificationType(result.data, false).add(() => {
          this.fetchCertificationTypes();
        });
      }
    });
    await modal.present();
  }

  async presentPopover(ev: any, row: any) {
    event.stopPropagation();
    const popover = await this.popoverController.create({
      component: PopCertificationTypesComponent,
      cssClass: 'popover-custom-css',
      event: ev,
      translucent: true,
      componentProps: { type: row },
    });
    popover.onDidDismiss().then((result) => {
      if (result.data !== 'cancel' && result.data !== undefined) {
        this.staffService.updateCertificationType(result.data, true).add(() => {
          this.fetchCertificationTypes();
        });
      }
    });
    return await popover.present();
  }

  async presentPopover3(ev: any, row: any) {
    ev.stopPropagation();
    const popover = await this.popoverController.create({
      component: PopRetroActionsComponent,
      cssClass: 'pop-schedule-act',
      event: ev,
      translucent: true,
      componentProps: { attendanceReq: [row] },
    });
    popover.onDidDismiss()
      .then((result) => {
        if (result['data'] == 'submitted') {
          this.fetchAttendanceRetroList();
        }
      });

    return await popover.present();
  }

  async presentPopover4(ev: any, row: any) {
    ev.stopPropagation();
    const popover = await this.popoverController.create({
      component: PopDeleteAttendanceActionsComponent,
      cssClass: 'pop-schedule-act',
      event: ev,
      translucent: true,
      componentProps: { attendanceReq: [row] },
    });
    popover.onDidDismiss()
    .then((result) => {
      if(result['data'] == 'submitted'){
        this.fetchDeleteAttendanceList();
      }
    });

    return await popover.present();
  }


  clearCertSearch() {
    this.queryTextCert = '';
    this.filteredTypes = this.types;
  }

  filterCertifications() {
    const safeQueryText = StringUtil.escapeSpecialCharacters(
      this.queryTextCert.trim().toLowerCase()
    );
    // console.log('filterRows(): safeQueryText: ', safeQueryText);
    this.filteredTypes = this.types.filter((row: any) => {
      if (!safeQueryText) {
        return true;
      }
      for (const filterColumn of this.filterColumnsCert) {
        let colValue = row[filterColumn];
        if (moment.isDate(colValue)) {
          colValue = moment(colValue).format('MM/DD/YYYY');
        }
        // toString() is needed if we want to search on numbers
        if (
          colValue &&
          colValue
            .toString()
            .toLowerCase()
            .match(safeQueryText)
        ) {
          return true;
        }
      }
      return false;
    });
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  async removeStudentException(row: any) {
    event.stopPropagation();
    const alertModal: ModalComponent = {
      body: 'Remove student exception?',
      header: 'Student Attendance Dispute',
      cancelText: 'Cancel',
      okText: 'Yes',
      model: {}

    };
    const modal = await this.modalController.create({
      component: ModalAlertComponent,
      componentProps: {
        props: alertModal
      }
    });
    modal.onDidDismiss().then((result) => {
      if (result.data != 'cancel') {
        row.isActive = false;
        this.calendarService.removeStudentAttendanceException(row).subscribe(() => {
          this.loadCalendars();
        });
      }
    });
    await modal.present();
  }

  selectionChanged(event) {
    console.log('selectionChanged() Started ->>>>>>>>>');
    this.filterCalendarList(event[0].data.id);
  }

  masterCalendarChanged(masterCalendar) {
    console.log('master calendar selectionChanged() Started ->>>>>>>>>');
    console.log(masterCalendar);
    if (this.currentMasterCalendar != masterCalendar.masterCalendarName) {
      this.currentMasterCalendar = masterCalendar.masterCalendarName;
      this.selectedMasterCalendarCode = masterCalendar.masterCalendarCode;
    }
  }

  async saveMasterCalendar() {

    const alert = await this.alertController.create({
      cssClass: 'custom-confirm',
      subHeader: 'Update Master Calendar',
      message: 'Are you sure you want to change the master calendar ?',
      buttons: [
        {
          text: 'No',
          handler: () => {
            alert.dismiss(false);
            return false;
          }
        }, {
          text: 'Yes',
          handler: () => {
            alert.dismiss(true);
            return false;
          }
        }]
    });

    alert.onDidDismiss().then(async (res) => {
      if (res.data) {
        this.reportsService.updateCurrentMasterCalendar(this.selectedMasterCalendarCode);
        this.currentMasterCalendarCode = this.selectedMasterCalendarCode;
      }
    });

    await alert.present();
  }

  filterCalendarList(id) {
    if (this.type === 'Core') {
      this.filteredCalendars = id === 0 ? this.calendars.filter((c) => c.type == this.type) : this.calendars.filter((c) => c.fiscalYearId === id);
    } else {
      this.filteredCalendars = id === 0 ? this.calendars.filter((c) => c.type == this.type) : this.calendars.filter((c) => c.calendarYearId === id);
    }
  }

  updateTable($event) {
    console.log('updateTable()');
    this.type = $event.detail.value;
    this.filterCalendarList(this.type === 'Core' ? this.currentFiscalYear : this.currentCalendarYear);
    //this.filteredCalendars = this.calendars.filter((c) => c.type == $event.detail.value);
  }

  createCalendar(type: string) {
    this.router.navigate(['/master-calendar-editor'], { queryParams: { type: type } });
  }

  sortLocal($event, index) {
    $event.preventDefault();
    $event.stopPropagation();

    this.calendars = this.calendars.slice(0, this.calendars.length);
  }
  async createException(showSchools: boolean, showTitle: boolean, showAgencies: boolean, showStudents: boolean) {
    const modal = await this.modalController.create({
      component: CalendarPeriod,
      componentProps: {
        showSchools: showSchools,
        showTitle: showTitle,
        showAgencies: showAgencies,
        showStudents: showStudents,
      },
    });
    modal.onDidDismiss().then((result) => {
      if (result.data === undefined || result.data === 'cancel') { return; }
      if (result.data.agencyId) {
        const range = {
          startDate: result.data.startDate,
          endDate: result.data.endDate,
          agencyId: result.data.agencyId,
          studentId: result.data.studentId
        };
        this.calendarService.addStudentAttendanceException(range).subscribe(() => {
          this.calendarService.studentAttendanceExceptions().subscribe((exceptions) => {
            this.studentExceptions = exceptions;
            this.studentExceptions = this.studentExceptions.filter(x => x.isActive);
          });
        });
      } else {
        const range = {
          description: result.data.title,
          startDate: result.data.startDate,
          endDate: result.data.endDate,
          schoolIds: result.data.schoolIds
        };
        this.calendarService.addException(range).subscribe(() => {
          this.calendarService.exceptions().subscribe((exceptions) => {
            this.exceptions = exceptions;
            this.filteredExceptions = exceptions;
          });
        });
      }
    });
    modal.present();
  }
  onSelect($event) {
    const calendarId = $event.selected[0].id;
    this.router.navigateByUrl(`/master-calendar-editor/${calendarId}`);
  }

  async onSelectException($event) {
    const exception = $event.selected[0];
    const modal = await this.modalController.create({
      component: CalendarPeriod,
      componentProps: {
        editMode: true,
        entity: exception
      }
    });

  }
  daysDiff(end: Date, start: Date) {
    return m(end).diff(start, 'days');
  }
  ngOnDestroy() {
    console.log('destoy');
  }

  //Missed Attendance
  selectedRequest: any;
  agencyName: any;
  submittedBy: any;
  submissionDate: any;
  responsibleStaff: any;
  schoolName: any;
  retroStudentsCopy: any;
  attendanceRetroId: any;
  retroDetailMasterCalendar: any;
  retroDetailAttendanceDateTime: any;
  retroDetailNotes: any;
  retroApproverNotes: any;
  retroDetailScheduledActivity: any;
  TabToShow = 'RetroList';
  RetroReadOnly: Boolean = true;
  selectedFilter: any;
  isStatusPending:boolean = true;
  isDelAttendanceStatusPending:boolean = true;
  retroDetailStudents: any = [];
  filteredRetroList: any = [];
  retroList: any = [];
  filteredRetroStudentList: any = [];
  studentList: any = [];
  selectedStudentsForApproval: any = [];
  public attendanceApproval: FormGroup;
  schoolId: any = null;
  studentsAttendanceRetro: any = [];
  selectionType = SelectionType.single;
  selectedRetro: any = [];
  retroStatusList: any = ['Pending', 'Approved', 'Custom', 'Rejected', 'Processed', 'Cancelled', 'Failed', 'In Progress'];


  agencyList: any[] = [{agencyName: 'All Agencies', agencyShortCode: 'All Agencies'}];
  schoolList: any[] = [ {schoolName: 'All Schools'}];

  m = m;
  retroFiles: any = [];
  // selectedAgency: any;



  //Delete Attendance
  delAttendanceAgencyName: any;
  delAttendanceSubmittedBy: any;
  delAttendanceSubmissionDate: any;
  delAttendanceResponsibleStaff: any;
  delAttendanceSchoolName: any;
  delAttendanceStudentsCopy: any;
  delAttendanceId: any;
  delAttendanceDetailMasterCalendar: any;
  delAttendanceDetailAttendanceDateTime: any;
  delAttendanceDetailNotes: any;
  delAttendanceApproverNotes: any;
  delAttendanceDetailScheduledActivity: any;
  delAttendanceTabToShow = 'DeleteList';
  delAttendanceReadOnly: Boolean = true;
  selectedDelAttendanceRequest: any;
  selectedDelAttendance: any = [];
  selectedDelAttendanceForApproval: any = [];
  studentsDeleteAttendance: any = [];
  delAttendanceDetailStudents: any = [];
  filteredDeleteAttendanceList: any = [];
  filteredDeleteAttendanceStudentList: any = [];
  delAttendanceList: any = [];
  delAttendanceStatusList: any = ['Pending', 'Approved', 'Rejected', 'In Progress', 'Processed', 'Cancelled'];
  delAttendanceAgencyList: any [] = [{agencyName: 'All Agencies', agencyShortCode: 'All Agencies'}];
  delAttendanceSchoolList: any [] = [{schoolName: 'All Schools'}];
  delAttendanceSelectedDate: any;

  delAttendanceSelectedFilter : any;
  delAttendanceDetailsStudents: any = [];
  delAttendanceFiles: any = [];
  delAttendanceSelectedStudentsForApproval: any = [];
  public delAttendanceApproval: FormGroup;
  filteredDelAttendanceStudentList: any = [];
  delAttendanceStudentList: any = [];

  filterRetroDetailsStudents(ev){
    let query = ev.target.value
    console.log(this.queryText);
    this.retroDetailStudents = this.retroStudentsCopy.filter(f => f.displayLastName.toLowerCase().indexOf(query.toLowerCase()) > -1 || f.displayFirstName.toLowerCase().indexOf(query.toLowerCase()) > -1);
  }

  filterDelAttendanceDetailsStudents(ev){
    let query = ev.target.value
    console.log(this.queryText);
    this.delAttendanceDetailsStudents = this.delAttendanceStudentsCopy.filter(f => f.displayLastName.toLowerCase().indexOf(query.toLowerCase()) > -1 || f.displayFirstName.toLowerCase().indexOf(query.toLowerCase()) > -1 );
  }

  clearRetroStudentSearch() {
    this.queryText = '';
    this.filteredRetroStudentList = this.studentList;
    this.retroDetailStudents = this.retroStudentsCopy;
  }

  clearDelAttendanceStudentSearch() {
    this.queryText = '';
    this.filteredDelAttendanceStudentList = this.delAttendanceStudentList;
    this.delAttendanceDetailsStudents = this.delAttendanceStudentsCopy;
  }

  getStudents(attendance) {
    this.studentsService.getStudentsForRetro(attendance.id).subscribe((data) => {
      this.retroDetailStudents = data;
      _.each(this.retroDetailStudents, s => {
        s.fullName = `${s.displayName}`
      })
      this.retroStudentsCopy = this.retroDetailStudents;

      this.approveAttendance(attendance, this.retroDetailStudents)
    })

  }

  async approveAttendance(attendance, students) {

    let studentList: any = [];
    this.loading = true;
    const attendanceRetro = {
      attendanceRetroId: attendance.id,
      approverNotes: '',
      status: 'Approved',
      approvedStudents: studentList
    };


    const modal = await this.modalController.create({
      component: SubmitAttendanceConfirmationComponent,
      cssClass: 'my-custom-modal-css-small',
      componentProps: {
        title: `Are you sure?`,
        text: `You are about to Approve a request for ${attendance.attendanceTime} for ${attendance.schoolName} by ${attendance.agencyName} with ${students.length} student(s).`,
      },
    });
    modal.onDidDismiss().then((response) => {
      if (response.role == 'confirm') {
        this.studentsService.approveAttenanceRetro(attendanceRetro).subscribe((data: any) => {
          this.fetchAttendanceRetroList();
          this.TabToShow = 'RetroList';
        });
      } else {
        return
      }
    });

    return await modal.present();
  }


  async approveRetroAttendances(approveType) {
    let status;
    let studentList: any = [];


    if (approveType == 'reject') {
      status = "Rejected";
      studentList = [];
    }
    else if (approveType == 'approve_all') {
      status = "Approved";
      studentList = this.retroDetailStudents.map(s => s.id);
    }
    else {
      status = "Custom";
      studentList = this.selectedStudentsForApproval;
    }

    let approverNote = this.attendanceApproval.controls.approverNotes.value;
    this.loading = true;
    const attendanceRetro = {
      attendanceRetroId: this.attendanceRetroId,
      approverNotes: approverNote,
      status: status,
      approvedStudents: studentList
    };

    const isReject = approveType == 'reject';
    const type = isReject ? 'reject' : 'approve';
    const selectedStudentCount = this.selectedStudentsForApproval.length > 0 ? this.selectedStudentsForApproval.length : this.retroDetailStudents.length;
    const studentCount = this.studentList.length > 0 ? this.studentList.length : selectedStudentCount;
    const modal = await this.modalController.create({
      component: SubmitAttendanceConfirmationComponent,
      cssClass: 'my-custom-modal-css-small',
      componentProps: {
        title: `Are you sure?`,
        text: `You are about to ${type} a request for ${this.retroDetailAttendanceDateTime} for ${this.schoolName} by ${this.agencyName} with ${studentCount} student(s).`,
        reject: isReject
      },
    });
    modal.onDidDismiss().then((response) => {
      if (response.role == 'confirm') {
        this.studentsService.approveAttenanceRetro(attendanceRetro).subscribe((data: any) => {
          this.clearRetroStudentSearch();
          this.attendanceApproval = this.formBuilder.group({
            students: new FormArray([]),
            approverNotes: ['']
          });

          this.fetchAttendanceRetroList();
          this.TabToShow = 'RetroList';
        });
      } else {
        return
      }
    });

    return await modal.present();

  }

  async approveDelAttendances(approveType)
  {
    let status;
    let delAttendanceStudentList: any = [];


    if(approveType == 'reject'){
      status = "Rejected";
      delAttendanceStudentList = [];
    }
    else if(approveType == 'approve_all'){
      status = "Approved";
      delAttendanceStudentList = this.delAttendanceDetailsStudents.map(s => s.id);
    }
    else{
      status = "Custom";
      delAttendanceStudentList = this.delAttendanceSelectedStudentsForApproval;
    }

    let approverNote = this.delAttendanceApproval.controls.approverNotes.value;
    this.loading = true;
    const delAttendance = {
      deleteAttendanceId: this.delAttendanceId,
      approverNotes: approverNote,
      status: status,
      approvedStudents: delAttendanceStudentList
    };

    const isReject = approveType == 'reject';
    const type = isReject ? 'reject' : 'approve';
    const selectedStudentCount = this.delAttendanceSelectedStudentsForApproval.length > 0 ? this.delAttendanceSelectedStudentsForApproval.length : this.delAttendanceDetailsStudents.length;
    const studentCount = this.delAttendanceStudentList.length > 0? this.delAttendanceStudentList.length : selectedStudentCount;
    const modal = await this.modalController.create({
      component: SubmitAttendanceConfirmationComponent,
      cssClass: 'my-custom-modal-css-small',
      componentProps: {
        title: `Are you sure?`,
        text: `You are about to ${type} a request for ${this.delAttendanceDetailAttendanceDateTime} for ${this.delAttendanceSchoolName} by ${this.delAttendanceAgencyName} with ${studentCount} student(s).`,
        reject: isReject
      },
    });
    modal.onDidDismiss().then((response) => {
      if(response.role == 'confirm'){
        this.studentsService.approveDelAttendance(delAttendance).subscribe((data: any) => {
          this.clearDelAttendanceStudentSearch();
          this.delAttendanceApproval = this.formBuilder.group({
            students: new FormArray([]),
            approverNotes: ['']
          });

          this.fetchDeleteAttendanceList();
          this.delAttendanceTabToShow ='DeleteList';
        });
      } else {
        return
      }
    });

    return await modal.present();

  }

  selectStudentToApprove(event, id){
    console.log(event);
    const formArray: FormArray = this.attendanceApproval.get('students') as FormArray;

    if (event.target.checked) {
      formArray.push(new FormControl(event.target.value));
      if (!this.selectedStudentsForApproval.includes(id))
        this.selectedStudentsForApproval.push(id);
    } else {
      let idx = this.selectedStudentsForApproval.indexOf(id);
      this.selectedStudentsForApproval.splice(idx, 1);

      let i: number = 0;
      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  selectDelAttendanceStudentToApprove(event, id){
    console.log(event);
    const formArray: FormArray = this.delAttendanceApproval.get('students') as FormArray;

    if(event.target.checked){
      formArray.push(new FormControl(event.target.value));
      if(!this.delAttendanceSelectedStudentsForApproval.includes(id))
        this.delAttendanceSelectedStudentsForApproval.push(id);
    } else{
      let idx = this.delAttendanceSelectedStudentsForApproval.indexOf(id);
      this.delAttendanceSelectedStudentsForApproval.splice(idx, 1);

      let i: number = 0;
      formArray.controls.forEach((ctrl: FormControl) => {
        if(ctrl.value == event.target.value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  downloadFile(file){
    window.open(file, "_blank");
  }

  async CloseRetroDetails() {
    this.TabToShow = 'RetroList';
    this.attendanceRetroId = undefined;
  }

  async CloseDelAttendanceDetails(){
    this.delAttendanceTabToShow ='DeleteList';
    this.delAttendanceId = undefined;
  }

  fetchAttendanceRetroList() {
    this.loading = true;

    console.log(this.schoolId, 'school id==========')



    this.schoolDetailsService
      .getAttendanceRetroSummaryByDateRange(this.selectedFilter
        , this.selectedStartDate ? `${this.selectedStartDate.getFullYear()}-${this.selectedStartDate.getMonth() + 1}-${this.selectedStartDate.getDate()}` : "1900-01-01"
        , this.selectedEndDate ? `${this.selectedEndDate.getFullYear()}-${this.selectedEndDate.getMonth() + 1}-${this.selectedEndDate.getDate()}` : "1900-01-01")
      .subscribe((data: any) => {
        console.log('fetchAttendanceRetroList(): data from service:', data);
        this.retroList = data;
        this.filteredRetroList = data;

        if (!this.selectedStartDate && !this.selectedEndDate) {
          let minAttendanceDate: Date = new Date(2023, 0, 1);

          let currentDate: Date = new Date();

          let days: Number = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(minAttendanceDate.getFullYear(), minAttendanceDate.getMonth(), minAttendanceDate.getDate())) / (1000 * 60 * 60 * 24)) ?
            Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(minAttendanceDate.getFullYear(), minAttendanceDate.getMonth(), minAttendanceDate.getDate())) / (1000 * 60 * 60 * 24)) : 0;

          this.minAttendanceDateRange.setDate(this.minAttendanceDateRange.getDate());

          if (Number(days) > 180) {
            let maxDays = Number(days) - 180;
            this.maxAttendanceDateRange.setDate(this.maxAttendanceDateRange.getDate() - Number(maxDays))
          }
        }

        //   else{}
        // this.maxAttendanceDateRange.setDate(Number(days) > 180 ? minAttendanceDate.getDate()+ 180 : this.maxAttendanceDateRange.getDate())

        // const  minattendanceTimes = new Date(Math.min(...))

        if (this.selectedFilter == 'Pending')
          this.limitCnt = 100;  //Show 100 records for Pending cases
        else
          this.limitCnt = 10;   //Show 10 records for all other cases

        this.applyRetroListFilter();
        // this.getAgencyList(data);
        // this.getSchoolList(data);
        // if(this.selectedFilter == null || this.selectedFilter == undefined){
        //   this.selectedFilter = 'Pending';
        // }
        const that = this;
        setTimeout(function () {
          that.loading = false;
        }, 300);
      });
  }

  fetchDeleteAttendanceList() {
    this.loading = true;

    console.log(this.schoolId, 'school id==========')
    this.schoolDetailsService
      .getDeleteAttendanceSummaryByDateRange(this.delAttendanceSelectedFilter
        , this.selectedDeleteAttendanceStartDate ? `${this.selectedDeleteAttendanceStartDate.getFullYear()}-${this.selectedDeleteAttendanceStartDate.getMonth() + 1}-${this.selectedDeleteAttendanceStartDate.getDate()}` : "1900-01-01"
        , this.selectedDeleteAttendanceEndDate ? `${this.selectedDeleteAttendanceEndDate.getFullYear()}-${this.selectedDeleteAttendanceEndDate.getMonth() + 1}-${this.selectedDeleteAttendanceEndDate.getDate()}` : "1900-01-01")
      .subscribe((data: any) => {
        console.log('fetchDeleteAttendanceList(): data from service:', data);
        this.delAttendanceList = data;
        this.filteredDeleteAttendanceList = data;  

        if (!this.selectedDeleteAttendanceStartDate && !this.selectedDeleteAttendanceEndDate) {
          let minAttendanceDate: Date = new Date(2023, 0, 1);

          let currentDate: Date = new Date();

          let days: Number = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(minAttendanceDate.getFullYear(), minAttendanceDate.getMonth(), minAttendanceDate.getDate())) / (1000 * 60 * 60 * 24)) ?
            Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(minAttendanceDate.getFullYear(), minAttendanceDate.getMonth(), minAttendanceDate.getDate())) / (1000 * 60 * 60 * 24)) : 0;

          this.minDeleteAttendanceDateRange.setDate(this.minDeleteAttendanceDateRange.getDate());

          if (Number(days) > 180) {
            let maxDays = Number(days) - 180;
            this.maxDeleteAttendanceDateRange.setDate(this.maxDeleteAttendanceDateRange.getDate() - Number(maxDays))
          }
        }

          if (this.delAttendanceSelectedFilter == 'Pending')
          this.limitCnt = 100;  //Show 100 records for Pending cases
        else
          this.limitCnt = 10;   //Show 10 records for all other cases

        this.applyDeleteAttendanceListFilter();

        const that = this;
        setTimeout(function () {
          that.loading = false;
        }, 300);
      });
  }

  getAgencyList(requests){
    let list = [
      {agencyName: 'All Agencies', agencyShortCode: 'All Agencies'}
    ]
    _.each(requests, r => {
      list.push({
        agencyName: r.agencyName,
        agencyShortCode: r.agencyShortCode
      })
    })

    list = _.uniqBy(list, 'agencyShortCode');
    this.agencyList = list;
  }

  getSchoolList(requests) {
    let list = [
      {schoolName: 'All Schools'}
    ]
    _.each(requests, r => {
      list.push({
        schoolName: r.schoolName,
      })
    })

    list = _.uniqBy(list, 'schoolName');
    this.schoolList = list;
  }

  getDelAttendanceAgencyList(requests){
    let list = [
      {agencyName: 'All Agencies', agencyShortCode: 'All Agencies'}
    ]
    _.each(requests, r => {
      list.push({
        agencyName: r.agencyName,
        agencyShortCode: r.agencyShortCode
      })
    })

    list = _.uniqBy(list, 'agencyShortCode');
    this.delAttendanceAgencyList = list;
  }

  getDelAttendanceSchoolList(requests){
    let list = [
      {schoolName: 'All Schools'}
    ]
    _.each(requests, r => {
      list.push({
        schoolName: r.schoolName,
      })
    })

    list = _.uniqBy(list, 'schoolName');
    this.delAttendanceSchoolList = list;
  }

  async showRetroDetails(event) {
    //event.event.stopPropagation();
    // collects activity's ID
    // if(activateEvent.type != 'click'){
    //   return;
    // }
    // let event = activateEvent.row;
    console.log(event)
    this.selectedRequest = event.selected[0];
    //this.retroFiles = event.selected[0].files;
    this.schoolName = event.selected[0].schoolName;
    this.agencyName = event.selected[0].agencyName;
    this.submittedBy = event.selected[0].createdBy;
    this.submissionDate = event.selected[0].createdOn;
    this.responsibleStaff = event.selected[0].staffName;
    const id = event.selected[0].id;
    this.attendanceRetroId = id;
    this.retroDetailAttendanceDateTime = event.selected[0].attendanceTime;
    this.retroDetailMasterCalendar = event.selected[0].masterCalendar;
    this.retroDetailNotes = event.selected[0].notes;
    this.retroApproverNotes = event.selected[0].approverNotes;
    this.retroDetailScheduledActivity = event.selected[0].scheduledActivityName;
    let status = event.selected[0].status;
    if (status != 'Pending' || (this.profile.roles.includes(6) || this.profile.roles.includes(7) || this.profile.roles.includes(8))) {
      this.TabToShow = 'RetroApproval';
      this.RetroReadOnly = true;
    }
    else {
      this.TabToShow = 'RetroApproval';
      this.RetroReadOnly = false;
    }

    this.studentsService.getStudentsForRetro(id).subscribe((data) => {
      this.retroDetailStudents = data;
      _.each(this.retroDetailStudents, s => {
        s.fullName = `${s.displayName}`
      })
      this.retroStudentsCopy = this.retroDetailStudents;
    })

    this.studentsService.getRetroFiles(id).subscribe((data) => {
      console.log(data);
      this.retroFiles = data;
    })
  }

  async showDelAttendanceDetails(event) {
    console.log(event)
    this.selectedDelAttendanceRequest = event.selected[0];
    this.delAttendanceSchoolName = event.selected[0].schoolName;
    this.delAttendanceAgencyName = event.selected[0].agencyName;
    this.delAttendanceSubmittedBy = event.selected[0].createdBy;
    this.delAttendanceSubmissionDate = event.selected[0].createdOn;
    this.delAttendanceResponsibleStaff = event.selected[0].staffName;
    const id = event.selected[0].id;
    this.delAttendanceId = id;
    this.delAttendanceDetailAttendanceDateTime = event.selected[0].attendanceTime;
    this.delAttendanceDetailMasterCalendar = event.selected[0].masterCalendar;
    this.delAttendanceDetailNotes = event.selected[0].notes;
    this.delAttendanceApproverNotes = event.selected[0].approverNotes;
    this.delAttendanceDetailScheduledActivity = event.selected[0].scheduledActivityName;
    let status = event.selected[0].status;
    if (status != 'Pending' || (this.profile.roles.includes(6) || this.profile.roles.includes(7) || this.profile.roles.includes(8))) {
      this.delAttendanceTabToShow = 'DeleteAttendanceDetails';
      this.delAttendanceReadOnly = true;
    }
    else {
      this.delAttendanceTabToShow = 'DeleteAttendanceApproval';
      this.delAttendanceReadOnly = false;
    }

    this.studentsService.getStudentsForDelAttendance(id).subscribe((data) => {
      this.delAttendanceDetailsStudents = data;
      _.each(this.delAttendanceDetailsStudents, s => {
        s.fullName = `${s.displayName}`
      })
      this.delAttendanceStudentsCopy = this.delAttendanceDetailsStudents;
    })

    this.studentsService.getDelAttendanceFiles(id).subscribe((data) => {
      console.log(data);
      this.delAttendanceFiles = data;
    })
  }

  onAttendanceStatusFilterChange(event) {
    // this.selectedDate = '';
    // this.selectedAgency = '';
    // this.selectedSchool = '';

    this.fetchAttendanceRetroList();
    this.isStatusPending = this.selectedFilter =='Pending'?true:false;


    // this.applyRetroListFilter();
    // if(this.selectedAgency)
    // {
    //   this.filteredRetroList = this.filteredRetroList.filter(r => r.agencyShortCode == this.selectedAgency);
    // }

    // if(this.selectedSchool)
    // {
    //   this.filteredRetroList = this.filteredRetroList.filter(r =>  r.schoolName == this.selectedSchool);
    // }

    // if(this.selectedDate)
    // {
    //   const fromDate = m(this.selectedDate[0]);
    //     const toDate = m(this.selectedDate[1]);
    //     this.filteredRetroList = this.filteredRetroList.filter(r => m(r.attendanceTime.substring(0, 10)).isBetween(fromDate, toDate) );
    // }
  }

  onDeleteAttendanceStatusFilterChange(event) {
    // this.selectedDate = '';
    // this.selectedAgency = '';
    // this.selectedSchool = '';

    this.fetchDeleteAttendanceList();
    this.isDelAttendanceStatusPending = this.delAttendanceSelectedFilter =='Pending'?true:false;
  }

  onDelAttendanceStatusFilterChange(event){
    this.delAttendanceSelectedDate = '';
    // this.delAttendanceSelectedAgency = '';
    // this.delAttendanceSelectedSchool = '';
    this.fetchDeleteAttendanceList();
  }

  // onAgencyFilterChange(ev) {
  //   if (ev == 'all') {
  //     this.selectedSchool = '';
  //     this.getSchoolList(this.retroList);
  //     this.filteredRetroList = this.retroList;
  //     this.selectedAgency = undefined;
  //   } else {
  //     this.selectedAgency = ev;
  //     this.selectedSchool = '';
  //     this.filteredRetroList = this.retroList.filter(r => r.agencyShortCode == ev);
  //     this.getSchoolList(this.filteredRetroList);
  //   }
  //   // if(this.selectedFilter)
  //   // {
  //   //   this.filteredRetroList = this.filteredRetroList.filter(r => r.status == this.selectedFilter);
  //   // }

  //   // if(this.selectedSchool)
  //   // {
  //   //   this.filteredRetroList = this.filteredRetroList.filter(r =>  r.schoolName == this.selectedSchool);
  //   // }

  //   // if(this.selectedDate)
  //   // {
  //   //   const fromDate = m(this.selectedDate[0]);
  //   //     const toDate = m(this.selectedDate[1]);
  //   //     this.filteredRetroList = this.filteredRetroList.filter(r => m(r.attendanceTime.substring(0, 10)).isBetween(fromDate, toDate) );
  //   // }
  //   this.applyRetroListFilter();

  // }

  // }

  onDelAttendaceAgencyFilterChange(ev) {
    if(ev == 'All Agencies') {
      // this.delAttendanceSelectedSchool = '';
      this.getDelAttendanceSchoolList(this.delAttendanceList);
      this.filteredDeleteAttendanceList = this.delAttendanceList;
      this.delAttendanceSelectedAgency = undefined;
    } else
    {
      this.delAttendanceSelectedAgency = ev;
      // this.delAttendanceSelectedSchool = '';
      this.filteredDeleteAttendanceList = this.delAttendanceList.filter(d => d.agencyShortCode == ev);
      this.getDelAttendanceSchoolList(this.filteredDeleteAttendanceList);
    }
      this.applyDeleteAttendanceListFilter();

  }

  onSchoolFilterChange(ev) {
    if (ev == 'All Schools') {
      this.filteredRetroList = this.retroList;
      this.selectedSchool = undefined
    } else {
      this.selectedSchool = ev;
      this.filteredRetroList = this.retroList.filter(r => r.schoolName == ev)
    }
    this.applyRetroListFilter();

    // if(this.selectedFilter)
    // {
    //   this.filteredRetroList = this.filteredRetroList.filter(r => r.status == this.selectedFilter);
    // }

    // if(this.selectedAgency)
    // {
    //   this.filteredRetroList = this.filteredRetroList.filter(r => r.agencyShortCode == this.selectedAgency );
    // }

    // if(this.selectedDate)
    // {
    //   const fromDate = m(this.selectedDate[0]);
    //     const toDate = m(this.selectedDate[1]);
    //     this.filteredRetroList = this.filteredRetroList.filter(r => m(r.attendanceTime.substring(0, 10)).isBetween(fromDate, toDate) );
    // }
  }

  onDelAttendanceSchoolFilterChange(ev) {
    if(ev == 'All Schools') {
       this.filteredDeleteAttendanceList = this.delAttendanceList;
       this.delAttendanceSelectedSchool = undefined
     } else {
       this.delAttendanceSelectedSchool = ev;
       this.filteredDeleteAttendanceList = this.delAttendanceList.filter(d => d.schoolName == ev)
     }
     this.applyDeleteAttendanceListFilter();
  }

  onDateRangeUpdate(ev) {
    if (ev != null) {
      this.selectedDate = ev;
      const fromDate = m(ev[0]);
      const toDate = m(ev[1]);

    }
    // else
    // {
    //   this.selectedDate = ev;
    //   const fromDate = m(ev[0]);
    //   const toDate = m(ev[1]);
    //   this.filteredRetroList = this.retroList.filter(r => m(r.attendanceTime.substring(0, 10)).isBetween(fromDate, toDate));
    // }
    // this.applyRetroListFilter();
    // if(this.selectedFilter)
    // {
    //   this.filteredRetroList = this.filteredRetroList.filter(r => r.status == this.selectedFilter);
    // }

    // if(this.selectedAgency)
    // {
    //   this.filteredRetroList = this.filteredRetroList.filter(r => r.agencyShortCode == this.selectedAgency );
    // }

    // if(this.selectedSchool)
    // {
    //   this.filteredRetroList = this.filteredRetroList.filter(r =>  r.schoolName == this.selectedSchool);
    // }
  }

  onDelAttendanceDateRangeUpdate(ev){
    if(ev == null)
    {
      this.filteredDeleteAttendanceList = this.delAttendanceList;
      this.delAttendanceSelectedDate = '';
      // this.delAttendanceSelectedAgency = '';
      // this.delAttendanceSelectedSchool = '';
      this.delAttendanceSelectedFilter = 'Pending';
    }
    else
    {
      this.delAttendanceSelectedDate = ev;
      const fromDate = m(ev[0]);
      const toDate = m(ev[1]);
      this.filteredDeleteAttendanceList = this.delAttendanceList.filter(d => m(d.attendanceTime.substring(0, 10)).isBetween(fromDate, toDate));
    }
    this.applyDeleteAttendanceListFilter();
  }

  applyRetroListFilter(){
    this.noOfAttendanceCount = 0;
    this.noOfSelectedRetroCount = 0;
    this.selectedRetrosForApproval = [];

    // if (this.selectedFilter) {
    //   this.filteredRetroList = this.filteredRetroList.filter(r => r.status == this.selectedFilter);
    // }

    if (this.selectedAgency && this.selectedAgency.length > 0 && !this.selectedAgency.includes('All Agencies')) {
      this.filteredRetroList = this.filteredRetroList.filter(r => this.selectedAgency.includes(r.agencyShortCode));
    }

    if (this.selectedSchool && this.selectedSchool.length > 0 && !this.selectedSchool.includes('All Schools')) {
      this.filteredRetroList = this.filteredRetroList.filter(r => this.selectedSchool.includes(r.schoolName));
    }   

    // if (this.selectedDate) {
    //   const fromDate = m(this.selectedDate[0]);
    //   const toDate = m(this.selectedDate[1]);
    //   this.filteredRetroList = this.filteredRetroList.filter(r => m(r.attendanceTime.substring(0, 10)).isBetween(fromDate, toDate));
    // }





      this.deSelectAllRetros();
    
  }

  onAgencyChange(event:any)
  {
   if (event == 'All Agencies')
   {
     this.selectedAgency = this.agencyList.map(x=>
      {return x.agencyShortCode});
   }
   else{
    this.selectedAgency = this.selectedAgency.filter((x:any) =>x!='All Agencies') 
   }
  }

  onDeleteAttendanceAgencyChange(event:any)
  {
   if (event == 'All Agencies')
   {
     this.delAttendanceSelectedAgency = this.delAttendanceAgencyList.map(x=>
      {return x.agencyShortCode});
   }
   else{
    this.delAttendanceSelectedAgency = this.delAttendanceSelectedAgency.filter((x:any) =>x!='All Agencies') 
   }
  }

  onSchollChange(event:any)
  {
   if (event == 'All Schools')
   {
     this.selectedSchool = this.schoolList.map(x=>
      {return x.schoolName});
   }
   else{
    this.selectedSchool = this.selectedSchool.filter((x:any) =>x!='All Schools')
   }
  }

  onDeleteAtendanceSchollChange(event:any)
  {
   if (event == 'All Schools')
   {
     this.delAttendanceSelectedSchool = this.delAttendanceSchoolList.map(x=>
      {return x.schoolName});
   }
   else{
    this.delAttendanceSelectedSchool = this.delAttendanceSelectedSchool.filter((x:any) =>x!='All Schools')
   }
  }

  applyDeleteAttendanceListFilter(){
    this.noOfDelAttendanceCount = 0;
    this.noOfDelAttendanceSelectedCount = 0;
    this.selectedDelAttendanceForApproval = [];


    if (this.delAttendanceSelectedAgency && this.delAttendanceSelectedAgency.length > 0 && !this.delAttendanceSelectedAgency.includes('All Agencies')) {
      this.filteredDeleteAttendanceList = this.filteredDeleteAttendanceList.filter(r => this.delAttendanceSelectedAgency.includes(r.agencyShortCode));
    }

    if (this.delAttendanceSelectedSchool && this.delAttendanceSelectedSchool.length > 0 && !this.delAttendanceSelectedSchool.includes('All Schools')) {
      this.filteredDeleteAttendanceList = this.filteredDeleteAttendanceList.filter(r => this.delAttendanceSelectedSchool.includes(r.schoolName));
    }  

      
      this.deSelectAllDelAttendances();
  }


  cancelRetroApproval() {
    this.clearRetroStudentSearch();
    this.attendanceApproval = this.formBuilder.group({
      students: new FormArray([]),
      approverNotes: ['']
    });
    this.TabToShow = 'RetroList';
  }

  cancelDelAttendanceApproval(){
    this.clearDelAttendanceStudentSearch();
    this.delAttendanceApproval = this.formBuilder.group({
      students: new FormArray([]),
      approverNotes: ['']
    });
    this.delAttendanceTabToShow ='DeleteList';
  }


  async cancelRetroRequest(req) {
    console.log(req, 'the retro request to remove')

    const modal = await this.modalController.create({
      component: SubmitAttendanceConfirmationComponent,
      cssClass: 'my-custom-modal-css-small',
      componentProps: {
        title: 'Cancel Missing Attendance Request?',
        text: 'This action cannot be undone'
      },
    });
    modal.onDidDismiss().then((response) => {
      if (response.role == 'confirm') {
        //api: cancelRetroRequest() goes here
      } else {
        return
      }
    });

    return await modal.present();
  }

  async cancelDelAttendanceRequest(req){
    console.log(req, 'the delete attendances request to remove')

    const modal = await this.modalController.create({
      component: SubmitAttendanceConfirmationComponent,
      cssClass: 'my-custom-modal-css-small',
      componentProps: {
        title: 'Cancel Delete Attendance Request?',
        text: 'This action cannot be undone'
      },
    });
    modal.onDidDismiss().then((response) => {
      if(response.role == 'confirm'){
        //api: cancelRetroRequest() goes here
      } else {
        return
      }
    });

    return await modal.present();
  }
  
  get profile() {
    return JSON.parse(
      window.localStorage.getItem('_ionicstorage/_ionickv/userSubject')
    );
  }

  fetchAttendanceRetro(mastercalendarcode) {
    this.studentsService.getStudentsBAttendanceRetro(Number(this.schoolId), mastercalendarcode)
      .subscribe((data: any) => {
        console.log('getStudentsBAttendanceRetro(): data from service:', data);
        this.studentsAttendanceRetro = data;
        const that = this;
        setTimeout(function () {
          that.loading = false;
        }, 300);
      });
  }

  fetchDelAttendance(mastercalendarcode){
    this.studentsService.getStudentsByDeleteAttendance(Number(this.schoolId), mastercalendarcode)
    .subscribe((data: any) => {
      console.log('getStudentsByDeleteAttendance(): data from service:', data);
      this.studentsAttendanceRetro = data;
      const that = this;
      setTimeout(function () {
        that.loading = false;
      }, 300);
    });
  }


  ShowHide() {
    if (this.limitCnt != 10) {
      this.limitCnt = 10;
    } else { this.limitCnt = 10; }
  }

  selectAllStudents(event){
    event.stopPropagation();
  }

  selectAllDelAttendanceStudents(event){
    event.stopPropagation();
  }

  onRetroClick(event){
    event.stopPropagation();
  }

  onDelAttendanceClick(event){
    event.stopPropagation();
  }

  retroSelected(event: any, retro){
    event.stopPropagation();
    this.updateRetroStats();
    // console.log(event);
    // const formArray: FormArray = this.retroMultiApprovalForm.get('retros') as FormArray;
    // let id = retro.id;
    // let studentCount = retro.attendanceCount;

    // if(event.target.checked){
    //   formArray.push(new FormControl(event.target.value));
    //   this.selectedRetrosForApproval.push(id);
    //   this.noOfAttendanceCount += studentCount;
    // } else{
    //   this.selectedRetrosForApproval = this.selectedRetrosForApproval.filter(r => r != id);

    //   this.noOfAttendanceCount -= studentCount;
    //   let i: number = 0;
    //   formArray.controls.forEach((ctrl: FormControl) => {
    //     if(ctrl.value == event.target.value) {
    //       formArray.removeAt(i);
    //       return;
    //     }
    //     i++;
    //   });
    // }

    // this.noOfSelectedRetroCount = this.selectedRetrosForApproval.length;
  }

  delAttendanceSelected(event: any, delAttendance){
    event.stopPropagation();
    this.updateDelAttendanceStats();
  }

  updateRetroStats(){
    this.selectedRetrosForApproval = [];
    this.noOfAttendanceCount = 0;
    this.noOfSelectedRetroCount = 0;
    this.filteredRetroList.forEach(retro => {
      if (retro.selected) {
        this.selectedRetrosForApproval.push(retro.id)
        this.noOfAttendanceCount += retro.attendanceCount;
        this.noOfSelectedRetroCount += 1;
      }
    });
  }

  updateDelAttendanceStats(){
    this.selectedDelAttendanceForApproval = [];
    this.noOfDelAttendanceCount = 0;
    this.noOfDelAttendanceSelectedCount = 0;
    this.filteredDeleteAttendanceList.forEach(delAttendance => {
      if(delAttendance.selected){
        this.selectedDelAttendanceForApproval.push(delAttendance.id)
        this.noOfDelAttendanceCount += delAttendance.attendanceCount;
        this.noOfDelAttendanceSelectedCount += 1;
      }
    });
  }

  selectAllRetros(){
    //event.stopPropagation();

    let attendanceCount = 0;
    let retroCount = 0;
    this.selectedRetrosForApproval = [];

    this.filteredRetroList.forEach(retro => {
      if (retro.status == 'Pending') {
        // this.selectedRetrosForApproval.push(retro.id);
        // attendanceCount += retro.attendanceCount;
        // retroCount += 1;
        retro.selected = true;
      }
    });

    // this.noOfAttendanceCount = attendanceCount;
    // this.noOfSelectedRetroCount = retroCount;
  }

  selectAllDelAttendance(){
    this.selectedDelAttendanceForApproval = [];

    this.filteredDeleteAttendanceList.forEach(delAttendance => {
      if(delAttendance.status == 'Pending'){
        delAttendance.selected = true;
      }
    });
  }

  deSelectAllRetros(){

    // this.noOfAttendanceCount = 0;
    // this.noOfSelectedRetroCount = 0;
    this.selectedRetrosForApproval = [];

    this.filteredRetroList.forEach(retro => {
      if (retro.status == 'Pending') {
        retro.selected = false;
      }
    });
  }

  deSelectAllDelAttendances(){
    this.selectedRetrosForApproval = [];
    this.filteredDeleteAttendanceList.forEach(delAttendance => {
      if(delAttendance.status == 'Pending'){
        delAttendance.selected = false;
      }
    });
  }

  showHideSelectAllRetro(){
    return this.filteredRetroList.filter(f => f.status == 'Pending').length == this.selectedRetrosForApproval.length;
  }

  pendingRetroCount() {
    return this.filteredRetroList.filter(f => f.status == 'Pending').length;
  }

  showHideSelectAllDelAttendance(){
    return this.filteredDeleteAttendanceList.filter(f => f.status == 'Pending').length == this.selectedDelAttendanceForApproval.length;
  }

  pendingDelAttendanceCount(){
    return this.filteredDeleteAttendanceList.filter(f => f.status == 'Pending').length;
  }

  async approveSelectedRetros(){
    this.loading = true;
    let studentList: any = [];
    const multiRetroApproval = {
      retroIds: this.selectedRetrosForApproval,
      approverNotes: 'Bulk Approval',
      status: 'Approved',
    };

    const modal = await this.modalController.create({
      component: SubmitAttendanceConfirmationComponent,
      cssClass: 'my-custom-modal-css-small',
      componentProps: {
        title: `Are you sure?`,
        text: `You are about to Approve ${this.selectedRetrosForApproval.length} retro requests.`,
      },
    });
    modal.onDidDismiss().then((response) => {
      if (response.role == 'confirm') {
        this.studentsService.approveMultiAttenanceRetro(multiRetroApproval).subscribe((data: any) => {
          this.fetchAttendanceRetroList();
          this.TabToShow = 'RetroList';
        });
      } else {
        this.loading = false;
        return
      }
    });

    return await modal.present();
  }

  onMinDateChange(event) {

    if (event) {
      this.selectedStartDate = event;
      this.minAttendanceEndDateRange = event;

      let selectedDate: Date = new Date(this.selectedStartDate);
      selectedDate.setDate(selectedDate.getDate() + 180);

      var currentDate: Date = new Date();

      let days: Number = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(this.selectedStartDate.getFullYear(), this.selectedStartDate.getMonth(), this.selectedStartDate.getDate())) / (1000 * 60 * 60 * 24)) ?
        Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(this.selectedStartDate.getFullYear(), this.selectedStartDate.getMonth(), this.selectedStartDate.getDate())) / (1000 * 60 * 60 * 24)) : 0;

      if (Number(days) > 180) {
        this.selectedEndDate = "";
        this.maxAttendanceDateRange = new Date();
        this.maxAttendanceDateRange = selectedDate;
      }
      else {
        this.selectedEndDate = "";
        this.maxAttendanceDateRange = new Date();
        this.maxAttendanceDateRange = currentDate;
      }

    }
  }
  onDeleteAttendanceMinDateChange(event) {

    if (event) {
      this.selectedDeleteAttendanceStartDate = event;
      this.minDeleteAttendanceEndDateRange = event;

      let selectedDate: Date = new Date(this.selectedDeleteAttendanceStartDate);
      selectedDate.setDate(selectedDate.getDate() + 180);

      var currentDate: Date = new Date();

      let days: Number = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(this.selectedDeleteAttendanceStartDate.getFullYear(), this.selectedDeleteAttendanceStartDate.getMonth(), this.selectedDeleteAttendanceStartDate.getDate())) / (1000 * 60 * 60 * 24)) ?
        Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(this.selectedDeleteAttendanceStartDate.getFullYear(), this.selectedDeleteAttendanceStartDate.getMonth(), this.selectedDeleteAttendanceStartDate.getDate())) / (1000 * 60 * 60 * 24)) : 0;

      if (Number(days) > 180) {
        this.selectedDeleteAttendanceEndDate = "";
        this.maxDeleteAttendanceDateRange = new Date();
        this.maxDeleteAttendanceDateRange = selectedDate;
      }
      else {
        this.selectedDeleteAttendanceEndDate = "";
        this.maxDeleteAttendanceDateRange = new Date();
        this.maxDeleteAttendanceDateRange = currentDate;
      }

    }
  }

  onMaxDateChange(event) {
    if (event) {
      this.selectedEndDate = event;
    }
  }

  onDeleteAttendanceMaxDateChange(event) {
    if (event) {
      this.selectedDeleteAttendanceEndDate = event;
    }
  }

  async approveSelectedDelAttendance() {
    this.loading = true;
    let studentList: any = [];
    const multidelAttendanceApproval = {
      deleteAttendanceIds: this.selectedDelAttendanceForApproval,
      approverNotes: 'Bulk Approval',
      status: 'Approved',
    };

    const modal = await this.modalController.create({
      component: SubmitAttendanceConfirmationComponent,
      cssClass: 'my-custom-modal-css-small',
      componentProps: {
        title: `Are you sure?`,
        text: `You are about to Approve ${this.selectedDelAttendanceForApproval.length} delete attendance requests.`,
      },
    });
    modal.onDidDismiss().then((response) => {
      if (response.role == 'confirm') {
        this.studentsService.approveMultiDelAttendance(multidelAttendanceApproval).subscribe((data: any) => {
          this.fetchDeleteAttendanceList();
          this.delAttendanceTabToShow = 'DeleteList';
        });
      } else {
        this.loading = false;
        return
      }
    });

    return await modal.present();
  }

  loadSearchBar(locationId) {
    if (!document.getElementById(locationId).hasAttribute('keydown')) {
      switch (locationId) {
        case "calSearchBar":
          document.getElementById('calSearchBar').addEventListener('keydown',
            function (event) {
              if (event.key === "Enter") {
                document.getElementById('calSearchButton').click();
              }
            });
          break;
        case 'excSearchBar':
          document.getElementById('excSearchBar').addEventListener('keydown',
            function (event) {
              if (event.key === "Enter") {
                document.getElementById('excSearchButton').click();
              }
            });
          break;
        case 'misSearchBar':
          document.getElementById('misSearchBar').addEventListener('keydown',
            function (event) {
              if (event.key === "Enter") {
                document.getElementById('misSearchButton').click();
              }
            });
          break;
        case 'delSearchBar':
        document.getElementById('delSearchBar').addEventListener('keydown',
          function (event) {
            if (event.key === "Enter") {
              document.getElementById('delSearchButton').click();
            }
          });
        break;
        case 'studentAttendanceDisputesSearchBar':
          document.getElementById('studentAttendanceDisputesSearchBar').addEventListener('keydown',
            function (event) {
              if (event.key === "Enter") {
                document.getElementById('studentAttendanceDisputesButton').click();
              }
            });
          break;
        case 'enrSearchBar':
          document.getElementById('enrSearchBar').addEventListener('keydown',
            function (event) {
              if (event.key === "Enter") {
                document.getElementById('enrSearchButton').click();
              }
            });
          break;
        case 'cerSearchBar':
          document.getElementById('cerSearchBar').addEventListener('keydown',
            function (event) {
              if (event.key === "Enter") {
                document.getElementById('cerSearchButton').click();
              }
            });
          break;
      }
    }
  }  
}

@Component({
  template: ``,
})
export class CalendarException { }

