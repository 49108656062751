import { Observable, Subject } from 'rxjs';
import { Injectable, Component } from '@angular/core';
import { MSAdal, AuthenticationContext, AuthenticationResult, MSAdalOriginal } from '@ionic-native/ms-adal';
import { environment } from '../../../environments/environment';
import { Authentication, AdalConfig } from 'adal-ts';
import { Storage } from '@ionic/storage';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, } from '@angular/router';
import { UserData } from '../user-data';


export interface LoginInfo {
  loggedin: boolean;
  userId: string;
  token: string;
}

@Injectable()
export class AuthServiceProvider implements CanActivate {

  constructor(public storage: Storage,
    public userData: UserData,
    private router: Router) {

  }

  public UserId: string;
  public AccessToken: string;

  // Private resources
  _aadAuthContext: AuthenticationContext = null;
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let subject = new Subject<any>();
    let active = true;
    return active;

  }

  async getProfile() {
    const profile = await this.userData.getProfile();
    console.log('auth--- Profile func', profile);
    return profile;
  }

  /*
  _aadAuthority: string = this.configurationService.getValue<string>("aadAuthority");
  _aadAppClientId: string = this.configurationService.getValue<string>("aadAppClientId");
  _aadAppRedirect: string = this.configurationService.getValue<string>("aadAppRedirect");
  _graphResource: string = this.configurationService.getValue<string>("aadGraphResource");
  _odataResource: string = this.configurationService.getValue<string>("aadOdataResource");
 */


  // Private function to get authentication context using ADAL
  ensureContext() {
    return new Promise((resolve, reject) => {
      // Check if aadAuthContext is already initialized
      if (this._aadAuthContext == null) {
        // aadAuthContext is null...initialize it
        // var authority = this.configurationService.getValue<string>("aadAuthority");
        // this._aadAuthContext = this.msAdal.createAuthenticationContext(authority, false);
        // resolve(this._aadAuthContext);
      } else {
        // aadAuthContext is already initialized so resolve in promise
        resolve(this._aadAuthContext);
      }
    });
  }

  signIn() {
    const context = Authentication.getContext(this.createConfig());
    context.login();

    // redirect to get id_token
    // window.location.href = this.configurationService.getValue<string>("aadAuthority") +
    // "/oauth2/authorize?response_type=id_token&client_id=" + this.configurationService.getValue<string>("aadClientId") +
    // "&redirect_uri=" + encodeURIComponent(window.location.href) +
    // "&state=SomeState&nonce=SomeNonce";
  }

  createConfig(): AdalConfig {
    const config: AdalConfig = {
      tenant: environment.tenant,
      clientId: environment.clientId,
      postLogoutRedirectUrl: environment.postLogoutRedirectUrl,
      // postLogoutRedirectUri: window.location.origin + '/',
      redirectUri: environment.redirectUrl,
      responseType: environment.responseType,
      extraQueryParameter: 'scope=openid',
      resource: null
    };
    return config;
  }

  createLogoutConfig(): AdalConfig {
    const config: AdalConfig = {
      tenant: environment.tenant,
      clientId: environment.clientId,
      postLogoutRedirectUrl: environment.postLogoutRedirectUrl,
      // postLogoutRedirectUri: window.location.origin + '/',
      redirectUri: environment.postLogoutRedirectUrl,
      responseType: null,
      resource: null,
      extraQueryParameter: null
    };
    return config;
  }

  signOut() {
    // tslint:disable-next-line: no-unused-expression
    console.log('calling signout');
    const context = Authentication.getContext(this.createLogoutConfig());
    console.log(context);
    this.storage.clear();
    window.localStorage.clear();
    window.dispatchEvent(new CustomEvent('user:logout'));
    // window.location.href = `${environment.ssoLogout}?returnUrl=${window.location.origin}/home`
    context.logout();
  }
}
