// used in Update Award Modal
import { Component, OnInit, Input } from '@angular/core';
import { Components } from '@ionic/core';
import { AgenciesService } from '../agencies/agencies.service';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';

@Component({
  selector: 'deactivate-agency',
  templateUrl: './deactivate-agency.component.html',
  styleUrls: ['./deactivate-agency.component.scss'],
})
export class DeactivateAgencyComponent implements OnInit {
  @Input() popover: Components.IonPopover;
  @Input() agency: any;
  constructor(public agenciesService: AgenciesService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    console.log(this.agency);
  }
  // deactivates agency
  deactivateAgency() {
    this.agenciesService.deactivateAgency(this.agency).add(() => {
      this.popover.dismiss();
      this.router.navigateByUrl(`/agencies`).then(() => {
        window.location.reload();
      });
    });
  }
}
