import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RolePermissionsService } from './rolepermissions-service/rolepermissions-service';

export interface User {
  name: string;
  roles: number[];
  agency: number;
}
@Injectable({
  providedIn: 'root'
})
export class UserData {
  _favorites: string[] = [];
  HAS_LOGGED_IN = 'hasLoggedIn';
  HAS_SEEN_TUTORIAL = 'hasSeenTutorial';

  constructor(
    public storage: Storage,
    private rolePermissionsService: RolePermissionsService
  ) { }

  hasFavorite(sessionName: string): boolean {
    return (this._favorites.indexOf(sessionName) > -1);
  }

  addFavorite(sessionName: string): void {
    this._favorites.push(sessionName);
  }

  removeFavorite(sessionName: string): void {
    const index = this._favorites.indexOf(sessionName);
    if (index > -1) {
      this._favorites.splice(index, 1);
    }
  }

  login(username: string): Promise<any> {
    return this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
      this.setUsername(username);
      return window.dispatchEvent(new CustomEvent('user:login'));
    });
  }

  signup(username: string): Promise<any> {
    return this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
      this.setUsername(username);
      return window.dispatchEvent(new CustomEvent('user:signup'));
    });
  }

  logout(): Promise<any> {
    return this.storage.remove(this.HAS_LOGGED_IN).then(() => {
      return this.storage.remove('username');
    }).then(() => {
      return this.storage.remove('userfromIdToken');
    }).then(() => {
      return this.storage.remove('userSubject');
    }).then(() => {
      window.dispatchEvent(new CustomEvent('user:logout'));
    });
  }

  setUsername(username: string): Promise<any> {
    return this.storage.set('username', username);
  }

  getUsername(): Promise<string> {
    return this.storage.get('username').then((value) => {
      return value;
    });
  }
  getTokenProfile(): Promise<string> {
    return this.storage.get('userfromIdToken');
  }
  getProfile(): Promise<any> {
    return this.storage.get('userSubject');
  }

  isLoggedIn(): Promise<boolean> {
    return this.storage.get(this.HAS_LOGGED_IN);
  }

  get profile() {
    return JSON.parse(window.localStorage.getItem("_ionicstorage/_ionickv/userSubject"))
  }

  hasRoles(roles: number[]): boolean {
    if(roles == undefined)
      return true;
    if (this.profile) {
      for (const aRole of this.profile.roles) {
        if (roles.includes(aRole))
          return true;
      }
    }
    return false;
  }
}
